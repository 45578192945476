import { useEffect, useRef } from 'react'
import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import TextArea from '@/Shared/Forms/TextArea'
import { useForm } from '@inertiajs/react'

export default function DisableTransactionSupportDialog({ open, onCanceled, onSuccess }) {
  let focusRef = useRef()
  const { clearErrors, data, setData, errors, reset, setError } = useForm({
    reason: '',
  })

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        focusRef.current.focus()
      }, 100)
    }
  }, [open])

  const onCloseDialog = () => {
    clearErrors()
    reset()
    onCanceled()
  }

  const onSubmit = () => {
    if (!data.reason) {
      setError('reason', 'This is a required field.')
      return
    }

    onCloseDialog()
    onSuccess(data)
  }

  return (
    <Dialog
      focusRef={focusRef}
      cancelText="Close"
      footerActions={
        <Button type="submit" theme="solid" onClick={() => onSubmit()}>
          Continue
        </Button>
      }
      show={open}
      size="sm:max-w-2xl"
      title="Disabling Transaction Support"
      onClosed={() => onCloseDialog()}
    >
      <p className="mb-3">
        Provide some comments describing why you are disabling Transaction Support. Your request will be the Compliance Department with our
        Transaction Support guidelines.
      </p>

      <TextArea
        ref={focusRef}
        label="Comments"
        name="reason"
        value={data.reason}
        error={errors.reason}
        rows="4"
        onChange={(value) => setData({ ...data, reason: value })}
        multiline
      />
    </Dialog>
  )
}
