import { Fragment } from 'react'
import classNames from 'classnames'
import Alert from '../../Shared/Alert'
import Button from '@/Shared/Button'
import Helpers from '@/utils/helpers'
import { router, usePage } from '@inertiajs/react'
import Layout from '@/Shared/Layouts/Main'

const Link = ({ children, href }) => {
  return (
    <a
      className="rounded font-medium text-blue-500 hover:underline focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      href={href}
      target="_blank"
    >
      {children}
    </a>
  )
}

const Tag = ({ tag }) => {
  return (
    <div className="mx-0.5 mt-0.5 inline-flex items-center whitespace-nowrap rounded-full bg-gray-200 px-2.5 pb-1 pt-1.5 text-sm font-medium uppercase leading-none text-gray-800 shadow">
      <span>{tag.name}</span>
    </div>
  )
}

function CoServicingAgreement({ inviter, transaction, source }) {
  const { constants } = usePage().props
  const minimumCapFee = Helpers.formatCurrency(constants.min_cap_fee)
  let associates = transaction.contacts.filter((c) => c.contact_type === 'Associate')
  let clients = transaction.contacts.filter((c) => c.roles.some((r) => r.name === transaction.type))
  const myContact = transaction.contacts.find((c) => c.is_owner)
  const owner = transaction.contacts.find((c) => c.is_transaction_owner)
  const transaction_support_enabled = transaction.contacts.some((contact) => contact.transaction_support)

  const getTeamNames = () => {
    return associates.map(
      (c, index) => (index > 0 ? (associates.length > 2 && index + 1 < associates.length ? ', ' : ' and ') : '') + c.full_name,
    )
  }

  const getClientNames = () => {
    return clients.map((c, index) => (index > 0 ? (clients.length > 2 && index + 1 < clients.length ? ', ' : ' and ') : '') + c.full_name)
  }

  const accept = () => {
    router.post(route('transactions.agreements.store', { transaction: transaction.id }))
  }

  return (
    <div className="flex flex-1 items-start justify-center bg-gray-400 bg-opacity-75 p-8">
      <div className="w-full max-w-7xl">
        <div className="mb-6 space-y-6 overflow-hidden bg-white shadow sm:rounded-lg">
          {inviter && (
            <div className="flex items-center justify-between bg-blue-100 p-6 sm:px-8">
              <div className="relative flex-1">
                <h1 className="text-center text-[18px] text-gray-700">
                  <span className="font-bold">{inviter}</span> has invited you to join the{' '}
                  <span className="font-bold">{myContact.party_representing[0]} PRO Team</span>{' '}
                  {myContact.roles?.length > 0 && (
                    <span className="font-bold">
                      (
                      {myContact.roles.map((role, index) => (
                        <Fragment key={index}>
                          {index > 0 ? ', ' : ''}
                          {role.name}
                        </Fragment>
                      ))}
                      )
                    </span>
                  )}{' '}
                  for the following transaction:
                </h1>
              </div>
            </div>
          )}

          <div className={classNames('space-y-6 text-lg sm:px-8', inviter ? 'px-6' : 'p-6')}>
            <h2 className="text-center text-xl font-semibold">ASSOCIATE CO-SERVICING AGREEMENT</h2>

            <div>
              <p>
                This{' '}
                <Link href="https://sites.google.com/a/betterlivingre.com/intranet/hr/co-servicing?pageMoved=Human%20Resources">
                  Co-Servicing Agreement
                </Link>{' '}
                establishes the working relationship by and between {getTeamNames()} (hereinafter referred to as the "Associates") when they
                agree to work for {getClientNames()} (hereinafter referred to as the "Client") as a “TEAM.” Accepting this agreement
                acknowledges the relationship as it pertains to both servicing a client and distribution of the{' '}
                <Link href="https://sites.google.com/a/betterlivingre.com/intranet/hr/policies/payment-of-commissions">
                  Associate-Side Split
                </Link>{' '}
                compensation between the Associates.
              </p>

              <div className="mx-auto max-w-3xl text-base">
                <Alert
                  heading="This Co-Servicing Agreement should be established and accepted at the first available opportunity."
                  type="warning"
                />
              </div>
            </div>

            <p>
              By state law, all clients are clients of Better Living Real Estate, LLC (hereinafter referred to as the "Brokerage") and
              therefore the Brokerage controls the liability, client agency relationship, services, agreements, contracts, compensation and
              services provided by Associates of the Brokerage. In case of any disputes, the Brokerage has the final decision in settlement
              and resolution of such matters between Associates, including disputes over policy and compensation. The Brokerage empowers
              Associates to perform services to clients on behalf of the Brokerage as outlined by both state and federal laws and as our
              independent contractor.
            </p>

            <p>
              The goal of this Co-Servicing Agreement is to further define the relationship between the Brokerage and the Associates who
              decide to work together by jointly servicing and providing “agency” and “fiduciary” benefits to the Brokerage’s client.
            </p>

            <p>
              This “teamwork” between the Associates is the “Co-Servicing” of the Client by contract between the Brokerage and the Client.
              Established by this Co-Servicing Agreement are the following terms:
            </p>

            <div className="mx-auto max-w-4xl">
              <div className="space-y-4">
                <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                  <div className="flex divide-x divide-gray-300">
                    <span className="w-[275px] bg-gray-100 px-4 py-2">Client</span>
                    <span className="flex-1 px-4 py-2 font-bold">{getClientNames()}</span>
                  </div>

                  <div className="flex divide-x divide-gray-300">
                    <span className="w-[275px] bg-gray-100 px-4 py-2">Type of Client</span>
                    <span className="flex-1 px-4 py-2 font-bold">
                      {transaction.type}{' '}
                      {transaction.propertyTypes?.map((propertyType) => (
                        <Tag tag={propertyType} key={propertyType.id} />
                      ))}
                      {['Buyer', 'Tenant'].some((type) => type.includes(transaction.type)) ? (
                        transaction.states?.map((state) => <Tag tag={{ name: state.state, type: 'state' }} key={state.id} />)
                      ) : (
                        <Tag tag={{ name: transaction.property_state }} />
                      )}
                    </span>
                  </div>

                  {['Seller', 'Landlord'].some((type) => type.includes(transaction.type)) && (
                    <div className="flex divide-x divide-gray-300">
                      <span className="w-[275px] bg-gray-100 px-4 py-2">Listing Address</span>
                      <span className="flex-1 px-4 py-2 font-bold">{transaction.property_address_inline}</span>
                    </div>
                  )}

                  <div className="flex divide-x divide-gray-300">
                    <span className="w-[275px] bg-gray-100 px-4 py-2">Client Generated By</span>
                    <span className="flex-1 px-4 py-2 font-bold">{owner.full_name}</span>
                  </div>

                  {source && (
                    <div className="flex divide-x divide-gray-300">
                      <span className="w-[275px] bg-gray-100 px-4 py-2">Client Generated From</span>
                      <span className="flex-1 px-4 py-2 font-bold">{source}</span>
                    </div>
                  )}

                  <div className="flex divide-x divide-gray-300">
                    <span className="w-[275px] bg-gray-100 px-4 py-2">Lead Associate</span>
                    <span className="flex-1 px-4 py-2 font-bold">{owner.full_name}</span>
                  </div>

                  {associates
                    .filter((a) => !a.is_transaction_owner)
                    .map((a) => (
                      <div className="flex divide-x divide-gray-300" key={a.id}>
                        <span className="w-[275px] bg-gray-100 px-4 py-2">
                          {a.transaction_support ? 'Transaction Support Associate' : 'Supporting Associate'}
                        </span>
                        <span className={classNames('flex-1 px-4 py-2 font-bold', { 'text-blue-500': a.id == myContact.id })}>
                          {a.full_name} {a.id == myContact.id && '(YOU)'}
                        </span>
                      </div>
                    ))}

                  {transaction.referral_type === 'external' && transaction.referral_fee > 0 && (
                    <div className="flex divide-x divide-gray-300">
                      <span className="w-[275px] bg-gray-100 px-4 py-2">Referral:</span>
                      <span className="flex flex-1 items-center px-4 py-2 font-bold">
                        {parseFloat(transaction.referral_fee_pct)}% ({transaction.referral_name})
                      </span>
                    </div>
                  )}

                  {transaction.referral_type === 'internal' && transaction.referral_fee > 0 && (
                    <div className="flex divide-x divide-gray-300">
                      <span className="w-[275px] bg-gray-100 px-4 py-2">Internal Referral:</span>
                      <span className="flex flex-1 items-center px-4 py-2 font-bold">
                        {parseFloat(transaction.referral_fee_pct)}% ({transaction.referral})
                      </span>
                    </div>
                  )}

                  {transaction.community_protector_rebate && (
                    <div className="flex divide-x divide-gray-300">
                      <span className="w-[275px] bg-gray-100 px-4 py-2">Community Protector Rebate:</span>
                      <span className="flex flex-1 items-center px-4 py-2 font-bold">
                        ${parseFloat(transaction.community_protector_rebate)}
                      </span>
                    </div>
                  )}

                  {transaction.mygivebackprogram_rebate && (
                    <div className="flex divide-x divide-gray-300">
                      <span className="w-[275px] bg-gray-100 px-4 py-2">MyGiveBackProgram Rebate:</span>
                      <span className="flex flex-1 items-center px-4 py-2 font-bold">
                        ${parseFloat(transaction.mygivebackprogram_rebate)}
                      </span>
                    </div>
                  )}

                  {transaction.additional_compensation
                    ?.filter((compensation) => compensation.amount)
                    .map((compensation, index) => (
                      <div className="flex divide-x divide-gray-300 bg-gray-100" key={index}>
                        <div className="w-[275px] px-4 py-2">
                          <div>{compensation.associate_name}</div>
                        </div>
                        <div className="flex flex-1 items-center bg-white px-4 py-2 font-bold leading-tight">
                          <div>
                            {compensation.deducted_from_id == myContact.contact.associate.id
                              ? '$' + Helpers.formatDecimal(compensation.amount, 2)
                              : compensation.deducted_from === 'Entire Associate-Side Split'
                                ? '$' + Helpers.formatDecimal(compensation.amount / associates.length, 2)
                                : ''}{' '}
                            <span className="font-normal">
                              (deducted from the <span className="font-semibold">{compensation.deducted_from}</span>)
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <p>
              <span className="font-bold">Commission Splits Breakdown & CAP Benefit</span>
            </p>

            <p>
              The commission splits are broken down to the "Associate-Side Split" and the "Brokerage-Side Split". The Associate-Side Split
              is the percentage of the closing commission each Associate will receive. The Brokerage-Side Split is the amount the Brokerage
              retains from the closing commission based on each Associate's Annual CAP Benefit and Annual CAP Period.
            </p>

            <div>
              <span className="font-bold">Associate-Side Split</span>
              <p>The Gross Retained Commission for this transaction will be set forth based on the following values:</p>
            </div>

            <div className="mx-auto max-w-4xl">
              <div className="space-y-4">
                <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                  {associates
                    .filter((a) => !a.transaction_support)
                    .map((a) => (
                      <div
                        className={classNames('flex divide-x divide-gray-300 font-bold', {
                          'text-blue-500': a.id == myContact.id,
                        })}
                        key={a.id}
                      >
                        <span className="w-[275px] bg-gray-100 px-4 py-2">
                          {a.full_name} {a.is_transaction_owner && '(YOU)'}
                          <div className="text-[12px] font-medium uppercase leading-none text-gray-500">
                            {a.is_transaction_owner ? 'Lead Associate Split' : 'Supporting Associate Split'}
                          </div>
                        </span>
                        <span className="flex flex-1 items-center px-4 py-2">{Helpers.truncateDecimals(a.split, '%')}</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div>
              <span className="font-bold">Brokerage-Side Split</span>
              <p>
                The Annual CAP Benefit and Annual CAP Period applies individually for each Associate. If an Associate has not reached their
                Annual CAP, the Brokerage-Side Split will be applied toward each Associates’ Annual CAP Period based{' '}
                {transaction.brokerage_split_auto_distribute ? (
                  <span>on the above Associate-side Splits</span>
                ) : (
                  <span>the following values</span>
                )}
                . If an Associate has already reached their Annual CAP or will reach their CAP during this transaction, however, they will
                receive 100% of their Associate-side Split of the closing commission less a {minimumCapFee} minimum fee.
              </p>
            </div>

            {!transaction.brokerage_split_auto_distribute && (
              <div className="mx-auto max-w-4xl">
                <div className="space-y-4">
                  <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                    {associates.map((a) => (
                      <div
                        className={classNames('flex divide-x divide-gray-300 font-bold', {
                          'text-blue-500': a.id == myContact.id,
                        })}
                        key={a.id}
                      >
                        <span className="w-[275px] bg-gray-100 px-4 py-2">
                          {a.full_name} {a.is_transaction_owner && '(YOU)'}
                          <div className="text-[12px] font-medium uppercase leading-none text-gray-500">
                            {a.is_transaction_owner ? 'Lead Associate Split' : 'Supporting Associate Split'}
                          </div>
                        </span>
                        <span className="flex flex-1 items-center px-4 py-2">{Helpers.truncateDecimals(a.cap_split, '%')}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {transaction_support_enabled && (
              <div>
                <div>
                  <span className="font-bold">Transaction Support Split</span>
                </div>

                <div className="mx-auto max-w-4xl">
                  <div className="space-y-4">
                    <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                      {associates
                        .filter((a) => a.transaction_support)
                        .map((a) => (
                          <div
                            className={classNames('flex divide-x divide-gray-300 font-bold', {
                              'text-blue-500': a.id == myContact.id,
                            })}
                            key={a.id}
                          >
                            <span className="w-[275px] bg-gray-100 px-4 py-2">
                              {a.full_name} {a.is_transaction_owner && '(YOU)'}
                              <div className="text-[12px] font-medium uppercase leading-none text-gray-500">Transaction Support</div>
                            </span>
                            <span className="flex flex-1 items-center px-4 py-2">{Helpers.truncateDecimals(a.split, '%')}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <p>
              By accepting this agreement, you hereby agree to the commission split established as the Associate-Side Split in the above
              table. No matter how much work one Associate does or the other Associate does, this commission split will be the split
              distributed by the Brokerage and will be reflected on the Closing Worksheet and Report. Any changes to this split will void
              this agreement and require approval of all affected Associates by accepting a new Associate Co-Servicing Agreement prior to
              closing.
            </p>
          </div>

          <div className="flex justify-end border-t border-gray-200 p-6 sm:px-8">
            <Button theme="outline" onClick={() => router.visit(route('transactions.show', transaction.id))}>
              Close
            </Button>

            <Button theme="solid" onClick={() => accept()}>
              <span className="text-lg">Accept Agreement</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

CoServicingAgreement.layout = (page) => <Layout title={`Transaction Co-Servicing Agreement`} children={page} />

export default CoServicingAgreement
