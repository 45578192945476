import React, { Fragment } from 'react'
import Alert from '@/Shared/Alert'
import Checkbox from '@/Shared/Forms/Checkbox'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'
import { usePage } from '@inertiajs/react'
import { Tooltip } from '@/Shared/Tooltip'

export default function AssociateEdit({ data, errors, fieldToFocus, states, setData }) {
  const { checklist_types, supervisors, auth, constants } = usePage().props
  const { user } = auth
  const administrating = route().current().startsWith('admin')

  const licensedStates = [
    { value: 'MA', label: 'MA' },
    { value: 'NH', label: 'NH' },
    { value: 'RI', label: 'RI' },
  ]

  const fetchAssociates = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        groups: 'associates',
      },
    })

  const showOption = (option) => {
    return option.label ? (
      option.label.name
    ) : (
      <div className="group flex items-center space-x-3" key={option.id}>
        {option.avatar ? (
          <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
        ) : (
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
            <div className="text-base font-medium tracking-wide">{option.initials}</div>
          </div>
        )}

        <div>
          <div className="space-x-1">
            <span className="space-x-1 font-medium text-gray-900">
              <span>{option.name}</span>
              {option.industry && <span className="text-gray-500">in {option.industry}</span>}
            </span>
          </div>

          {option.company && <div className="text-gray-500">{option.company}</div>}
        </div>
      </div>
    )
  }

  return (
    <div className="mb-5 rounded-md border-2 border-blue-300 bg-blue-50 pb-4">
      <div className="mb-3 inline-block rounded-br-md rounded-tl bg-blue-500 px-3 py-0.5 font-medium uppercase text-white">
        Associate Details
      </div>

      <div className="space-y-6 px-6">
        <div>
          <label htmlFor="dob" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
            Birthday
          </label>

          <DatePicker
            ref={fieldToFocus?.toLowerCase() === 'dob' ? focusRef : null}
            date={data.dob && new Date(data.dob)}
            onChange={(date) => setData({ ...data, dob: date })}
            hideYear
          />
        </div>

        {administrating && Helpers.isAdminOrHR(user) && (
          <Fragment>
            <div>
              <Heading>Compensation Plan</Heading>

              <Alert
                type="notice"
                subtext="Changing the commission rate will result in any pending commission payments for open transactions to be recalculated.  The associate will be notified of this update."
              />

              <div className="mt-4 sm:flex sm:justify-between sm:gap-8">
                <div className="flex flex-1 justify-end">
                  <TextInput
                    name="commission_pct"
                    error={errors.commission_pct}
                    label={
                      <span>
                        Commission Rate<span className="pl-1 text-red-600">*</span>
                      </span>
                    }
                    icon={<i className="fal fa-percent"></i>}
                    iconPosition="right"
                    size="w-40"
                    value={data.commission_pct}
                    onBlur={() => {
                      setData({ ...data, commission_pct: Helpers.formatDecimal(Helpers.parseCurrency(data.commission_pct), 2) })
                    }}
                    onChange={(value) => setData({ ...data, commission_pct: value })}
                  />
                </div>

                <div className="flex-1">
                  <TextInput
                    label="Brokerage Rate"
                    icon={<i className="fal fa-percent"></i>}
                    iconPosition="right"
                    type="number"
                    size="w-40"
                    value={Helpers.formatDecimal(100 - data.commission_pct, 2)}
                    disabled={true}
                  />
                </div>
              </div>

              <DatePicker
                date={data.cap_anniversary_date && new Date(data.cap_anniversary_date)}
                error={errors.cap_anniversary_date}
                label="CAP Anniversary Date"
                onChange={(date) => setData({ ...data, cap_anniversary_date: date })}
                required
              />
            </div>

            <div>
              <Heading>Roles</Heading>

              <div className="space-y-4">
                <Checkbox
                  name="roles_support_vp"
                  label="Support Vice President"
                  value={data.support_vp}
                  error={errors.support_vp}
                  onChange={(checked) => {
                    const updatedData = { ...data, support_vp: checked, pal: false }

                    // Remove transaction support when set
                    if (checked && updatedData.transaction_support) {
                      delete updatedData.transaction_support
                    }

                    setData(updatedData)
                  }}
                />

                {errors.support_vp && (
                  <div className="mx-1 mt-2 rounded-md bg-red-100/80 p-4 font-medium text-red-600">{errors.support_vp}</div>
                )}

                <Checkbox
                  name="roles_pal"
                  label="PAL"
                  value={data.pal}
                  onChange={(checked) => setData({ ...data, pal: checked, support_vp: false })}
                />
              </div>
            </div>

            <div id="support_vps" className="mb-5 pt-3">
              <Heading noMargin>
                {Object.keys(errors).find((key) => key.includes('support_vps')) && (
                  <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>
                )}
                Support Vice Presidents
              </Heading>

              {Object.keys(errors).find((key) => key.includes('support_vps')) && (
                <div className="mx-1 mt-2 text-red-600">Complete the highlighted fields.</div>
              )}

              <MultipleInputBlock
                ref={fieldToFocus?.toLowerCase() === 'support_vp' ? focusRef : null}
                data={data.support_vps}
                onChange={(value) => setData({ ...data, support_vps: value })}
                hasPrimary={false}
                render={({ focusRef, data: item, index, onChange }) => (
                  <div className="flex-1 sm:flex sm:flex-wrap sm:gap-4">
                    <Select
                      classes="flex-1"
                      name="supervisor_id"
                      options={supervisors.filter(
                        (option) =>
                          option.value == item.supervisor_id ||
                          (!data.support_vps.find((selected) => selected.supervisor_id == option.value) && option.deleted_at == null),
                      )}
                      placeholder="Select Associate"
                      value={item.supervisor_id}
                      error={errors[`support_vps.${index}.supervisor_id`]}
                      onChange={(selected) => onChange({ ...item, supervisor_id: selected && selected.value })}
                      hideErrorMessage
                    />

                    <Select
                      ref={focusRef}
                      classes="w-48"
                      name="svp_state"
                      options={licensedStates.filter(
                        (option) =>
                          item.states?.split(',').indexOf(option.value) >= 0 ||
                          !data.support_vps.find((selected) => selected.states?.split(',').indexOf(option.value) >= 0),
                      )}
                      placeholder="State(s)"
                      value={licensedStates.filter((state) => (item.states || '').split(',').indexOf(state.value) >= 0)}
                      error={errors[`support_vps.${index}.states`]}
                      isClearable={false}
                      onChange={(selected) =>
                        onChange({
                          ...item,
                          states: selected?.reduce((carry, item) => (carry += (carry != '' ? ',' : '') + item.value), ''),
                        })
                      }
                      multiple
                      hideErrorMessage
                    />
                  </div>
                )}
              />
            </div>

            <div>
              <Heading>Primary Sponsor</Heading>
              <Select
                error={errors.manager_id}
                name="sponsor_id"
                optionLabel={(option) => showOption(option)}
                optionValue={(option) => option.id}
                placeholder="Search Associates"
                value={data.sponsor1stTier}
                onChange={(selected) => setData({ ...data, sponsor_id: selected?.associate_id || null, sponsor1stTier: selected })}
                onInputChanged={(value) => fetchAssociates(value)}
                async
              />
            </div>

            <div>
              <Heading className="flex items-center justify-between">
                <div>Transaction Support</div>

                <Checkbox
                  name="enabled_transaction_support"
                  label="Enabled"
                  value={data.transaction_support?.enabled === undefined || data.transaction_support?.enabled}
                  onChange={(checked) => {
                    setData({ ...data, transaction_support: { enabled: checked } })
                  }}
                />
              </Heading>
              <div className="space-y-4">
                {(data.transaction_support?.enabled === undefined || data.transaction_support?.enabled) && (
                  <div className="overflow-hidden rounded-xl border border-gray-300 bg-white">
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-200">
                          <th width="115px" className="py-2 pl-4 text-left">
                            Checklist
                          </th>

                          <th className="flex items-center gap-2 py-2 pr-4 text-left">
                            <span># of Completed Transactions Required</span>

                            <Tooltip
                              label="Represents the # of completed transactions required, per checklist type, over the past 12 months to not be assigned a Transaction Support Associate when creating a new Transaction."
                              placement="bottom"
                            >
                              <i className="far fa-question-circle cursor-help text-lg text-orange-500"></i>
                            </Tooltip>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-300">
                        {checklist_types.map((type) => (
                          <tr key={type.value}>
                            <td className="py-2 pl-4 text-left align-middle">
                              <label htmlFor="roles_support_vp" className="space-y-0.5 leading-none">
                                <span className="font-semibold uppercase leading-none text-gray-600">{type.label}</span>
                              </label>
                            </td>

                            <td className="py-2 pr-4 text-center">
                              <TextInput
                                classes="mb-0 w-20"
                                value={
                                  data.transaction_support?.types?.[type.value]?.completed ?? constants.transaction_support.min_required
                                }
                                onChange={(value) =>
                                  setData((prevData) => ({
                                    ...prevData,
                                    transaction_support: {
                                      ...prevData.transaction_support,
                                      types: {
                                        ...prevData.transaction_support?.types,
                                        [type.value]: { name: type.label, completed: value },
                                      },
                                    },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}

        <div>
          <Heading>Driver's License Information</Heading>

          <Select
            label="State"
            name="drivers_license_state"
            options={states}
            placeholder="State"
            value={data.drivers_license_state}
            isClearable={false}
            onChange={(selected) => setData({ ...data, drivers_license_state: selected && selected.value })}
          />

          <DatePicker
            label="Expiration Date"
            date={data.drivers_license_expires_at && new Date(data.drivers_license_expires_at)}
            onChange={(date) => setData({ ...data, drivers_license_expires_at: date })}
          />
        </div>

        <div>
          <Heading>Vehicle Information</Heading>

          <TextInput
            label="Make"
            name="vehicle_make"
            value={data.vehicle_make}
            onChange={(value) => setData({ ...data, vehicle_make: value })}
          />

          <TextInput
            label="Model"
            name="vehicle_model"
            value={data.vehicle_model}
            onChange={(value) => setData({ ...data, vehicle_model: value })}
          />

          <TextInput
            label="Year"
            name="vehicle_year"
            value={data.vehicle_year}
            onChange={(value) => setData({ ...data, vehicle_year: value })}
          />

          <TextInput
            label="Color"
            name="vehicle_color"
            value={data.vehicle_color}
            onChange={(value) => setData({ ...data, vehicle_color: value })}
          />

          <Select
            label="State"
            name="vehicle_state"
            options={states}
            placeholder="State"
            value={data.vehicle_state}
            isClearable={false}
            onChange={(selected) => setData({ ...data, vehicle_state: selected && selected.value })}
          />

          <TextInput
            label="Plate"
            name="vehicle_plate"
            value={data.vehicle_plate}
            onChange={(value) => setData({ ...data, vehicle_plate: value })}
          />
        </div>
      </div>
    </div>
  )
}
