import { Fragment, useEffect, useState } from 'react'
import Alert from '@/Shared/Alert'
import AmountPercentInput from '@/Shared/Forms/AmountPercentInput'
import Checkbox from '@/Shared/Forms/Checkbox'
import Helpers from '@/utils/helpers'
import Select from '@/Shared/Forms/Select'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'

export default function Referrals({ associates, data, errors, transactionType, onChanged, onFetchContacts, onUpdateSplit }) {
  const defaultSplit = 70
  const owner = associates.find((contact) => contact.is_transaction_owner)
  const [internalData, setInternalData] = useState({
    referral_fee: data.referral_fee,
    referral_fee_pct: null,
  })

  useEffect(() => {
    if (data.referral_type !== 'internal') {
      onChanged({
        ...data,
        ...internalData,
        splits: data.splits?.map((split) => {
          let { referral_deduction, ...rest } = split
          return rest
        }),
      })

      return
    }

    let referral_fee = Helpers.parseCurrency(internalData.referral_fee || 0)

    if (associates.filter((transactionContact) => !transactionContact.transaction_support).length == 1) {
      let splits = data.splits

      if (referral_fee === 0) {
        splits = splits.map((split) => {
          const { referral_deduction, ...rest } = split
          return rest
        })
      }

      onChanged({
        ...data,
        ...internalData,
        splits: splits.map((split) =>
          split.type === 'Associate'
            ? { ...split, ...(referral_fee > 0 ? { referral_deduction: Helpers.formatDecimal(referral_fee, 2) } : {}) }
            : split,
        ),
      })
    } else if (data.splits) {
      if (data.referral_auto_distribute) {
        distributeReferralFeeEvenly(referral_fee)
      } else if (referral_fee == 0) {
        onChanged({
          ...data,
          ...internalData,
          splits: data.splits.map((split) => {
            const { referral_deduction, ...rest } = split
            return rest
          }),
        })
      }
    }
  }, [internalData, data.referral_fee, data.referral_auto_distribute])

  const onBlur = (split) => {
    let formatted = Helpers.formatDecimal(Helpers.parseCurrency(split.referral_deduction || 0), 2)

    if (split.referral_deduction !== formatted) {
      onUpdateSplit({ ...split, referral_deduction: formatted })
    }
  }

  const distributeReferralFeeEvenly = (amount) => {
    let totalDistributed = 0

    // Distribute amounts by rounding down.
    let distributedSplits = data.splits.map((split) => {
      const exactAmount = amount * (split.percent / 100)
      const roundedAmount = Math.floor(exactAmount * 100) / 100 // Round down to 2 decimal places.
      totalDistributed += roundedAmount

      if (roundedAmount === 0) {
        const { referral_deduction, ...rest } = split
        split = rest
      }

      // Return updated person object with their distributed amount.
      return split.type === 'Associate' && !split.transaction_support
        ? { ...split, ...(amount > 0 ? { referral_deduction: roundedAmount } : {}) }
        : split
    })

    // Calculate remaining amount and add it to the lead associate.
    const remaining = amount - totalDistributed

    // Adjust for any remaining pennies, rounding to 2 decimal places.
    distributedSplits = distributedSplits.map((split) => ({
      ...split,
      referral_deduction: Helpers.formatDecimal(
        remaining > 0 && owner.id == split.id ? Math.round((split.referral_deduction + remaining) * 100) / 100 : split.referral_deduction,
        2,
      ),
    }))

    onChanged({
      ...data,
      ...internalData,
      splits: distributedSplits,
    })
  }

  const referralBasis = () => {
    let basis =
      Helpers.parseCurrency(data.commission_blre) -
      Helpers.parseCurrency(data.community_protector_rebate) -
      Helpers.parseCurrency(data.mygivebackprogram_rebate)

    return data.referral_type === 'internal' ? basis * (defaultSplit / 100) : basis
  }

  return (
    <div className="mb-4 rounded-md border border-gray-300 bg-gray-50 px-3 pt-3">
      <Select
        error={errors.referral_type}
        label="Referral Type"
        name="referral_type"
        options={[
          { value: 'internal', label: 'Internal' },
          { value: 'external', label: 'Paid to Referral Brokerage' },
        ]}
        placeholder="Choose one"
        value={data.referral_type}
        onChange={(selected) => {
          onChanged({
            ...data,
            referral: null,
            referral_id: null,
            referral_type: selected?.value || null,
            referral_fee: null,
            referral_name: '',
            referral_info: '',
          })
        }}
        required
      />

      {['Buyer', 'Seller'].some((type) => type.includes(transactionType)) ? (
        <AmountPercentInput
          label="Referral Fee"
          name="referral_fee"
          basis={referralBasis()}
          value={data.referral_fee}
          error={errors.referral_fee}
          onChange={(value, percent) =>
            setInternalData((prevData) => ({ ...prevData, referral_fee: value || null, referral_fee_pct: percent || null }))
          }
        />
      ) : (
        <TextInput
          label="Referral Fee"
          name="referral_fee"
          icon={<i className="fas fa-dollar-sign"></i>}
          value={data.referral_fee}
          error={errors.referral_fee}
          onBlur={() =>
            setInternalData((prevData) => ({
              ...prevData,
              referral_fee: Helpers.formatDecimal(Helpers.parseCurrency(data.referral_fee), 2),
            }))
          }
          onChange={(value) => setInternalData((prevData) => ({ ...prevData, referral_fee: value || null, referral_fee_pct: null }))}
          onFocus={(e) => e.target.select()}
          clearable
        />
      )}

      {data.referral_type === 'internal' && (
        <Fragment>
          <Select
            error={errors.referral_id}
            label="Referral"
            name="referral_id"
            optionLabel={(option) =>
              option.label || (
                <div className="group flex items-center space-x-3">
                  {option.avatar ? (
                    <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
                  ) : (
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                      <div className="text-base font-medium tracking-wide">{option.initials}</div>
                    </div>
                  )}

                  <div>
                    <div className="space-x-1">
                      <span className="space-x-1 font-medium text-gray-900">
                        <span>{option.name}</span>
                        {option.industry && <span className="text-gray-500">in {option.industry}</span>}
                      </span>
                    </div>

                    {option.company && <div className="text-gray-500">{option.company}</div>}
                  </div>
                </div>
              )
            }
            optionValue={(option) => option.id}
            placeholder="Search Associate/PALs"
            value={data.referral}
            onChange={(selected) => onChanged({ ...data, referral_id: selected?.associate_id, referral: selected?.name })}
            onInputChanged={(value) => onFetchContacts(value)}
            required
            async
          />

          {data.splits?.filter((split) => !split.transaction_support).length > 1 && (
            <Fragment>
              <div className="mb-5 pt-2">
                <Checkbox
                  name="referral_auto_distribute"
                  label="Distribute Automatically"
                  description="Deducts the internal referral amount from all associates according to their transaction share %."
                  value={data.referral_auto_distribute}
                  onChange={(checked) => onChanged({ ...data, referral_auto_distribute: checked })}
                />
              </div>

              {data.splits
                ?.filter((split) => !split.transaction_support)
                .map((split, index) => (
                  <div key={index}>
                    <TextInput
                      label={
                        <span className="font-bold text-black">
                          {split.name} {Boolean(data.referral_auto_distribute) && <span>({split.percent}%)</span>}
                        </span>
                      }
                      name={`referral_deduction_${index}`}
                      icon={<i className="fas fa-dollar-sign"></i>}
                      value={split.referral_deduction}
                      onBlur={() => onBlur(split)}
                      onChange={(value) => onUpdateSplit({ ...split, referral_deduction: Helpers.sanitizeCurrencyInput(value || 0) })}
                      onFocus={(e) => e.target.select()}
                      clearable
                      disabled={Boolean(data.referral_auto_distribute)}
                    />
                  </div>
                ))}
            </Fragment>
          )}

          {errors.referral_not_deducted && (
            <div className="mb-3">
              <Alert heading={errors.referral_not_deducted} type="danger" />
            </div>
          )}
        </Fragment>
      )}

      {data.referral_type === 'external' && (
        <Fragment>
          <TextInput
            label="Brokerage Name"
            name="referral_name"
            value={data.referral_name}
            error={errors.referral_name}
            onChange={(value) => onChanged({ ...data, referral_name: value })}
          />

          <TextArea
            label={<span className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Mail Referral Fee to:</span>}
            name="referral_info"
            value={data.referral_info}
            error={errors.referral_info}
            onChange={(value) => onChanged({ ...data, referral_info: value })}
          />
        </Fragment>
      )}
    </div>
  )
}
