import { createContext, useCallback, useEffect, useState } from 'react'
import axios from '@/apis/axios'
import useInfiniteScroll from '@/hooks/useInfiniteScroll'
import useVisibilityAndActivityAwarePolling from '@/hooks/useVisibilityAndActivityAwarePolling'
import { usePage } from '@inertiajs/react'

export const NotificationContext = createContext({
  archiveAll: () => {},
  count: 0,
  filters: {},
  handleInfiniteScroll: (event: any) => {},
  isFetching: false,
  markAllAsRead: () => {},
  newNotifications: 0,
  notifications: [],
  onArchive: (event: any, item: any) => {},
  open: false,
  setFilters: (filters: any) => {},
  setNewNotifications: (count: number) => {},
  setOpen: (open: boolean) => {},
  toggleRead: (event: any, item: any, read: Boolean) => {},
})

export const NotificationProvider = ({ children }: any) => {
  const { notifications_enabled } = usePage().props
  const { user }: any = usePage().props.auth
  const isAdvisor = user.roles.some((role: string) => ['Advisor'].indexOf(role) >= 0)
  const isAssociate = user.roles.some((role: string) => ['Associate'].indexOf(role) >= 0)
  const isAdmin = user.roles.some((role: string) => ['Admin'].indexOf(role) >= 0)

  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState([
    ...(isAssociate
      ? [
          {
            name: 'contact',
            label: 'Contacts',
            filtering: false,
          },
        ]
      : []),
    {
      name: 'lsns',
      label: 'LSNs',
      filtering: false,
    },
    ...(isAssociate
      ? [
          {
            name: 'openhouse',
            label: 'Open Houses',
            filtering: false,
          },
        ]
      : []),
    ...(!isAdvisor
      ? [
          {
            name: 'tasks',
            label: 'Tasks',
            filtering: false,
          },
        ]
      : []),
    ...(!isAdvisor
      ? [
          {
            name: 'transactions',
            label: 'Transactions',
            filtering: false,
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            name: 'serviceapproval',
            label: 'Service Approvals',
            filtering: false,
          },
        ]
      : []),
    {
      name: 'archived',
      label: 'Archived',
      filtering: false,
    },
  ])

  const [lastPolled, setLastPolled] = useState(null)
  const [newNotifications, setNewNotifications] = useState(0)
  const [notifications, setNotifications] = useState([] as any)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(1)
  const { isFetching, setIsFetching, setStopFetching, handleInfiniteScroll } = useInfiniteScroll(fetchMoreNotifications)

  useEffect(() => {
    fetchMoreNotifications(notifications.length > 0)
  }, [filters])

  useEffect(() => {
    if (open && count > 0) {
      markAllAsSeen()
    }
  }, [open])

  useEffect(() => {
    if (notifications.length > 0) {
      let count = notifications.filter((notification: any) => !notification.seen).length
      setCount(count)
    }
  }, [notifications])

  const pollNotifications = useCallback(async () => {
    if (!notifications_enabled) return

    try {
      await axios
        .get(`/api/notifications/poll`, {
          params: {
            timestamp: lastPolled,
          },
        })
        .then((response: any) => {
          let newNotifications = response.data.notifications
          if (newNotifications) {
            setNotifications(newNotifications.concat(notifications))
            if (newNotifications.length) {
              setNewNotifications(newNotifications.length)
            }
          }
          setLastPolled(response.data.last_polled)
        })
    } catch (error: any) {}
  }, [])

  useVisibilityAndActivityAwarePolling(pollNotifications)

  function fetchMoreNotifications(resetOnFetch: boolean) {
    if (!notifications_enabled) return

    axios
      .get(`/api/notifications`, {
        params: {
          page: resetOnFetch ? 1 : page,
          filters: filters,
        },
      })
      .then((response: any) => {
        if (resetOnFetch) {
          setNotifications(response.data.notifications)
          setPage(1)
          setStopFetching(false)
        } else if (response.data.notifications.length > 0) {
          setNotifications(notifications.concat(response.data.notifications))
          setPage(page + 1)
          setStopFetching(false)
        } else setStopFetching(true)

        setIsFetching(false)
      })
  }

  const archiveAll = () => {
    if (notifications.length > 0) {
      axios.post(`/api/notifications/archiveAll`).then(() => {
        const updated: any = notifications.map((notification: any) => ({ ...notification, archived: true }))
        setNotifications(filters.find((filter) => filter.name === 'archived' && filter.filtering) ? updated : [])
      })
    }
  }

  const markAllAsRead = () => {
    if (notifications.length > 0 && notifications.filter((notification: any) => !notification.read).length > 0) {
      axios.post(`/api/notifications/markAllAsRead`).then(() => {
        setNotifications(notifications.map((notification: any) => ({ ...notification, read: true })))
      })
    }
  }

  const markAllAsSeen = () => {
    axios.post(`/api/notifications/seen`).then((response: any) => {
      setCount(0)
    })
  }

  const onArchive = (event: any, item: any) => {
    event.preventDefault()
    event.stopPropagation()

    axios
      .post(`/api/notifications/${item.id}`, {
        _method: 'PATCH',
        archived: !item.archived,
      })
      .then((response: any) => {
        const item = response.data
        const updated: any = notifications.map((notification: any) =>
          notification.id == item.id ? { ...notification, archived: item.archived } : notification,
        )

        setNotifications(
          filters.find((filter) => filter.name === 'archived' && filter.filtering)
            ? updated
            : updated.filter((notification: any) => !item.archived),
        )
      })
  }

  const toggleRead = async (event: any, item: any, read: Boolean) => {
    event.preventDefault()
    event.stopPropagation()

    axios
      .post(`/api/notifications/${item.id}`, {
        _method: 'PATCH',
        read: read,
      })
      .then((response: any) => {
        const updated: any = notifications.map((notification: any) => {
          if (notification.id == item.id) return response.data
          return notification
        })
        setNotifications(updated)
      })
  }

  const context = {
    archiveAll,
    count,
    filters,
    handleInfiniteScroll,
    isFetching,
    markAllAsRead,
    newNotifications,
    notifications,
    onArchive,
    open,
    setFilters,
    setNewNotifications,
    setOpen,
    toggleRead,
  }

  return <NotificationContext.Provider value={context}>{children}</NotificationContext.Provider>
}

export const NotificationConsumer = NotificationContext.Consumer
