import React, { Fragment, forwardRef } from 'react'
import Button from '@/Shared/Button'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'

const SlideOver = forwardRef(function SlideOver(
  {
    aboveMessages,
    actions,
    children,
    color,
    focusRef,
    footerActions,
    footerLeftActions,
    hideCloseButton,
    hideFooter,
    hideOverlay,
    hidePadding,
    position,
    show,
    size,
    subTitle,
    title,
    zIndex,
    onCancel,
    onClosed,
  },
  scrollableContainerRef,
) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(
          'fixed inset-0 overflow-hidden',
          hideOverlay ? 'pointer-events-none' : '',
          zIndex || (aboveMessages ? 'z-30' : 'z-30 md:z-20'),
        )}
        initialFocus={focusRef}
        open={true}
        onClose={() => !hideOverlay && onClosed()}
      >
        <div className="absolute inset-0 h-screen overflow-hidden">
          {!hideOverlay && <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />}

          <div className={classNames('fixed inset-y-0 flex max-w-full', position === 'left' ? 'left-0' : 'right-0')}>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300"
              enterFrom={position === 'left' ? '-translate-x-full' : 'translate-x-full'}
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo={position === 'left' ? '-translate-x-full' : 'translate-x-full'}
            >
              <div className={classNames('w-screen', size, { 'pointer-events-auto': hideOverlay })}>
                <div
                  ref={scrollableContainerRef}
                  className={classNames(
                    'flex h-full flex-col overflow-y-auto bg-white',
                    hideOverlay && position !== 'left' ? 'shadow-left' : 'shadow-xl',
                  )}
                >
                  <div
                    className={classNames(
                      'flex h-16 items-center px-4 sm:px-6',
                      hideOverlay ? 'bg-gradient-to-r from-primary-400 via-primary-600 to-primary-900' : 'bg-primary-700',
                    )}
                  >
                    <div className="flex flex-1 items-center justify-between overflow-hidden px-0.5">
                      <h2 className="truncate text-xl font-medium text-white" id="slide-over-title">
                        {title}
                        {subTitle && <div className="text-sm font-normal uppercase text-gray-100">{subTitle}</div>}
                      </h2>
                      <div className="ml-3 flex h-10 items-center">
                        {actions}
                        <Button theme="toolbar-icon" onClick={onClosed}>
                          <span className="sr-only">Close panel</span>
                          <i className="fal fa-times text-2xl leading-none text-white text-opacity-80"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    id="slideover-scroll-container"
                    className={classNames('relative flex-1 overflow-y-auto overflow-x-hidden', color, hidePadding || 'p-4 sm:p-6')}
                  >
                    <div role="tabpanel" className="flex h-full flex-col">
                      {children}
                    </div>
                  </div>

                  {!hideFooter && (!hideCloseButton || footerLeftActions || footerActions) && (
                    <div
                      className={classNames(
                        'flex items-center border-t border-gray-300 p-5',
                        footerLeftActions ? 'justify-between' : 'justify-end',
                      )}
                    >
                      {footerLeftActions && <div className="flex flex-col justify-center">{footerLeftActions}</div>}
                      <div className="flex-shrink-0 flex-grow text-right">
                        {!hideCloseButton && (
                          <Button theme="clean" onClick={onCancel || onClosed}>
                            Close
                          </Button>
                        )}
                        {footerActions}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
})

SlideOver.defaultProps = {
  keyboardClose: true,
  overlayClose: true,
  size: 'sm:max-w-lg',
}

export default SlideOver
