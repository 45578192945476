import { useEffect, useState } from 'react'
import About from './About'
import Card from './Card'
import classNames from 'classnames'
import Details from '@/Shared/Layouts/Details'
import Main from '@/Shared/Layouts/Main'
import OpenHouseEdit from './Edit'
import OpenHouseMobile from './Mobile'
import Tab from '@/Shared/Tabs/Tab'
import Timeline from '@/Shared/Timeline/Index'
import Visits from './Visits/Index'

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const OpenHouseShow = ({ openhouse }) => {
  const [editing, setEditing] = useState(!openhouse.when)
  const [activeTab, setActiveTab] = useState('activity')
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  })

  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      width: window.innerWidth,
    })
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  return (
    <Details columns={3}>
      <Details.Card>
        <Card onEdit={() => setEditing(true)} />
      </Details.Card>

      <Details.LeftColumn>{dimensions.width >= 768 ? <About /> : <OpenHouseMobile />}</Details.LeftColumn>

      <Details.MiddleColumn>
        {dimensions.width >= 768 && (
          <div className="flex h-full flex-col overflow-hidden bg-gray-100">
            <div className="flex h-full flex-col">
              <div className="relative z-0 flex w-full flex-col self-stretch overflow-hidden">
                <div className="flex h-full flex-col overflow-hidden">
                  <div className="flex items-start justify-between px-4 pt-4 sm:px-6">
                    <ul
                      role="tablist"
                      className="list-reset mb-6 mt-2 flex flex-auto flex-wrap justify-start border-b border-gray-400 border-opacity-20"
                    >
                      <Tab active={activeTab === 'activity'} onClick={() => setActiveTab('activity')}>
                        Activity
                      </Tab>
                    </ul>
                  </div>

                  <div className="flex flex-col overflow-hidden">
                    <div
                      id="activity"
                      className={classNames('flex flex-col overflow-hidden px-4 sm:px-6', {
                        hidden: activeTab !== 'activity',
                      })}
                    >
                      <Timeline />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <OpenHouseEdit record={openhouse} open={editing} onClosed={() => setEditing(false)} />
      </Details.MiddleColumn>

      {dimensions.width >= 768 && (
        <Details.RightColumn>
          <Visits />
        </Details.RightColumn>
      )}
    </Details>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
OpenHouseShow.layout = (page) => <Main title={page.props.openhouse.address} children={page} overflowHidden />

export default OpenHouseShow
