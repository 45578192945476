import { Fragment } from 'react'
import Helpers from '@/utils/helpers'
import { usePage } from '@inertiajs/react'

export default function ReimbursementsSummary({ data }) {
  const distributions = data.reimbursements?.flatMap((r) => r.distributions || [])
  const hasReimbursementDistributions = distributions.some((d) => Helpers.parseCurrency(d.amount) > 0)

  if (!hasReimbursementDistributions) {
    return null
  }

  const { transaction } = usePage().props
  const associates = transaction.contacts.filter(
    (transactionContact) =>
      transactionContact.contact_type === 'Associate' &&
      distributions.some((d) => Helpers.parseCurrency(d.amount) > 0 && d.associate_id === transactionContact.contact.associate.id),
  )

  return (
    <Fragment>
      {associates.map((associate) => {
        const distributions = data.reimbursements
          .flatMap(
            (r) =>
              r.distributions?.map((d) => ({
                ...d,
                description: r.description,
              })) || [],
          )
          .filter((d) => Helpers.parseCurrency(d.amount) > 0 && d.associate_id === associate.contact.associate.id)

        const totalAmount = distributions.reduce((sum, d) => sum + Helpers.parseCurrency(d.amount), 0)

        return (
          <div key={associate.id}>
            <div className="mb-0.5 flex items-end justify-between px-1">
              <div className="text-sm font-bold uppercase text-black">
                <div className="leading-none">{associate.full_name}</div>
              </div>
            </div>

            {totalAmount > 0 && (
              <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                <div className="flex divide-x divide-gray-300">
                  <div className="flex flex-1 flex-col justify-center bg-gray-100 px-4 py-2 leading-snug">
                    {distributions.map((d, index) => (
                      <span className="text-[0.925rem]" key={index}>
                        {distributions.length > 1 && <span className="font-bold">${d.amount} - </span>}
                        {d.description}
                      </span>
                    ))}
                  </div>
                  <span className="flex w-32 items-center justify-center px-4 py-2 font-bold">
                    $<span className="truncate">{Helpers.formatDecimal(totalAmount, 2)}</span>
                  </span>
                </div>
              </div>
            )}
          </div>
        )
      })}
    </Fragment>
  )
}
