import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import Alert from '@/Shared/Alert'
import { BlockUIContext } from '@/Shared/BlockUI/BlockUIContext'
import classNames from 'classnames'
import ConfirmationDialog from '@/Shared/Dialog/ConfirmationDialog'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import Helpers from '@/utils/helpers'
import TextArea from '@/Shared/Forms/TextArea'
import { useForm, usePage } from '@inertiajs/react'

const CancelClosing = ({ cooperatingParty, transaction, focusRef, data, errors, setData }) => {
  const { propertyTypes, allStates } = usePage().props

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <p>Resetting the transaction & starting a new offer will perform the following actions:</p>

        <ul className="list-inside divide-y divide-red-200 overflow-hidden rounded-md border border-red-200 text-black">
          {cooperatingParty && (
            <li className="flex items-center gap-4 bg-red-50 px-3 py-1.5">
              <i className="fal fa-users-slash h-7 w-6 text-center text-xl text-red-500"></i>
              <span>
                Remove <span className="font-bold">{cooperatingParty}</span> as the pending {transaction.cooperating_party} and any contacts
                in their PRO team.
              </span>
            </li>
          )}
          {['Buyer', 'Tenant'].some((type) => type.includes(transaction.type)) && transaction.property_address_inline && (
            <li className="flex items-center gap-4 bg-red-50 px-3 py-1.5">
              <i className="fal fa-house-circle-xmark h-7 w-6 text-center text-xl text-red-500"></i>
              <span>
                Remove{' '}
                <span className="font-bold">
                  {transaction.property_address_inline} {transaction.property_type && <span>({transaction.property_type})</span>}
                </span>{' '}
                as the purchase property address.
              </span>
            </li>
          )}
          {['Buyer', 'Tenant'].some((type) => type.includes(transaction.type)) &&
            (transaction.inactive_property_types.length > 0 || transaction.inactive_states.length > 0) && (
              <li className="flex items-center gap-4 bg-red-50 px-3 py-1.5">
                <i className="far fa-rotate-left h-7 w-6 text-center text-xl text-red-500"></i>
                <span>
                  Reset{' '}
                  {transaction.inactive_property_types.length > 0 && (
                    <Fragment>
                      the property types to{' '}
                      <span className="font-medium">
                        {Helpers.explode(
                          ',',
                          propertyTypes
                            .filter((type) => transaction.inactive_property_types.includes(Number(type.value)))
                            .map((type) => type.label),
                        )}
                      </span>
                    </Fragment>
                  )}
                  {transaction.inactive_property_types.length > 0 && transaction.inactive_states.length > 0 && <Fragment> and </Fragment>}
                  {transaction.inactive_states.length > 0 && (
                    <Fragment>
                      the states to{' '}
                      <span className="font-medium">
                        {Helpers.explode(
                          ',',
                          allStates.filter((state) => transaction.inactive_states.includes(state.value)).map((state) => state.label),
                        )}
                      </span>
                    </Fragment>
                  )}{' '}
                  that were changed when the purchase property address was specified.
                </span>
              </li>
            )}
          {transaction.status === 'Pending' && (
            <li className="flex items-center gap-4 bg-red-50 px-3 py-1.5">
              <i className="fas fa-times h-7 w-6 text-center text-xl text-red-500"></i>
              <span>
                Remove this transaction from{' '}
                <div className="inline-block whitespace-nowrap rounded bg-blue-500 px-2.5 py-1.5 text-sm leading-none text-white">
                  <span>Sale Pending</span>
                </div>{' '}
                .
              </span>
            </li>
          )}
          <li className="flex items-center gap-4 bg-red-50 px-3 py-1.5">
            <i className="fal fa-box-archive h-7 w-6 text-center text-xl text-red-500"></i>
            <span>Archive all related files required for this closing.</span>
          </li>
          <li className="flex items-center gap-4 bg-red-50 px-3 py-1.5">
            <i className="fal fa-calendar-xmark h-7 w-6 text-center text-xl text-red-500"></i>
            <span>Reset all Important Dates (Except the Hiring Date).</span>
          </li>
          <li className="flex items-center gap-4 bg-red-50 px-3 py-1.5">
            <i className="far fa-rotate-left h-7 w-6 text-center text-xl text-red-500"></i>
            <span>Reset the Closing Worksheet.</span>
          </li>
          <li className="flex items-center gap-4 bg-red-50 px-3 py-1.5">
            <i className="fal fa-list-tree h-7 w-6 text-center text-xl text-red-500"></i>
            <span>Mark all related steps as incomplete.</span>
          </li>
        </ul>
      </div>

      <TextArea
        ref={focusRef}
        label="Reason for Canceling"
        name="reason"
        value={data.reason}
        error={errors.reason}
        rows="4"
        onChange={(value) => setData({ ...data, reason: value })}
        required
        multiline
      />
    </div>
  )
}

const ReleaseOfEscrow = ({ clearErrors, data, transaction, errors, setData }) => {
  return (
    <div className="space-y-4">
      {transaction.escrow_held_by_blre ? (
        <Alert
          heading="Escrow funds have been received"
          subtext="1 or more escrow checks have been received by Better Living Real Estate for this transaction requiring further action before this closing can be canceled."
          type="notice"
        />
      ) : (
        <Alert
          heading="Upload Release of Escrow"
          subtext="Where applicable, a Release of Escrow should be completed & uploaded when canceling this closing for our records."
          type="notice"
        />
      )}

      <div>
        <label htmlFor="file" className="text-sm font-medium uppercase text-gray-600">
          Upload a Release of Escrow <span className="text-red-500">*</span> <span className="text-yellow-600">(PDF Only)</span>
        </label>

        {data.release_of_escrow ? (
          <div className="mt-1">
            <div className="relative flex items-center justify-between bg-gray-100 px-3 py-2">
              <div className="flex items-center gap-1.5 font-semibold">
                <i className="fas fa-file-pdf text-xl text-gray-500"></i>
                <span>Release of Escrow</span>
                <span className="text-gray-600">({Helpers.getReadableFileSizeString(data.release_of_escrow.size)})</span>
              </div>

              <div className="flex items-center gap-3">
                <button
                  className="flex h-7 items-center justify-center rounded px-2 py-0.5 outline-none hover:bg-red-600 hover:text-white focus:bg-red-100 focus:text-blue-500 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                  onClick={() => setData({ ...data, release_of_escrow: null })}
                >
                  <i className="far fa-times text-lg"></i>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative">
            <label
              className={classNames(
                'font-md transition-border relative block h-11 w-full rounded px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out focus-within:border-transparent focus-within:ring-2 focus-within:ring-primary-500 hover:border-gray-400',
                errors.file ? 'border-2 border-red-600' : 'border border-gray-300',
              )}
            >
              <input
                type="file"
                id="release_of_escrow"
                className="sr-only"
                accept="image/jpeg,image/png,application/pdf"
                onChange={(event) => {
                  clearErrors('release_of_escrow')
                  setData({ ...data, release_of_escrow: Array.from(event.target.files)[0] })
                }}
              />
              Choose File
              <div className="absolute inset-y-0 right-0 my-px mr-0.5 flex h-[calc(100%-2px)] items-center rounded-r border border-transparent bg-gray-100 px-4 text-gray-700">
                Browse
              </div>
            </label>
          </div>
        )}
        {errors.release_of_escrow && (
          <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.release_of_escrow }}></div>
        )}
      </div>

      {transaction.escrow_held_by_blre && (
        <TextArea
          label="Mailing address to send the escrow payment to"
          name="escrow_mailing_address"
          value={data.escrow_mailing_address}
          error={errors.escrow_mailing_address}
          rows="4"
          onChange={(value) => setData({ ...data, escrow_mailing_address: value })}
          required
          multiline
        />
      )}
    </div>
  )
}

export default function CancelClosingDialog({ open, transaction, onCanceled }) {
  let focusRef = useRef()

  const showReleaseOfEscrow = !transaction.escrow_held_by_blre || transaction.escrow_received_at
  const cooperatingParty = transaction.cooperating_clients
  const { clearErrors, data, setData, errors, reset, post } = useForm()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [stepIndex, setStepIndex] = useState(showReleaseOfEscrow ? 1 : 2)
  const { loading, setLoading } = useContext(BlockUIContext)

  useEffect(() => setStepIndex(showReleaseOfEscrow ? 1 : 2), [transaction])

  useEffect(() => {
    if (stepIndex == 2 && open) {
      setTimeout(() => {
        focusRef.current.focus()
      }, 100)
    }
  }, [stepIndex])

  const onApproved = () => {
    if (stepIndex == 1) {
      setStepIndex(2)
    } else {
      setConfirmOpen(true)
    }
  }

  const onCloseDialog = (event) => {
    setConfirmOpen(false)
    onCanceled()

    setTimeout(() => {
      reset()
      setStepIndex(showReleaseOfEscrow ? 1 : 2)
    }, 300)
  }

  const onCancelClosing = () => {
    setConfirmOpen(false)
    setLoading(true)

    post(route('transactions.cancel', transaction.id), {
      onSuccess: () => {
        onCloseDialog()
      },
      onFinish: () => {
        setLoading(false)
      },
    })
  }

  return (
    <Fragment>
      <ConfirmationDialog
        open={open}
        title={
          !showReleaseOfEscrow
            ? 'Reset Transaction & Start a New Offer'
            : stepIndex == 1
              ? 'Step 1 - Release of Escrow' + (!transaction.escrow_held_by_blre ? ' (Optional)' : '')
              : 'Step 2 - Reset & Start a New Offer'
        }
        buttonText="CONTINUE"
        size={stepIndex == 1 ? 'sm:max-w-xl' : 'sm:max-w-4xl'}
        onCanceled={(event) => onCloseDialog(event)}
        onApproved={() => onApproved()}
        disabled={transaction.escrow_received_at && (!Boolean(data.release_of_escrow) || !Boolean(data.escrow_mailing_address))}
      >
        {stepIndex == 1 && (
          <ReleaseOfEscrow clearErrors={clearErrors} data={data} transaction={transaction} errors={errors} setData={setData} />
        )}

        {stepIndex == 2 && (
          <CancelClosing
            focusRef={focusRef}
            cooperatingParty={cooperatingParty}
            data={data}
            errors={errors}
            setData={setData}
            transaction={transaction}
          />
        )}

        <DeleteConfirmationDialog
          open={confirmOpen}
          title="Are your sure?"
          buttonText="YES, CANCEL THE CLOSING"
          size="sm:max-w-xl"
          onCanceled={() => setConfirmOpen(false)}
          onApproved={() => onCancelClosing()}
          disabled={loading}
        >
          <Alert
            heading={
              <p>
                This action is <span className="font-bold uppercase text-red-600">final</span> and can't be reversed.
              </p>
            }
            subtext="Are you sure you would you like to continue?"
            type="danger"
          />
        </DeleteConfirmationDialog>
      </ConfirmationDialog>
    </Fragment>
  )
}
