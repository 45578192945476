import React from 'react'
import classNames from 'classnames'
import { BlockUIProvider } from '@/Shared/BlockUI/BlockUIContext'
import BlockUI from '@/Shared/BlockUI/Index'
import FlashMessages from '@/Shared/FlashMessages'
import Head from '@/Shared/Head'
import Notifications from '@/Shared/Notifications/Index'
import { NotificationProvider } from '@/Shared/Notifications/NotificationContext'
import Toasts from '@/Shared/Toasts'
import TopHeader from '@/Shared/TopHeader'
import { usePage } from '@inertiajs/react'

const Providers = ({ children, notificationsEnabled }) => {
  const { user } = usePage().props.auth

  return notificationsEnabled && user.type === 'Associate' ? (
    <BlockUIProvider>
      <NotificationProvider>{children}</NotificationProvider>
    </BlockUIProvider>
  ) : (
    <BlockUIProvider>{children}</BlockUIProvider>
  )
}

export default function Layout({ background, title, children, overflowHidden, standAlone }) {
  return (
    <Providers notificationsEnabled={!standAlone}>
      <div className={classNames('flex h-full flex-1 flex-shrink-0 flex-col', background || 'bg-gray-100')}>
        <Head title={title} />
        <div className="wrapper flex h-full flex-auto flex-col">
          <BlockUI />
          <TopHeader standAlone={standAlone} />
          <Toasts />
          <FlashMessages />
          {!standAlone && <Notifications />}

          <div className="flex h-full flex-auto overflow-hidden">
            {/* To reset scroll region (https://inertiajs.com/pages#scroll-regions) add `scroll-region="true"` to div below */}
            <div
              className={classNames('flex flex-grow flex-col', {
                'overflow-hidden overflow-y-auto': !overflowHidden,
                'overflow-y-auto lg:overflow-hidden': overflowHidden,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </Providers>
  )
}
