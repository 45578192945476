import { Fragment, useRef } from 'react'
import Dialog from '@/Shared/Dialog/Index'
import Button from '@/Shared/Button'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'

export default function RevisionDialog({ revisions, open, onClosing }) {
  const { contact } = usePage().props
  const addableFields = ['phone_numbers', 'emails', 'websites', 'addresses']

  let focusRef = useRef()

  const getRelationCurrentValue = (revision) => {
    let relation = contact[revision.field_name].find((relation) => relation.identifier.toLowerCase() === revision.value.toLowerCase())
    if (relation) {
      return relation.label
    }
  }

  const isUpdateable = (revision) => {
    return (
      revision.action === 'updated' &&
      contact[revision.field_name]?.length > 0 &&
      addableFields.some((field) => field === revision.field_name)
    )
  }

  const onAdd = (revision) => {
    router.patch(route('contacts.revisions.update', { contact: contact.id, revision: revision.id }), {
      action: 'added',
    })
  }

  const onAccept = (revision) => {
    router.patch(route('contacts.revisions.update', { contact: contact.id, revision: revision.id }))
  }

  const onDismiss = (revision) => {
    router.delete(route('contacts.revisions.destroy', { contact: contact.id, revision: revision.id }), {
      onFinish: () => onClosing(),
    })
  }

  return (
    <Dialog
      focusRef={focusRef}
      background="bg-gray-100"
      cancelText="Close"
      position="top"
      show={open}
      size="sm:max-w-4xl"
      title={`Pending Revisions (${revisions.length})`}
      onClosed={() => onClosing()}
    >
      <div className="space-y-4">
        <p>
          The following pending revisions have been received from Transactions this contact is linked to or a linked PRO you are following
          updates for (where available):
        </p>

        <div className="block divide-y divide-dashed divide-gray-300 overflow-hidden rounded-b-lg rounded-t-lg border border-gray-300 bg-white shadow">
          {revisions.map((revision) => (
            <div className="flex flex-wrap items-center gap-4 px-4 py-2 even:bg-gray-50 sm:flex-nowrap" key={revision.id}>
              {Array.isArray(contact[revision.field_name]) && revision.action !== 'updated' ? (
                {
                  added: (
                    <Fragment>
                      <span className="flex h-6 w-6 items-center justify-center rounded-md bg-green-100 text-sm font-medium uppercase text-green-800">
                        <i className="fas fa-plus"></i>
                      </span>
                    </Fragment>
                  ),
                  deleted: (
                    <Fragment>
                      <span className="flex h-6 w-6 items-center justify-center rounded-md bg-red-100 text-sm font-medium uppercase text-red-700">
                        <i className="fas fa-trash"></i>
                      </span>
                    </Fragment>
                  ),
                }[revision.action]
              ) : (
                <span className="flex h-6 w-6 items-center justify-center rounded-md bg-gray-200 text-sm font-medium uppercase text-gray-700">
                  <i className="fas fa-repeat"></i>
                </span>
              )}

              <div className="flex-1">
                <div className="space-y-1 sm:flex sm:flex-wrap sm:items-center sm:gap-x-2 sm:space-y-0">
                  <div className="font-semibold text-gray-600">{revision.field}:</div>

                  {Array.isArray(contact[revision.field_name]) ? (
                    <div className="flex flex-row flex-wrap items-center gap-2 leading-snug sm:flex-[inherit] sm:gap-y-0">
                      {
                        {
                          added: (
                            <Fragment>
                              <div className="font-medium text-blue-500">{revision.changes?.label || 'None'}</div>
                            </Fragment>
                          ),
                          updated: (
                            <Fragment>
                              <div className="inline-block rounded-md bg-gray-200 p-2 text-gray-700 line-through sm:rounded-none sm:bg-transparent sm:p-0">
                                {getRelationCurrentValue(revision) || 'None'}
                              </div>
                              <i className="far fa-long-arrow-right mx-auto rotate-90 sm:mx-0 sm:rotate-0"></i>
                              <div className="inline-block rounded-md bg-blue-100 p-2 font-medium text-blue-500 sm:rounded-none sm:bg-transparent sm:p-0">
                                {revision.changes?.label || 'None'}
                              </div>
                            </Fragment>
                          ),
                          deleted: (
                            <Fragment>
                              <div className="text-red-600">{getRelationCurrentValue(revision) || 'None'}</div>
                            </Fragment>
                          ),
                        }[revision.action]
                      }
                    </div>
                  ) : (
                    <div className="flex flex-wrap items-center gap-2 leading-snug">
                      <div className="text-gray-700 line-through">{contact[revision.field_name] || 'None'}</div>
                      <i className="far fa-long-arrow-right"></i>
                      <div className="font-medium text-blue-500">{revision.value || 'None'}</div>
                    </div>
                  )}
                </div>

                <div className="mt-1.5 flex flex-wrap items-center gap-2 text-sm leading-none sm:mt-0.5">
                  <div className="text-gray-500 text-opacity-75">{revision.updater}</div>
                  {revision.source && (
                    <Fragment>
                      <i className="fas fa-circle text-[4px] text-gray-500 text-opacity-50"></i>
                      <div className="text-gray-500 text-opacity-75">{revision.source}</div>
                    </Fragment>
                  )}

                  <i className="fas fa-circle text-[4px] text-gray-500 text-opacity-50"></i>
                  <div className="capitalize text-gray-500 text-opacity-75">{revision.action}</div>

                  <i className="fas fa-circle text-[4px] text-gray-500 text-opacity-50"></i>
                  <div className="text-gray-500 text-opacity-75">{revision.created_at}</div>
                </div>
              </div>

              <div className="sm:justify-auto flex w-full items-center justify-end sm:w-auto">
                {isUpdateable(revision) && (
                  <Tooltip label="Add" placement="bottom">
                    <Button theme="icon" onClick={() => onAdd(revision)}>
                      <span className="sr-only">Add</span>
                      <i className="fas fa-plus text-lg text-green-700"></i>
                    </Button>
                  </Tooltip>
                )}

                <Tooltip label={isUpdateable(revision) ? 'Replace' : 'Approve'} placement="bottom">
                  <Button theme="icon" onClick={() => onAccept(revision)}>
                    <span className="sr-only">{isUpdateable(revision) ? 'Replace' : 'Approve'}</span>
                    {isUpdateable(revision) ? (
                      <i className="fas fa-repeat text-lg text-red-600"></i>
                    ) : (
                      <i className="fas fa-check text-xl text-green-700"></i>
                    )}
                  </Button>
                </Tooltip>

                <Tooltip label="Dismiss" placement="bottom">
                  <Button theme="icon" onClick={() => onDismiss(revision)}>
                    <span className="sr-only">Dismiss</span>
                    <i className="fas fa-times text-xl text-gray-500"></i>
                  </Button>
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  )
}
