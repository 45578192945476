import { Fragment, useEffect, useState } from 'react'
import classNames from 'classnames'
import { router } from '@inertiajs/react'
import { Transition } from '@headlessui/react'
import { useForm, usePage } from '@inertiajs/react'

import Alert from '@/Shared/Alert'
import Avatar from '@/Shared/Avatar'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import Helpers from '@/utils/helpers'
import Radio from '@/Shared/Forms/Radio'
import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'

export default function ContactSearchItem({ activeContactId, contact, last, position, settings, transaction, onAdding, onClose, onError }) {
  const { auth, roles: originalRoles } = usePage().props
  const [adding, setAdding] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [isTeam, setIsTeam] = useState(false)
  const [roles, setRoles] = useState(originalRoles)
  const associates = transaction.contacts.filter((tc) => tc.contact_type === 'Associate')
  const transaction_support_enabled = associates.some((associate) => associate.transaction_support)
  const supportableContacts = transaction.contacts
    .filter((contact) => contact.roles?.length > 0)
    .sort((a, b) => {
      if (a.first_name < b.first_name) return -1
      if (a.first_name > b.first_name) return 1
      return 0
    })
    .map((contact) => ({ value: contact.id, label: contact.legal_name }))
  const currentRole =
    contact.type === 'Controller'
      ? roles.filter((role) => role.value === 'Escrow Agent')
      : settings?.roles && roles.find((r) => r.value === settings.roles[0])

  const existingContact = transaction.contacts.find(
    (c) =>
      (c.contact ? c.contact.id : c.id) == contact.contact_id ||
      (c.contact ? c.contact.id : c.id) == contact.id ||
      c.followers?.find((f) => f.id == contact.id),
  )

  const permissions = [
    { value: 'view', label: 'Viewer' },
    { value: 'edit', label: 'Editor' },
    { value: 'admin', label: 'Administrator' },
  ]

  const form = useForm({
    id: contact.id,
    type: contact.type,
    roles: currentRole ? (contact.roles || []).concat(currentRole) : contact.roles || [],
    role_type: currentRole || ['Controller', 'Associate'].some((r) => r.includes(contact.type)) ? 'Primary' : '',
    supported_contact_id: null,
    cc_for_supported_contact: null,
    permissions: 'edit',
    party_representing:
      contact.type === 'Controller'
        ? transaction.type
        : settings?.group
          ? transaction.parties.filter((p) => p === settings.group)[0]
          : existingContact?.party_representing[0] || '',
    commission_splits: [],
    follow: contact.type !== 'Contact' && !contact.following,
    brokerage_split_auto_distribute: true,
    transaction_support: false,
  })

  const { clearErrors, data, errors, setData, setError } = form
  const splits = data.commission_splits?.filter((split) => !split.transaction_support) || []

  useEffect(() => checkIfTeam(), [data.party_representing])
  useEffect(() => filterRoles(), [data.roles, data.party_representing])

  useEffect(() => {
    if (adding) {
      let hasError = false

      if (!data.transaction_support) {
        let totalShare = splits.reduce((carry, split) => carry + split.commission_pct, 0)
        let totalCap = splits.reduce((carry, split) => carry + split.cap_pct, 0)

        if (totalShare != 100) {
          setError('commission_splits', `The total of all splits must add up to 100%. (Current: ${totalShare}%)`)
          hasError = true
        } else {
          clearErrors('commission_splits')
        }

        if (!data.brokerage_split_auto_distribute && totalCap != 100) {
          setError('brokerage_splits', `The total of all brokerage-side splits must add up to 100%. (Current: ${totalCap})`)
          hasError = true
        } else {
          clearErrors('brokerage_splits')
        }
      }

      setDisabled(hasError)
    }
  }, [data.commission_splits])

  useEffect(() => {
    if (isTeam) {
      let associatesCount = associates.filter((split) => !split.transaction_support).length + 1
      let equalShare = Helpers.numberPrecision(100 / associatesCount, 2)
      let combinedShares = Helpers.numberPrecision(equalShare * associatesCount, 2)
      let remainder = Helpers.round(100 - combinedShares, 2)

      setData({
        ...data,
        roles: (data.roles || [])
          .filter((r) => r.value !== `${transaction.type}-Agent`)
          .concat(originalRoles.find((r) => r.value === `${transaction.type}-Agent`)),
        commission_splits: Array.from(associates, (associate) => ({
          id: associate.id,
          full_name: associate.full_name,
          is_transaction_owner: associate.is_transaction_owner,
          transaction_support: associate.transaction_support,
          commission_pct: associate.split,
          cap_pct: associate.cap_split,
        }))
          .concat({ contact_id: contact.id, full_name: contact.name })
          .map((associate) => {
            if (associate.transaction_support) {
              return associate
            }

            let commission_pct = associate.is_transaction_owner ? Math.round((equalShare + remainder) * 1e12) / 1e12 : equalShare

            return {
              ...associate,
              commission_pct: commission_pct,
              cap_pct: commission_pct,
            }
          }),
      })
    }
  }, [isTeam])

  useEffect(() => {
    if (isTeam) {
      const maxShare = data.transaction_support ? 15 : 100

      // Determine which splits should be updated
      let splitsToUpdate = data.commission_splits.filter((split) =>
        data.transaction_support
          ? split.transaction_support || split.contact_id === data.id
          : !split.transaction_support || split.contact_id === data.id,
      )

      // Calculate equal shares and handle rounding remainder
      let associatesCount = splitsToUpdate.length
      let equalShare = Helpers.numberPrecision(maxShare / associatesCount, 2)
      let combinedShares = Helpers.numberPrecision(equalShare * associatesCount, 2)
      let remainder = Helpers.round(maxShare - combinedShares, 2)
      let equalCommissionPct = Math.round((equalShare + remainder) * 1e12) / 1e12

      const updatedSplits = data.commission_splits.map((split) => {
        if (data.transaction_support) {
          if (split.transaction_support || split.contact_id === data.id) {
            return { ...split, commission_pct: equalCommissionPct, cap_pct: 0 }
          }
        } else {
          if (!split.transaction_support || split.contact_id === data.id) {
            let commission_pct = split.is_transaction_owner ? equalCommissionPct : equalShare

            return {
              ...split,
              commission_pct: commission_pct,
              cap_pct: commission_pct,
            }
          }
        }

        // Reset to stored value
        const associate = associates.find((associate) => associate.id === split.id)
        return associate ? { ...split, commission_pct: associate.split, cap_pct: associate.cap_split } : split
      })

      setData({ ...data, commission_splits: updatedSplits })
    }
  }, [data.transaction_support])

  useEffect(() => {
    if (activeContactId != contact.id) {
      setAdding(false)
    }
  }, [activeContactId])

  useEffect(() => {
    if (errors.id) {
      onError({ contactExists: errors.id })
    }
  }, [errors])

  const canAssignRole = (existingContact) => {
    if (!currentRole || existingContact.type === 'Associate') {
      return false
    }

    // Check if the existing contact has a main role
    const parties = transaction.parties
    const existingParty = existingContact.roles.find((role) => parties.includes(role.name))

    // Eligible if the existing contact doesn't have a main role
    if (!existingParty) {
      return true
    }

    // Main roles are not eligible to be the Escrow Agent
    const isRoleAvailableToMainRoles = ['Escrow Agent'].find((r) => r === currentRole.value) == undefined
    if (!isRoleAvailableToMainRoles) {
      return false
    }

    // Eligible if the existing contact's main role matches the role's party
    const currentRoleParty = currentRole.value.split('-')[0]
    const isNonPartyRole = !parties.some((mainRole) => mainRole.includes(currentRoleParty))
    const isSamePartyRole = existingParty.name === currentRoleParty

    return isNonPartyRole || isSamePartyRole
  }

  const checkIfTeam = () => {
    if (data.type === 'Associate' && auth.user.type === 'Associate') {
      const leadAssociate = associates.find((a) => a.is_transaction_owner)
      if (leadAssociate) {
        setIsTeam(leadAssociate.party_representing[0] === data.party_representing)
      }
    }
  }

  const getEligibleRoles = () => {
    let eligibleRoles = roles

    if (contact.type === 'Controller') {
      eligibleRoles = eligibleRoles.filter((role) => role.value === 'Escrow Agent')
    } else if (contact.type === 'Associate') {
      const eligiblAssociateRoles = [
        transaction.type,
        `${transaction.type}-Agent`,
        transaction.cooperating_party,
        `${transaction.cooperating_party}-Agent`,
      ]

      eligibleRoles = eligibleRoles.filter((role) =>
        eligiblAssociateRoles.filter((r) => r !== transaction.type).some((r) => r === role.value),
      )
    }

    // remove Escrow Agent role if it is already assigned
    if (transaction.contacts.find((contact) => contact.roles.find((role) => role.name === 'Escrow Agent'))) {
      eligibleRoles = eligibleRoles.filter((role) => role.value !== 'Escrow Agent')
    }

    // Filter roles when party representing is known
    if (data.party_representing) {
      eligibleRoles = eligibleRoles.filter(
        (role) => !role.value.includes(data.party_representing === transaction.type ? transaction.cooperating_party : transaction.type),
      )
    }

    return eligibleRoles
  }

  const getSplitName = (split) => {
    let name = contact.name
    let transactionContact = split.id && associates.find((tc) => tc.id == split.id)
    if (transactionContact) {
      name = transactionContact.contact
        ? `${transactionContact.contact.first_name} ${transactionContact.contact.last_name}`
        : transactionContact.full_name
    }

    return name + ' '
  }

  const filterRoles = () => {
    let activeKeyRoles = data.roles?.filter((r) => transaction.parties.indexOf(r.value) >= 0)
    setRoles(
      activeKeyRoles?.length > 0
        ? originalRoles.filter((r) => activeKeyRoles.find((ar) => r == ar) || transaction.parties.indexOf(r.value) < 0)
        : originalRoles,
    )
  }

  const isTransactionSupport = () => {
    if (transaction_support_enabled) {
      const myContact = transaction.contacts.find((tc) => tc.is_me)
      return myContact?.transaction_support || Helpers.isAdminOrHR(auth.user)
    }

    return false
  }

  const roleChanged = (selected) => {
    clearErrors('roles')

    const [lastItem] = selected ? selected.slice(-1) : []
    const keyRoleWasSelected = lastItem && transaction.parties.some((keyRole) => lastItem.value === keyRole)

    // Remove key roles if last selected item wasn't a key role
    if (selected && !keyRoleWasSelected) {
      selected = selected.filter((s) => !transaction.parties.some((keyRole) => s.value === keyRole))
    }

    setData({
      ...data,
      roles: keyRoleWasSelected ? [lastItem] : selected,
      party_representing: keyRoleWasSelected ? lastItem.value : data.party_representing,
    })
  }

  const partyRepresentingChanged = (selected) => {
    clearErrors('party_representing')

    setData({
      ...data,
      roles: currentRole && !data.roles && currentRole.value.includes(selected.value) ? [currentRole] : switchPartyRoles(selected?.value),
      role_type:
        contact.type === 'Controller' || (contact.type === 'Associate' && data.party_representing === transaction.type)
          ? 'Primary'
          : data.role_type,
      party_representing: selected?.value,
    })
  }

  const switchPartyRoles = (newParty) => {
    if (data.roles == undefined) return []

    let partyRoles = data.roles.filter((role) => data.party_representing && role.value?.includes(data.party_representing))

    return data.roles
      .concat(
        partyRoles.map((role) => {
          let match = roles.find((r) => r.value == role.value.replace(data.party_representing, newParty))
          if (match) {
            return { ...match, name: match.value }
          }
        }),
      )
      .filter((role) => role && !partyRoles.find((pr) => pr.value == role.value))
  }

  const assignRole = () => {
    setDisabled(true)

    router.patch(
      route('transactions.contacts.update', { transaction: transaction.id, transactionContact: existingContact.id }),
      {
        party_representing: [data.party_representing],
        role_type: 'Primary',
        roles: existingContact.roles
          .reduce(
            (roles, role) =>
              roles.concat({
                name: role.party_representing ? `${role.party_representing}-${role.name}` : role.name,
                primary: role.primary,
              }),
            [],
          )
          .concat({
            name: currentRole.value,
          }),
      },
      {
        onSuccess: () => {
          onClose()
          setTimeout(() => {
            setDisabled(false)
          }, 300)
        },
      },
    )
  }

  const submit = (event) => {
    event.preventDefault()

    setDisabled(true)

    router.post(
      route('transactions.contacts.store', transaction.id),
      {
        ...data,
        party_representing: data.party_representing ? [data.party_representing] : [],
        roles: data.roles?.map((s) => ({ name: s.value })),
      },
      {
        onSuccess: () => {
          clearErrors()
          onClose()
          setTimeout(() => {
            setAdding(false)
          }, 300)
        },
        onError: (error) => {
          clearErrors()
          setError(error)
        },
        onFinish: () => {
          setTimeout(() => {
            setDisabled(false)
          }, 300)
        },
      },
    )
  }

  return (
    <div
      className={classNames(
        'relative transition-all duration-100 ease-in-out',
        adding
          ? 'z-50 -mx-2 -mb-1 -mt-2 rounded-lg border-2 border-gray-400 shadow-md'
          : [
              'border-gray-300',
              {
                'rounded-b-lg': position == 1,
                'border-b border-l border-r': !last,
                'rounded-t-lg border': last,
                border: position == 1 && last,
              },
            ],
      )}
    >
      <div>
        <div
          className={classNames('relative z-0 flex items-start justify-start bg-white p-5', {
            'rounded-b-lg': position == 1,
            'rounded-t-lg': adding || last,
          })}
        >
          <div className="flex flex-grow flex-wrap justify-center gap-3 text-left sm:justify-start">
            <div className="w-18 flex flex-col items-center justify-center gap-1">
              <Avatar contact={contact} height="h-14" width="w-14" />

              {contact.following && (
                <div
                  className={classNames(
                    'mt-0.5 inline-flex items-center whitespace-nowrap rounded-full px-2.5 py-1.5 text-sm font-medium uppercase leading-none',
                    contact.type === 'Contact' ? 'bg-gray-200 bg-opacity-75 text-gray-800' : 'bg-purple-100 text-purple-800',
                  )}
                >
                  <span>{contact.type === 'Contact' ? 'Contact' : 'PRO'}</span>
                </div>
              )}
            </div>

            <div className="mb-2 flex flex-1 flex-col justify-center leading-snug text-gray-600 sm:mb-0">
              <div className="font-semibold text-blue-500">{contact.name}</div>

              {contact.company && (
                <div className="font-medium">{contact.type === 'Associate' ? 'Better Living Real Estate' : contact.company}</div>
              )}

              {contact.type !== 'Controller' && contact.industry && <div>{contact.industry}</div>}

              {existingContact?.roles && (
                <div className="mt-0.5 flex flex-wrap gap-1">
                  {existingContact.roles?.map((role, index) => (
                    <span
                      className="inline-block rounded bg-blue-200 bg-opacity-40 px-1.5 py-1 text-sm font-medium leading-none text-blue-700"
                      key={index}
                    >
                      <span className="whitespace-pre-line leading-none">
                        {roles.find((r) => r.value === `${role.party_representing}-${role.name}` || r.value === role.name)?.label ||
                          role.name}
                      </span>
                    </span>
                  ))}
                </div>
              )}
            </div>

            <div className={classNames('flex justify-end self-stretch sm:w-auto md:pl-4', adding ? 'items-start' : 'items-center')}>
              {existingContact ? (
                <div className="space-y-3">
                  <div className="flex items-center justify-end">
                    <i
                      className={classNames(
                        'pr-2 text-lg leading-none',
                        existingContact.pending ? 'fas fa-user-clock text-blue-500' : 'fas fa-check text-green-600',
                      )}
                    ></i>
                    {existingContact.pending ? 'Pending' : 'Added'}
                  </div>

                  {canAssignRole(existingContact) && (
                    <Button type="submit" theme="outline-sm" disabled={disabled} onClick={() => assignRole()}>
                      <div className="relative flex w-full items-center justify-center font-medium leading-none">
                        Assign {currentRole.label} Role
                      </div>
                    </Button>
                  )}
                </div>
              ) : adding ? (
                <Button theme="icon" onClick={() => setAdding(false)}>
                  <span className="sr-only">Close panel</span>
                  <i className="fal fa-times text-2xl leading-none text-opacity-80"></i>
                </Button>
              ) : (
                <Button theme="outline-sm" onClick={() => setAdding(true)}>
                  Add
                  <div className="flex h-5 w-5 justify-end leading-none">
                    <i className="fas fa-angle-down text-base" aria-hidden="true"></i>
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>

        <Transition
          show={adding}
          enter="transition ease duration-300 transform"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          beforeEnter={() => onAdding(contact.id)}
        >
          <form id="addContact" className="relative z-0 rounded-b-md border-t border-gray-300 bg-gray-100 p-6" onSubmit={submit}>
            <ul role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 bg-white">
              {contact.type !== 'Controller' &&
                (!data.roles || data.roles.filter((r) => transaction.parties.indexOf(r.value) >= 0).length == 0) && (
                  <li
                    className={classNames('flex items-center justify-between py-3 pl-3 pr-4', errors?.party_representing && 'bg-red-100')}
                  >
                    <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                      <div className="mb-1.5 text-left sm:mb-0 sm:flex sm:flex-1 sm:flex-wrap sm:items-center">
                        <span className="flex-1 font-semibold text-gray-800">
                          Which party is this contact representing? <span className="text-red-700">*</span>
                        </span>
                        {errors.party_representing && <div className="w-full text-red-700">{errors.party_representing}</div>}
                      </div>
                      <div className="flex flex-shrink-0 space-x-4 sm:ml-4">
                        <Select
                          classes="w-full sm:w-80"
                          name="party_representing"
                          options={transaction.parties.map((party) => ({ value: party, label: party }))}
                          placeholder="Choose one"
                          value={data.party_representing}
                          isClearable={false}
                          onChange={(selected) => partyRepresentingChanged(selected)}
                        />
                      </div>
                    </div>
                  </li>
                )}
              <li
                className={classNames(
                  'flex min-h-[4rem] items-center justify-between py-3 pl-3 pr-4',
                  errors?.role_type && 'rounded-lg border border-red-500 bg-red-100/90',
                )}
              >
                <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                  <div className="mb-1.5 text-left sm:mb-0 sm:flex sm:flex-1 sm:flex-wrap sm:items-center">
                    <span className="flex-1 font-semibold text-gray-800">Primary or Support Role?</span>
                    {errors.role_type && <div className="w-full text-red-700">{errors.role_type}</div>}
                  </div>
                  <div className="flex w-full shrink-0 items-center space-x-6 sm:ml-4 sm:w-80">
                    <Radio
                      id="role_type_primary"
                      name="role_type"
                      label="Primary"
                      value={data.role_type === 'Primary'}
                      onChange={() => {
                        clearErrors('role_type', 'supported_contact_id', 'cc_for_supported_contact')
                        setData({ ...data, role_type: 'Primary', supported_contact_id: null, cc_for_supported_contact: null, title: '' })
                      }}
                      disabled={
                        contact.type === 'Controller' || (contact.type === 'Associate' && data.party_representing === transaction.type)
                      }
                    />
                    <Radio
                      id="role_type_support"
                      name="role_type"
                      label="Support"
                      value={data.role_type === 'Support'}
                      onChange={() => {
                        clearErrors('role_type', 'roles')
                        setData({ ...data, role_type: 'Support', roles: [], cc_for_supported_contact: true })
                      }}
                      disabled={
                        contact.type === 'Controller' || (contact.type === 'Associate' && data.party_representing === transaction.type)
                      }
                    />
                  </div>
                </div>
              </li>

              {data.role_type === 'Primary' && (
                <li className={classNames('flex items-center justify-between py-3 pl-3 pr-4', errors?.roles && 'bg-red-100')}>
                  <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                    <div className="mb-1.5 leading-tight sm:mb-0">
                      <div className="font-semibold text-gray-800">Select Primary Role(s)</div>
                      {errors.roles && <div className="w-full text-red-700">{errors.roles}</div>}
                    </div>
                    <div className="flex shrink-0 space-x-4 sm:ml-4">
                      <Select
                        classes="w-full sm:w-80"
                        name="roles"
                        options={getEligibleRoles()}
                        placeholder="None"
                        value={data.roles}
                        isClearable={false}
                        onChange={(selected) => roleChanged(selected)}
                        multiple
                        creatable
                        disabled={
                          contact.type === 'Controller' || (contact.type === 'Associate' && data.party_representing === transaction.type)
                        }
                      />
                    </div>
                  </div>
                </li>
              )}

              {data.role_type === 'Support' && (
                <Fragment>
                  <li
                    className={classNames('flex items-center justify-between py-3 pl-3 pr-4', errors?.supported_contact_id && 'bg-red-100')}
                  >
                    <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                      <div className="mb-1.5 leading-tight sm:mb-0">
                        <div className="font-semibold text-gray-800">Contact Supporting</div>
                        <div className="text-sm text-blue-500">
                          <i className="far fa-info-circle"></i> Supported contact must have a primary role to be eligible.
                        </div>
                        {errors.supported_contact_id && <div className="w-full text-red-700">{errors.supported_contact_id}</div>}
                      </div>
                      <div className="flex shrink-0 space-x-4 sm:ml-4">
                        <Select
                          classes="w-full sm:w-80"
                          name="supported_contact_id"
                          placeholder="Select One"
                          options={supportableContacts}
                          error={errors.supported_contact_id}
                          value={data.supported_contact_id}
                          onChange={(selected) => {
                            clearErrors('supported_contact_id')
                            setData({ ...data, supported_contact_id: selected?.value })
                          }}
                        />
                      </div>
                    </div>
                  </li>

                  <li
                    className={classNames(
                      'flex items-center justify-between py-3 pl-3 pr-4',
                      errors?.cc_for_supported_contact && 'bg-red-100',
                    )}
                  >
                    <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                      <div className="mb-1.5 leading-tight sm:mb-0">
                        <div className="font-semibold text-gray-800">Copy on Emails Sent to Supported Contact?</div>
                        {errors.cc_for_supported_contact && (
                          <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.cc_for_supported_contact }}></div>
                        )}
                      </div>
                      <div className="flex w-full shrink-0 items-center space-x-6 sm:ml-4 sm:w-80">
                        <Checkbox
                          name="cc_for_supported_contact"
                          value={data.cc_for_supported_contact}
                          onChange={(selected) => setData({ ...data, cc_for_supported_contact: selected })}
                        />
                      </div>
                    </div>
                  </li>

                  <li
                    className={classNames(
                      'flex items-center justify-between py-3 pl-3 pr-4',
                      errors?.cc_for_supported_contact && 'bg-red-100',
                    )}
                  >
                    <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                      <div className="mb-1.5 leading-tight sm:mb-0">
                        <div className="font-semibold text-gray-800">Job Title</div>
                      </div>
                      <div className="flex w-full shrink-0 items-center space-x-6 sm:ml-4 sm:w-80">
                        <TextInput
                          classes="mb-0 w-full"
                          name="title"
                          value={data.title}
                          onChange={(value) => setData({ ...data, title: value })}
                          clearable
                        />
                      </div>
                    </div>
                  </li>
                </Fragment>
              )}

              {!['Contact', 'Controller'].some((type) => type === contact.type) && !contact.following && (
                <li className="flex items-center justify-center bg-gray-50 py-3 pl-3 pr-4">
                  <Checkbox
                    label="Follow this contact"
                    name="follow"
                    value={data.follow}
                    onChange={(checked) => setData({ ...data, follow: checked })}
                  />
                </li>
              )}
            </ul>

            {isTeam && (
              <div className="mt-5 space-y-5">
                <h3 className="block text-lg font-medium">Associate Settings</h3>

                {isTransactionSupport() && (
                  <div className="flex space-x-4">
                    <Radio
                      id="transaction_support_no"
                      name="transaction_support"
                      label={<span className={classNames('font-bold', { 'text-blue-500': !data.transaction_support })}>Co-Service</span>}
                      value={!data.transaction_support}
                      onChange={() =>
                        setData({
                          ...data,
                          transaction_support: false,
                        })
                      }
                    />

                    <Radio
                      id="transaction_support_yes"
                      name="transaction_support"
                      label={
                        <span className={classNames('font-bold', { 'text-orange-600': data.transaction_support })}>
                          Transaction Support
                        </span>
                      }
                      value={data.transaction_support}
                      onChange={() =>
                        setData({
                          ...data,
                          transaction_support: true,
                        })
                      }
                    />
                  </div>
                )}

                <div className="my-3 space-y-3">
                  {data.transaction_support ? (
                    <div className="space-y-3">
                      <ul role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 bg-white">
                        {data.transaction_support && (
                          <li className="py-3 pl-3 pr-4">
                            <div className="ml-4 sm:flex sm:justify-between sm:gap-6">
                              <div className="flex-1">
                                <span className="font-semibold text-gray-800">
                                  Transaction Support Associate Splits: <span className="text-red-700">*</span>
                                </span>

                                <p className="text-gray-500">
                                  Changes to Transaction Support Associate splits requires approval from each affected Associate.
                                </p>

                                {errors?.transaction_support_splits && (
                                  <div className="mt-1 font-semibold text-red-600">* {errors.transaction_support_splits}</div>
                                )}
                              </div>

                              <div className="mb-1 space-y-4 sm:w-80">
                                {data.commission_splits
                                  .filter((split) => split.transaction_support || split.contact_id === data.id)
                                  .map((split, index) => (
                                    <div key={index}>
                                      <div className="text-sm font-semibold uppercase text-blue-500">
                                        {getSplitName(split)}
                                        <span className="text-red-700">*</span>
                                      </div>
                                      <div className="relative flex w-full">
                                        <input
                                          autoComplete="off"
                                          autoCorrect="false"
                                          className={classNames(
                                            'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                                            errors?.transaction_support_splits
                                              ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                              : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                                          )}
                                          type="number"
                                          value={split.commission_pct}
                                          onChange={(e) => {
                                            clearErrors('transaction_support_splits')
                                            setData({
                                              ...data,
                                              commission_splits: data.commission_splits.map((s) => {
                                                if (s.id === split.id) {
                                                  return {
                                                    ...s,
                                                    commission_pct: parseFloat(e.target.value),
                                                    cap_pct: 0,
                                                  }
                                                }

                                                return s
                                              }),
                                            })
                                          }}
                                          onFocus={(e) => e.target.select()}
                                        />

                                        <span
                                          className={classNames(
                                            'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                                            errors?.transaction_support_splits
                                              ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                              : 'border-gray-300 bg-gray-200 text-gray-700',
                                          )}
                                        >
                                          <i className="fal fa-percent text-lg"></i>
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  ) : (
                    <div className="space-y-3">
                      <ul role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 bg-white">
                        <li className={classNames('flex items-center justify-between py-3 pl-3 pr-4', errors?.permissions && 'bg-red-100')}>
                          <div className="ml-4 flex-grow sm:ml-4 sm:flex sm:items-center sm:justify-between">
                            <div className="mb-1.5 text-left sm:mb-0 sm:flex sm:flex-1 sm:flex-wrap sm:items-center">
                              <span className="flex-1 font-semibold text-gray-800">
                                Specify permissions for this contact <span className="text-red-700">*</span>
                              </span>
                              {errors.permissions && <div className="w-full text-red-700">{errors.permissions}</div>}
                            </div>
                            <div className="flex flex-shrink-0 space-x-4 sm:ml-4">
                              <Select
                                classes="w-full sm:w-80"
                                name="permissions"
                                options={permissions}
                                placeholder="Choose one"
                                value={data.permissions}
                                isClearable={false}
                                onChange={(selected) => {
                                  clearErrors('permissions')
                                  setData({ ...data, permissions: selected ? selected.value : '' })
                                }}
                              />
                            </div>
                          </div>
                        </li>
                        <li className="py-3 pl-3 pr-4">
                          <div className="ml-4 sm:flex sm:justify-between sm:gap-6">
                            <div className="flex-1">
                              <span className="font-semibold text-gray-800">
                                Associate-Side Splits: <span className="text-red-700">*</span>
                              </span>

                              <p className="text-gray-500">
                                Changes to Associate-Side splits requires approval from each affected Associate.
                              </p>

                              {errors?.commission_splits && (
                                <div className="mt-1 font-semibold text-red-600">* {errors.commission_splits}</div>
                              )}
                            </div>

                            <div className="mb-1 space-y-4 sm:w-80">
                              {splits.map((split, index) => (
                                <div key={index}>
                                  <div className="text-sm font-semibold uppercase text-blue-500">
                                    {getSplitName(split)}
                                    <span className="text-red-700">*</span>
                                  </div>
                                  <div className="relative flex w-full">
                                    <input
                                      autoComplete="off"
                                      autoCorrect="false"
                                      className={classNames(
                                        'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                                        errors?.commission_splits
                                          ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                          : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                                      )}
                                      type="number"
                                      value={split.commission_pct}
                                      onChange={(e) => {
                                        clearErrors('commission_splits')
                                        setData({
                                          ...data,
                                          commission_splits: data.commission_splits.map((s) => {
                                            if (s.id == split.id || (s.contact_id != undefined && s.contact_id == split.contact_id)) {
                                              return {
                                                ...s,
                                                commission_pct: parseFloat(e.target.value),
                                                cap_pct: data.brokerage_split_auto_distribute ? parseFloat(e.target.value) : s.cap_pct,
                                              }
                                            }

                                            return s
                                          }),
                                        })
                                      }}
                                      onFocus={(e) => e.target.select()}
                                    />

                                    <span
                                      className={classNames(
                                        'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                                        errors?.commission_splits
                                          ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                          : 'border-gray-300 bg-gray-200 text-gray-700',
                                      )}
                                    >
                                      <i className="fal fa-percent text-lg"></i>
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </li>

                        <li className={classNames('flex justify-between py-3 pl-3 pr-4', errors?.permissions && 'bg-red-100')}>
                          <div className="ml-4 flex-grow gap-6 sm:ml-4 sm:flex sm:justify-between">
                            <div className="mb-1.5 flex-1 text-left sm:mb-0">
                              <span className="flex flex-1 items-center gap-1.5 font-semibold text-gray-800">
                                Brokerage-Side Splits <span className="text-red-600">*</span>
                              </span>

                              {errors?.brokerage_splits && (
                                <div className="mt-1 font-semibold text-red-600">* {errors.brokerage_splits}</div>
                              )}

                              <div className="pt-4">
                                <Checkbox
                                  name="brokerage_split_auto_distribute"
                                  label="Distribute Automatically"
                                  description="CAP will be applied to all Associates according to their Associate-side split %."
                                  value={data.brokerage_split_auto_distribute}
                                  onChange={(checked) =>
                                    setData({
                                      ...data,
                                      brokerage_split_auto_distribute: checked,
                                      ...(checked
                                        ? {
                                            commission_splits: data.commission_splits.map((s) => ({
                                              ...s,
                                              cap_pct: s.commission_pct,
                                            })),
                                          }
                                        : {}),
                                    })
                                  }
                                />
                              </div>
                            </div>

                            <div className="flex w-full flex-col gap-4 sm:w-80">
                              {splits?.map((split, index) => (
                                <div key={index}>
                                  <div className="text-sm font-semibold uppercase text-blue-500">
                                    {getSplitName(split)}
                                    <span className="text-red-700">*</span>
                                  </div>
                                  <div className="relative flex w-full">
                                    <input
                                      name={`brokerage_split_${index}`}
                                      autoComplete="off"
                                      autoCorrect="false"
                                      className={classNames(
                                        'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                                        data.brokerage_split_auto_distribute ? 'cursor-not-allowed bg-gray-200' : '',
                                        errors?.brokerage_splits
                                          ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                          : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                                      )}
                                      spellCheck="false"
                                      type="number"
                                      value={split.cap_pct}
                                      onChange={(e) => {
                                        clearErrors('brokerage_splits')
                                        setData({
                                          ...data,
                                          commission_splits: data.commission_splits.map((s) => {
                                            if (s.id == split.id || (s.contact_id != undefined && s.contact_id == split.contact_id)) {
                                              return {
                                                ...s,
                                                cap_pct: parseFloat(e.target.value),
                                              }
                                            }

                                            return s
                                          }),
                                        })
                                      }}
                                      onFocus={(e) => e.target.select()}
                                      disabled={data.brokerage_split_auto_distribute}
                                    />

                                    <span
                                      className={classNames(
                                        'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                                        errors?.brokerage_splits
                                          ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                          : 'border-gray-300 bg-gray-200 text-gray-700',
                                      )}
                                    >
                                      <i className="fal fa-percent text-lg"></i>
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </li>
                      </ul>

                      <Alert
                        type="warning"
                        heading="Co-Service Alert"
                        subtext={
                          <p className="font-normal">
                            You are about to add <b>{contact.name}</b> as a Co-Service of your PRO Team. Continuing with this action will
                            grant them <span className="font-semibold uppercase text-blue-500">{data.permissions}</span> access to this
                            transaction.
                          </p>
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="mt-6 flex justify-end">
              <Button type="submit" theme="solid" form="addContact" disabled={disabled}>
                <div className="relative flex w-full items-center justify-center py-1 font-medium leading-none">
                  {isTeam ? (
                    <span>
                      <i className="fas fa-users mr-2"></i> Invite to My PRO Team
                    </span>
                  ) : (
                    <span>
                      <i className="fas fa-user-plus mr-2"></i> Add
                    </span>
                  )}
                </div>
              </Button>
            </div>
          </form>
        </Transition>
      </div>
    </div>
  )
}
