import { Fragment, useEffect, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import Helpers from '@/utils/helpers'
import SlideOver from '@/Shared/SlideOver'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'
import TimePicker from '@/Shared/TimePicker'
import { useForm, usePage } from '@inertiajs/react'

export default ({ entry, type, open, onClosed }) => {
  let focusRef = useRef()
  const { user } = usePage().props.auth
  const { clearErrors, data, delete: destroy, errors, setData, patch, post, reset } = useForm({})
  const [deleting, setDeleting] = useState(false)

  useEffect(() => {
    setData({ ...entry, created_at: entry?.created_at ? new Date(entry.created_at) : null })
  }, [entry])

  const onClosing = () => {
    setDeleting(false)
    clearErrors()
    reset()
    onClosed()
  }

  const onDelete = () => {
    destroy(route(`associates.${type}.destroy`, { associate: entry.associate_id, cap_history: data.id }), {
      onSuccess: () => onClosing(),
    })
  }

  const submit = (event) => {
    event.preventDefault()

    if (data.id) {
      patch(route(`associates.${type}.update`, { associate: data.associate_id, cap_history: data.id }), {
        onSuccess: () => {
          onClosing()
        },
      })
    } else {
      post(route(`associates.${type}.store`, { associate: data.associate_id }), {
        onSuccess: () => {
          onClosing()
        },
      })
    }
  }

  const updateDate = (newDate, currentDate) => {
    if (!newDate) {
      return currentDate
    } else if (!currentDate) {
      newDate.setHours(9)
      newDate.setMinutes(0)
      newDate.setSeconds(0)
      return newDate
    }

    let datetime = new Date(newDate)
    datetime.setHours(currentDate.getHours())
    datetime.setMinutes(currentDate.getMinutes())
    datetime.setSeconds(0)

    return datetime
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="cap-form">
          Save
        </Button>
      }
      footerLeftActions={
        Helpers.isAdminOrHR(user) &&
        data.id && (
          <button
            role="button"
            className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
            onClick={() => setDeleting(true)}
          >
            <span className="sr-only">Delete Entry</span>
            <i className="fas fa-trash text-xl text-gray-800 group-hover:text-red-600"></i>
          </button>
        )
      }
      show={open}
      size="max-w-lg"
      title="Edit"
      subTitle="Cap History Entry"
      onClosed={onClosing}
    >
      <form id="cap-form" onSubmit={submit}>
        <TextInput
          error={errors.cap}
          name="cap"
          label="Remaining Annual CAP Amount"
          icon={<i className="fal fa-dollar-sign"></i>}
          value={data.cap}
          onBlur={() => {
            setData({
              ...data,
              cap: Helpers.formatDecimal(data.cap, 2),
              cap_residual: Helpers.formatDecimal(data.cap >= 3000 ? data.cap - 3000 : 0, 2),
            })
          }}
          onChange={(value) => {
            value = Helpers.sanitizeCurrencyInput(value)
            setData({ ...data, cap: value, cap_residual: value >= 3000 ? value - 3000 : 0 })
          }}
        />

        {type === 'cap_history' && (
          <TextInput
            label="Remaining Annual CAP Residual"
            icon={<i className="fal fa-dollar-sign"></i>}
            value={data.cap_residual}
            disabled={true}
          />
        )}

        <div className="mb-4">
          <label htmlFor="created_at" className="mb-0.5 block text-sm font-medium uppercase text-gray-500">
            Date
          </label>

          <div className="flex items-start gap-4">
            <DatePicker
              date={data.created_at && new Date(data.created_at)}
              error={errors.created_at}
              onChange={(date) => setData({ ...data, created_at: date ? updateDate(date, data.created_at) : null })}
            />

            <div className="flex-1">
              <TimePicker
                id="created_at"
                value={data.created_at}
                onChange={(date) => setData({ ...data, created_at: updateDate(data.created_at, date) })}
                disabled={!data.created_at}
                isClearable={true}
                interval={1}
              />
            </div>
          </div>
        </div>

        <div className="pb-4">
          <TextArea
            name="comments"
            label="Comments"
            className="block w-full rounded border border-gray-500 px-2 py-1 leading-normal outline-none focus:border-transparent focus:ring-2 focus:ring-blue-500"
            rows={4}
            error={errors.comments}
            value={data.comments}
            onChange={(value) => setData({ ...data, comments: value })}
            required={!data.id}
          />
        </div>

        {data.id && (
          <div className="mt-6 space-y-3 border-t border-dashed border-gray-300 pt-6">
            <div className="flex items-center justify-center gap-4">
              <label className="block w-40 text-right text-sm font-semibold uppercase text-gray-700">Last Updated</label>
              <div className="flex-1">{data.updated_at}</div>
            </div>
          </div>
        )}
      </form>

      <DeleteConfirmationDialog open={deleting} onCanceled={() => setDeleting(false)} onApproved={() => onDelete()}>
        <Fragment>
          <div className="space-y-6">
            <div className="space-y-1 text-center text-red-600">
              <div className="flex items-center justify-center gap-3">
                <i className="fas fa-exclamation-triangle text-4xl"></i>
                <p className="text-2xl font-semibold">WARNING</p>
              </div>
              <p className="text-lg font-semibold">Deleting this entry may affect historical data for completed transactions.</p>
              <p className="text-lg font-semibold">Are you sure you wish to delete this entry?</p>
            </div>

            <div className="rounded-md border-2 border-red-500 bg-red-50 p-4 text-center">
              <div className="font-semibold text-gray-900">
                CAP: ${Helpers.formatDecimal(data.cap, 2)}
                {type === 'cap_history' && <Fragment>(${Helpers.formatDecimal(data.cap_residual, 2)})</Fragment>}
              </div>
              <div className="text-gray-600">
                {new Date(data.created_at).toLocaleDateString('en-us', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              </div>
            </div>
          </div>
        </Fragment>
      </DeleteConfirmationDialog>
    </SlideOver>
  )
}
