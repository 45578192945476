import { useContext } from 'react'
import Accordion from '@/Shared/Accordion'
import classNames from 'classnames'
import NearbyTowns from './Forms/NearbyTowns'
import PrimaryMarketLocation from './Forms/PrimaryMarketLocation'
import ServicesProvided from './Forms/ServicesProvided'
import { SettingContext } from './SettingContext'
import TownsServiced from './Forms/TownsServiced'
import { usePage } from '@inertiajs/react'

export default () => {
  const administrating = route().current()?.startsWith('admin')
  const user = administrating ? usePage().props.user : usePage().props.auth.user
  const { setting, setSetting } = useContext(SettingContext)

  return (
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <div className="px-4 py-6 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-xl font-medium leading-6 text-gray-900">Services & Towns</h2>
        </div>

        <div className="mt-6">
          <Accordion
            id="services-towns"
            onIndexChanged={(index) =>
              setSetting(
                {
                  0: 'services_provided',
                  1: 'service_area',
                  2: 'primary_market_location',
                  3: 'nearby_towns',
                }[index],
              )
            }
          >
            <Accordion.Items>
              <Accordion.Item active={setting === 'services_provided'}>
                <Accordion.Button>
                  <div className="text-lg font-medium">Services Provided</div>
                  <div className="flex flex-wrap gap-1 text-gray-500">
                    {user.services_provided.map((serviceProvided) => (
                      <span
                        className={classNames(
                          'inline-block whitespace-nowrap rounded px-2.5 py-1.5 text-sm leading-none',
                          serviceProvided.primary && serviceProvided.approved ? 'bg-primary-500 text-white' : 'bg-gray-200 text-gray-800',
                          { 'bg-yellow-200 text-yellow-700': !serviceProvided.approved },
                        )}
                        key={serviceProvided.id}
                      >
                        <span className="font-medium">{serviceProvided.service.title}</span>
                      </span>
                    ))}
                  </div>
                </Accordion.Button>

                <Accordion.Panel>
                  <ServicesProvided user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item active={setting === 'service_area'}>
                <Accordion.Button>
                  <div className="shrink-0 text-lg font-medium">Towns Serviced</div>
                  <div className="flex flex-wrap gap-1 text-gray-500">
                    {user.service_area_list.map(
                      (area, index) =>
                        index < 10 && (
                          <span
                            className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                            key={area}
                          >
                            {area}
                          </span>
                        ),
                    )}{' '}
                    {user.service_area_list.length > 10 && (
                      <span className="ml-1 font-medium">& {user.service_area_list.length - 10} more</span>
                    )}
                  </div>
                </Accordion.Button>

                <Accordion.Panel>
                  <TownsServiced user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item active={setting === 'primary_market_location'}>
                <Accordion.Button>
                  <div className="text-lg font-medium">Primary Market Location</div>
                  <div className="text-gray-500">{user.primary_market_location}</div>
                </Accordion.Button>

                <Accordion.Panel>
                  <PrimaryMarketLocation user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Button>
                  <div className="text-lg font-medium">Nearby Towns</div>
                  {user.nearby ? (
                    <div className="text-gray-500">Appearing in searches near your service area.</div>
                  ) : (
                    <div className="text-red-600">Only appearing in searches within your service area.</div>
                  )}
                </Accordion.Button>

                <Accordion.Panel>
                  <NearbyTowns user={user} />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion.Items>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
