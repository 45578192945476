import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import Select from '@/Shared/Forms/Select'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'
import { nanoid } from 'nanoid'
import classNames from 'classnames'

function Reimbursements({ associates, clearErrors, data, errors, setError, onChanged, onFormVisibilityChange }, ref) {
  const { transaction, media_url } = usePage().props
  const formRef = useRef(null)
  const [editing, setEditing] = useState(false)
  const types = [
    ...(['Buyer', 'Seller'].some((type) => type.includes(transaction.type))
      ? [
          { value: 'smoke_co_detector_inspection_fee', label: 'Smoke Detector and CO Detector Inspection Fee' },
          { value: 'water_inspection_fee', label: 'Final Water Inspection Fee and/or Bill' },
        ]
      : []),
    ...[
      { value: 'home_inspection_fee', label: 'Home Inspection Fee' },
      { value: 'other', label: 'Other' },
    ],
  ]
  const receivedPayments = transaction.payments.filter(
    (payment) => !payment.credit && !payment.archived && payment.type === 'Reimbursements' && (payment.paid || payment.checks.length > 0),
  )

  useImperativeHandle(ref, () => ({
    scrollToSaveButton: () => {
      if (formRef.current) {
        formRef.current.scrollToSaveButton()
      }
    },
  }))

  useEffect(() => {
    onFormVisibilityChange(editing ? true : false)
  }, [editing, onFormVisibilityChange])

  const isFormValid = (reimbursement) => {
    const amount = Helpers.parseCurrency(reimbursement.amount || 0)
    const totalDistributions =
      reimbursement.distributions?.reduce((sum, distribution) => sum + Helpers.parseCurrency(distribution.amount), 0).toFixed(2) || 0

    clearErrors(
      'reimbursement_type',
      'reimbursement_amount',
      'reimbursement_description',
      'reimbursement_receipt',
      'reimbursement_distributions',
      'reimbursement_undistributed',
    )

    if (
      !reimbursement.type ||
      amount == 0 ||
      !reimbursement.description ||
      (reimbursement.type !== 'smoke_co_detector_inspection_fee' && !reimbursement.receipt) ||
      totalDistributions == 0
    ) {
      if (!reimbursement.type) {
        setError('reimbursement_type', 'This field is required.')
      }
      if (amount == 0) {
        setError('reimbursement_amount', 'This field is required.')
      }
      if (!reimbursement.description) {
        setError('reimbursement_description', 'This field is required.')
      }
      if (reimbursement.type !== 'smoke_co_detector_inspection_fee' && !reimbursement.receipt) {
        setError('reimbursement_receipt', 'You must provide a receipt.')
      }
      if (totalDistributions == 0) {
        setError('reimbursement_distributions', 'You must distribute the reimbursement amount.')
      }

      return false
    }

    if (totalDistributions > amount) {
      setError(
        'reimbursement_undistributed',
        `The total amount distributed exceeds the reimbursement amount by ${Helpers.formatCurrency(totalDistributions - amount, 2)}.`,
      )
      return false
    } else if (totalDistributions < amount) {
      setError(
        'reimbursement_undistributed',
        `The total amount distributed is less than the reimbursement amount by ${Helpers.formatCurrency(amount - totalDistributions, 2)}.`,
      )
      return false
    } else {
      return true
    }
  }

  const onCancel = () => {
    clearErrors('reimbursement_undistributed')
    setEditing(null)
  }

  const onDelete = (reimbursement) => {
    onChanged({ ...data, reimbursements: data.reimbursements.filter((r) => r.id != reimbursement.id) })
    setEditing(false)
  }

  const onSave = (reimbursement) => {
    if (isFormValid(reimbursement)) {
      if (data.reimbursements.find((r) => r.id == reimbursement.id)) {
        onChanged({ ...data, reimbursements: data.reimbursements.map((r) => (r.id == reimbursement.id ? reimbursement : r)) })
      } else {
        data.reimbursements.push({ ...reimbursement })
      }
      setEditing(false)
    }
  }

  return (
    <div className="space-y-3">
      <div className="flex items-center gap-2">
        <i className="far fa-info-circle text-lg"></i>
        <p>Remember to keep copies of receipts for your personal taxes.</p>
      </div>

      {transaction.reimbursements.some((r) => receivedPayments.find((p) => r.payment_id === p.id)) && (
        <div className="flex gap-2">
          <i className="far fa-dollar-circle text-lg text-green-700"></i>
          <p>Reimbursement(s) can no longer be edited due to funds having been received.</p>
        </div>
      )}

      {data.reimbursements?.length > 0 && (
        <ReimbursementsTable
          receivedPayments={receivedPayments}
          reimbursements={data.reimbursements}
          media_url={media_url}
          onEdit={(reimbursement) => setEditing(reimbursement)}
        />
      )}

      <div className="pt-3">
        {editing ? (
          <ReimbursementsForm
            ref={formRef}
            associates={associates}
            data={editing}
            errors={errors}
            media_url={media_url}
            types={types.filter(
              (type) => type.value === 'other' || type.value === editing.type || !data.reimbursements.some((r) => type.value === r.type),
            )}
            onCancel={() => onCancel()}
            onDelete={(reimbursement) => onDelete(reimbursement)}
            onSave={(reimbursement) => onSave(reimbursement)}
            creating={!editing.type}
          />
        ) : (
          <div className="flex justify-center">
            <Button theme="border" onClick={() => setEditing({ id: nanoid() })}>
              <i className="far fa-plus pr-1 text-sm"></i>
              <span>Add Reimbursement</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const ReimbursementsTable = ({ receivedPayments, reimbursements, media_url, onEdit }) => {
  return (
    <div className="inline-block min-w-full overflow-hidden rounded-lg bg-white align-middle ring-1 ring-gray-300/75">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-2.5 pl-5 pr-3 text-left font-semibold text-gray-900">
              Type
            </th>

            <th scope="col" className="px-3 py-2.5 text-left font-semibold text-gray-900" width="90px">
              Amount
            </th>

            <th scope="col" className="relative py-2.5 pl-3 pr-4 sm:w-[36px] sm:pr-3">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200 bg-white">
          {reimbursements?.map((reimbursement, index) => (
            <tr className="even:bg-gray-50" key={index}>
              <td className="py-1 pl-4 pr-3 text-[0.925rem] text-gray-900 sm:pl-3">{reimbursement.description}</td>

              <td className="whitespace-nowrap px-3 py-2 text-gray-800">
                <span className="font-semibold">{Helpers.formatCurrency(Helpers.parseCurrency(reimbursement.amount), 2)}</span>
              </td>

              <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right align-middle font-medium sm:pr-3">
                <div className="flex justify-end gap-1">
                  {reimbursement.receipt?.url && (
                    <a
                      href={`https://${media_url}/storage/${reimbursement.receipt.url}`}
                      className="group relative inline-flex h-8 w-8 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600"
                      target="_blank"
                    >
                      <span className="sr-only">View Receipt</span>
                      <i className="far fa-arrow-up-right-from-square text-lg text-gray-800 hover:text-blue-500"></i>
                    </a>
                  )}

                  {receivedPayments.some((p) => p.id === reimbursement.payment_id) ? (
                    <div className="group relative inline-flex h-8 w-8 items-center justify-center rounded leading-none">
                      <i className="far fa-dollar-circle text-xl text-green-700"></i>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="group relative inline-flex h-8 w-8 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
                      onClick={() => onEdit(reimbursement)}
                    >
                      <span className="sr-only">Edit</span>
                      <i className="far fa-pencil text-lg text-gray-800 group-hover:text-red-600"></i>
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const ReimbursementsForm = forwardRef(({ associates, creating, data, errors, media_url, types, onCancel, onDelete, onSave }, ref) => {
  const saveButtonRef = useRef(null)
  const [reimbursement, setReimbursement] = useState(null)
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] = useState(false)

  useImperativeHandle(ref, () => ({
    scrollToSaveButton: () => {
      if (!saveButtonRef.current) return

      setShowUnsavedChangesWarning(true)

      saveButtonRef.current.focus()

      setTimeout(() => {
        saveButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 50)
    },
  }))

  useEffect(() => {
    setReimbursement(data)
  }, [data])

  if (!reimbursement) {
    return <></>
  }

  const handleCancel = () => {
    reset()
    onCancel()
  }

  const handleDelete = (reimbursement) => {
    reset()
    onDelete(reimbursement)
  }

  const handleDistributionChange = (transactionContact, value) => {
    const associate = transactionContact.contact?.associate

    let updatedDistributions = [...(reimbursement.distributions || [])]

    const sanitizedAmount = Helpers.sanitizeCurrencyInput(value)

    // Check if the associate already exists in the distributions array
    const existingIndex = updatedDistributions.findIndex((dist) => dist.associate_id === associate.id)

    if (existingIndex !== -1) {
      updatedDistributions[existingIndex] = {
        ...updatedDistributions[existingIndex],
        amount: sanitizedAmount,
      }
    } else {
      updatedDistributions.push({
        associate_id: associate.id,
        amount: sanitizedAmount,
      })
    }

    setReimbursement({
      ...structuredClone(reimbursement),
      distributions: updatedDistributions,
    })
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Prevent outer form submission
      e.preventDefault()

      onSave(reimbursement)
    }
  }

  const handleSave = () => {
    reset()
    onSave(reimbursement)
  }

  const reset = () => {
    setShowUnsavedChangesWarning(false)
  }

  return (
    <div
      className={classNames('rounded-md bg-white p-6', {
        'border-2 border-red-500': showUnsavedChangesWarning,
        'border border-gray-300': !showUnsavedChangesWarning,
      })}
    >
      <div className="grow space-y-3">
        <div className="text-lg font-medium tracking-wider text-gray-800">Reimbursement Details</div>

        <Select
          label="Fee Type"
          error={errors.reimbursement_type}
          options={types}
          placeholder="Select a reimbursement type"
          value={reimbursement.type || null}
          onChange={(selected) =>
            setReimbursement({ ...reimbursement, type: selected?.value, description: selected?.value === 'other' ? '' : selected?.label })
          }
          isClearable={false}
          required
          creatable
        />

        {reimbursement.type === 'other' && (
          <TextInput
            label="Description (will appear on invoice)"
            name={'reimbursement_descriptio'}
            value={reimbursement.description}
            error={errors.reimbursement_description}
            onChange={(value) => setReimbursement({ ...reimbursement, description: value })}
            required
            clearable
          />
        )}

        <TextInput
          label="Amount"
          name={(`reimbursement_amount`, 'This field is required.')}
          icon={<i className="fas fa-dollar-sign"></i>}
          value={reimbursement.amount}
          error={errors.reimbursement_amount}
          onBlur={() =>
            setReimbursement({
              ...reimbursement,
              amount: Helpers.formatDecimal(Helpers.parseCurrency(reimbursement.amount), 2),
            })
          }
          onChange={(value) =>
            setReimbursement({
              ...reimbursement,
              amount: Helpers.sanitizeCurrencyInput(value),
            })
          }
          onKeyDown={handleKeyPress}
          onFocus={(e) => e.target.select()}
          clearable
          required
        />

        <div>
          <label className="mb-1 text-sm font-medium uppercase text-gray-600">
            Receipt{' '}
            {reimbursement.type === 'smoke_co_detector_inspection_fee' ? (
              <span className="text-sm uppercase">(Optional)</span>
            ) : (
              <span className="text-red-500">*</span>
            )}
          </label>

          {reimbursement.receipt ? (
            <div className="mt-1">
              <div className="flex items-center justify-between rounded-md bg-gray-200/75 py-2 pl-3 pr-4">
                <div className="flex items-center gap-1">
                  {!reimbursement.receipt.name && reimbursement.receipt?.url && (
                    <a
                      href={`https://${media_url}/storage/${reimbursement.receipt.url}`}
                      className="group relative inline-flex h-8 w-8 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600"
                      target="_blank"
                    >
                      <span className="sr-only">View Receipt</span>
                      <i className="far fa-arrow-up-right-from-square text-lg text-gray-800 hover:text-blue-500"></i>
                    </a>
                  )}

                  <span className="font-semibold">Receipt</span>
                </div>
                <div>
                  <button
                    type="button"
                    className="flex h-5 items-center justify-center rounded px-1 outline-none hover:bg-green-500 hover:text-white focus:bg-green-100 focus:text-blue-500 focus:ring-2 focus:ring-inset focus:ring-blue-500"
                    onClick={() => setReimbursement({ ...reimbursement, receipt: null })}
                  >
                    <i className="far fa-times text-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="relative">
                <label
                  htmlFor={`reimbursement_receipt`}
                  className="font-md transition-border block h-11 w-full rounded border border-gray-300 bg-gray-100 px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out focus-within:border-transparent focus-within:ring-2 focus-within:ring-primary-500 hover:border-gray-400"
                >
                  <input
                    type="file"
                    id={`reimbursement_receipt`}
                    className="sr-only"
                    aaccept="application/pdf, image/*"
                    onChange={(event) =>
                      setReimbursement({
                        ...reimbursement,
                        receipt: Array.from(event.target.files)[0],
                      })
                    }
                  />
                  Choose file
                  <div className="absolute inset-y-0 right-0 my-px mr-0.5 flex h-[calc(100%-2px)] items-center rounded-r border border-transparent bg-gray-200 px-4 text-gray-700">
                    Browse
                  </div>
                </label>
              </div>

              {errors.reimbursement_receipt && (
                <div className="mt-1 text-red-600" dangerouslySetInnerHTML={{ __html: errors.reimbursement_receipt }}></div>
              )}
            </div>
          )}
        </div>

        {associates.length > 0 && (
          <div className="pt-2">
            <Heading>
              <div className="flex items-center gap-3">
                <span>Distribution of Funds</span>

                <Tooltip label="The amounts to be reimbursed to each associate. Must add up to total reimbursements.">
                  <i className="far fa-question-circle cursor-help text-xl text-orange-500"></i>
                </Tooltip>
              </div>
            </Heading>

            {associates.map((transactionContact) => (
              <div key={transactionContact.id}>
                <TextInput
                  label={<span className="font-bold text-black">{transactionContact.full_name}</span>}
                  name="distribution_amount"
                  icon={<i className="fas fa-dollar-sign"></i>}
                  value={
                    reimbursement.distributions?.find((d) => d.associate_id === transactionContact.contact?.associate?.id)?.amount ?? ''
                  }
                  error={errors.reimbursement_distributions}
                  onFocus={(e) => e.target.select()}
                  onBlur={() => {
                    const amount = reimbursement.distributions?.find(
                      (d) => d.associate_id === transactionContact.contact?.associate?.id,
                    )?.amount

                    if (amount) {
                      handleDistributionChange(transactionContact, Helpers.formatDecimal(Helpers.parseCurrency(amount), 2))
                    }
                  }}
                  onChange={(value) => handleDistributionChange(transactionContact, value)}
                  onKeyDown={handleKeyPress}
                  clearable
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {errors.reimbursement_undistributed && (
        <div className="mb-4">
          <Alert heading={errors.reimbursement_undistributed} type="danger" />
        </div>
      )}

      {showUnsavedChangesWarning && (
        <Alert
          className="mb-4"
          heading="You have unsaved changes"
          subtext={
            <span>
              You must first <span className="font-bold">{reimbursement.id ? 'Update' : 'Add'}</span> or{' '}
              <span className="font-bold">Cancel</span> the changes to this reimbursement to continue.
            </span>
          }
          type="danger"
        />
      )}

      <div className="flex items-center justify-between">
        <div>
          {!creating && (
            <button
              type="button"
              className="group relative inline-flex h-10 w-10 items-center justify-center rounded leading-none transition-all duration-150 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600"
              onClick={() => handleDelete(reimbursement)}
            >
              <span className="sr-only">Delete</span>
              <i className="far fa-trash text-xl text-gray-800 group-hover:text-red-600"></i>
            </button>
          )}
        </div>

        <div className="relative space-x-2 whitespace-nowrap text-right">
          <Button type="button" theme="clean" onClick={() => handleCancel()}>
            Cancel
          </Button>

          <Button ref={saveButtonRef} type="button" theme="solid" onClick={() => handleSave(reimbursement)}>
            {reimbursement.id ? 'Update' : 'Add'}
          </Button>
        </div>
      </div>
    </div>
  )
})

export default forwardRef(Reimbursements)
