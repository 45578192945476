import { Fragment, useEffect, useRef, useState } from 'react'
import { useForm, usePage } from '@inertiajs/react'
import Button from '@/Shared/Button'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'

export default ({ fields, operators, rule, step, open, onClosed, onSaving }) => {
  let focusRef = useRef()
  const { checklist } = usePage().props
  const { data, setData, clearErrors, errors, setError, reset } = useForm(rule)
  const actions = [
    { value: 'step_complete', label: 'Mark this step completed' },
    { value: 'step_incomplete', label: 'Mark this step incomplete' },
    { value: 'step_not_applicable', label: 'Mark this step not applicable' },
  ]
  const events = [
    { value: 'step_complete', label: 'Step marked complete' },
    { value: 'step_incomplete', label: 'Step marked incomplete' },
    { value: 'step_not_applicable', label: 'Step marked not applicable' },
    { value: 'data_changed', label: 'Transaction data updated' },
  ]
  const [steps, setSteps] = useState([])

  useEffect(() => getSteps(), [])

  useEffect(() => {
    if (rule) {
      setData(rule)
    }
  }, [rule])

  const getSteps = () => {
    let allSteps = []

    checklist.sections.forEach((section) =>
      section.steps
        .filter((s) => s.id != step.id || s.subtasks?.length > 0)
        .forEach((s) => {
          if (s.id != step.parent_id) {
            allSteps.push(
              Object.assign(
                {},
                {
                  label: `${s.index}. ${s.nameWithoutTags}`,
                  value: s.id.toString(),
                },
              ),
            )
          }

          if (s.subtasks?.length > 0) {
            s.subtasks.forEach((subtask, index) => {
              if (subtask.id != step.id) {
                allSteps.push(
                  Object.assign(
                    {},
                    {
                      label: `${s.index}${String.fromCharCode(index + 'A'.charCodeAt(0))}. ${subtask.nameWithoutTags}`,
                      value: subtask.id.toString(),
                    },
                  ),
                )
              }
            })
          }
        }),
    )
    setSteps(allSteps)
  }

  const isValid = () => {
    clearErrors('event', 'condition', 'condition_operator', 'condition_value', 'condition_value_end', 'action')

    // prettier-ignore
    let errorList = []
    let fields = ['event', 'condition', 'action']

    fields.map((field) => {
      if (!data[field]) {
        errorList.push({ field: field, message: 'This field is required.' })
      }

      if (data.event === 'data_changed' && data.condition) {
        if (!data.condition.operator) {
          errorList.push({ field: 'condition_operator', message: 'This field is required.' })
        }

        if (['between'].indexOf(data.condition.operator) >= 0 && !data.condition.value_end) {
          errorList.push({ field: 'condition_value_end', message: 'This field is required.' })
        }

        if (['is_true', 'is_false', 'known', 'unknown'].indexOf(data.condition.operator) < 0 && !data.condition.value) {
          errorList.push({ field: 'condition_value', message: 'This field is required.' })
        }
      }
    })

    if (errorList.length > 0) {
      errorList.map((error) => setError(error.field, error.message))

      return false
    }

    return true
  }

  const onEventChanged = (event) => {
    setData({ event: event })
  }

  const onConditionFieldChanged = (condition) => {
    let sameType = data.condition?.type === condition.type
    setData({
      ...data,
      condition: {
        ...data.condition,
        field: condition.value,
        label: condition.label,
        type: condition.type || null,
        value: sameType ? data.condition?.value : null,
        value_end: sameType ? data.condition?.value_end : null,
      },
    })
  }

  const submit = (event) => {
    event.stopPropagation()
    event.preventDefault()

    if (isValid()) {
      onSaving(data)
      onClosed()

      setTimeout(() => {
        reset()
      }, 300)
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="rule-form">
          {data.id ? 'Save Changes' : 'Save'}
        </Button>
      }
      show={open}
      size="max-w-lg"
      title="Rule"
      subTitle="Settings"
      onClosed={onClosed}
      zIndex="z-40"
    >
      <form id="rule-form" className="pb-4" onSubmit={submit}>
        <Select
          ref={focusRef}
          error={errors.event}
          label="Event that occurred"
          name="event"
          options={events}
          placeholder="Choose one"
          value={data.event}
          onChange={(selected) => onEventChanged(selected?.value)}
          isClearable={false}
          required
        />

        {data.event === 'data_changed' && (
          <Fragment>
            <Select
              error={errors.condition_field}
              label="Data to check"
              name="condition_field"
              options={fields}
              placeholder="Choose one"
              value={data.condition?.field}
              onChange={(selected) => onConditionFieldChanged(selected)}
              isClearable={false}
              required
            />

            {data.condition && (
              <Select
                error={errors.condition_operator}
                label="Condition"
                name="condition_operator"
                options={operators.filter(
                  (operator) => !operator.allowed_types || operator.allowed_types.some((type) => type === data.condition?.type),
                )}
                placeholder="Choose one"
                value={data.condition?.operator}
                onChange={(selected) => setData({ ...data, condition: { ...data.condition, operator: selected?.value } })}
                isClearable={false}
                required
              />
            )}

            {data.condition &&
              data.condition.operator &&
              ['is_true', 'is_false', 'known', 'unknown'].indexOf(data.condition.operator) < 0 && (
                <Fragment>
                  {data.condition.type === 'Date' ? (
                    <Fragment>
                      <DatePicker
                        error={errors.condition_value}
                        label={data.condition.operator === 'between' ? 'From' : 'Value'}
                        name="condition_value"
                        date={data.condition.value && new Date(data.condition.value)}
                        onChange={(date) => setData({ ...data, condition: { ...data.condition, value: date } })}
                        required
                      />

                      {data.condition.operator === 'between' && (
                        <DatePicker
                          error={errors.condition_value_end}
                          label="To"
                          name="condition_value_end"
                          date={data.condition.value_end && new Date(data.condition.value_end)}
                          onChange={(date) => setData({ ...data, condition: { ...data.condition, value_end: date } })}
                          required
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <TextInput
                        error={errors.condition_value}
                        label={data.condition.operator === 'between' ? 'From' : 'Value'}
                        name="condition_value"
                        icon={data.condition.type === 'Currency' && <i className="fas fa-dollar-sign"></i>}
                        value={data.condition.value}
                        onChange={(value) => setData({ ...data, condition: { ...data.condition, value: value } })}
                      />

                      {data.condition.operator === 'between' && (
                        <TextInput
                          error={errors.condition_value_end}
                          label="To"
                          name="condition_value_end"
                          icon={data.condition.type === 'Currency' && <i className="fas fa-dollar-sign"></i>}
                          value={data.condition.value_end}
                          onChange={(value) => setData({ ...data, condition: { ...data.condition, value_end: value } })}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
          </Fragment>
        )}

        {['step_complete', 'step_incomplete', 'step_not_applicable'].indexOf(data.event) >= 0 && (
          <Select
            error={errors.condition}
            label="Step"
            name="condition"
            options={steps}
            placeholder="Choose one"
            value={data.condition?.value}
            onChange={(selected) => setData({ ...data, condition: { value: selected?.value } })}
            isClearable={false}
            required
          />
        )}

        {data.condition && (
          <Select
            error={errors.action}
            label="Automated Action to Perform"
            name="action"
            options={actions}
            placeholder="Choose one"
            value={data.action}
            onChange={(selected) => setData({ ...data, action: selected && selected.value })}
            isClearable={false}
            required
          />
        )}
      </form>
    </SlideOver>
  )
}
