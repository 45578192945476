import { useContext, useEffect, useRef, useState } from 'react'
import { AccordionContext } from '@/Shared/Accordion'
import Button from '@/Shared/Button'
import TextArea from '@/Shared/Forms/TextArea'
import { router } from '@inertiajs/react'

export default ({ user }) => {
  const focusRef = useRef()
  const { onSaved } = useContext(AccordionContext)
  const [data, setData] = useState({
    setting: 'synopsis',
    synopsis: user.synopsis,
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    focusRef.current.focus()
  }, [])

  const submit = (event) => {
    event.preventDefault()

    axios
      .post(route('api.users.update', user.id), { ...data, _method: 'PATCH' })
      .then((_) =>
        router.reload({
          onFinish: () => {
            onSaved()
          },
        }),
      )
      .catch((error) => {
        if (error.response != undefined) {
          const { status } = error.response
          const VALIDATION_ERROR = 422
          if (status == VALIDATION_ERROR) {
            setErrors(error.response.data.errors)
          }
        }
      })
  }

  return (
    <div>
      <p className="mb-3">
        When consumers search the site for services, they see your headshot and this short one- or two-sentence brief overview of your
        services.
      </p>

      <p className="mb-3">Example: An appropriate example for a CPA may be:</p>
      <blockquote className="px-3 py-2 text-center font-semibold text-primary-500">
        Certified Public Accountants for individuals and owners of small businesses.
      </blockquote>

      <form className="mt-4" onSubmit={submit}>
        <TextArea
          ref={focusRef}
          name="synopsis"
          label="Search Results Description"
          error={errors.synopsis}
          value={data.synopsis}
          rows={window.innerWidth < 768 ? 4 : 2}
          onChange={(value) => setData({ ...data, synopsis: value })}
          required
        />

        <div className="mb-2 flex justify-center">
          <span className={(data.synopsis?.length || 0) <= 140 ? 'text-gray-500' : 'font-medium text-red-700'}>
            ({140 - (data.synopsis?.length || 0)}/140) characters remaining
          </span>
        </div>

        <div className="flex justify-end">
          <Button type="submit" theme="solid">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}
