import Accordion from '@/Shared/Accordion'
import AddressForm from './Forms/Address'
import AvatarForm from './Forms/Avatar'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import CompanyForm from './Forms/Company'
import EmailForm from './Forms/Email'
import Helpers from '@/utils/helpers'
import LicenseForm from './Forms/License'
import NameForm from './Forms/Name'
import PasswordForm from './Forms/Password'
import PhoneNumbersForm from './Forms/PhoneNumbers'
import SocialMediaForm from './Forms/SocialMedia'
import TitleForm from './Forms/Title'
import WebsiteForm from './Forms/Website'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'

const Connection = ({ children, disabled, url }) => {
  let style = classNames(
    'outline-none flex h-12 items-center justify-center gap-2 rounded border-2 border-gray-300 px-3',
    disabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-white hover:bg-gray-100 focus:ring-2 focus:ring-primary-500 focus:ring-offset-1',
  )

  return disabled ? (
    <div className={style}>{children({ disabled })}</div>
  ) : (
    <a className={style} href={url} role="button">
      {children({ disabled })}
    </a>
  )
}

export default () => {
  const administrating = route().current()?.startsWith('admin')
  const user = administrating ? usePage().props.user : usePage().props.auth.user

  return (
    <div className="divide-y divide-gray-200">
      <div className="px-4 py-6 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-xl font-medium leading-6 text-gray-900">Account</h2>
        </div>

        <div className="mt-6 space-y-6">
          <Accordion id="account">
            <Accordion.Items>
              {((administrating && Helpers.isAdminOrHR(user)) || user.type === 'Advisor') && (
                <Accordion.Item>
                  <Accordion.Button>
                    <div className="text-lg font-medium">Login / Email</div>
                    <div className="text-gray-500">{user.email}</div>
                  </Accordion.Button>

                  <Accordion.Panel>
                    <EmailForm user={user} />
                  </Accordion.Panel>
                </Accordion.Item>
              )}

              {!administrating && (
                <Accordion.Item>
                  <Accordion.Button>
                    <div className="text-lg font-medium">Change Your Password</div>
                    <div className="text-gray-500">Change your password at any time.</div>
                  </Accordion.Button>

                  <Accordion.Panel>
                    <PasswordForm user={user} />
                  </Accordion.Panel>
                </Accordion.Item>
              )}

              {!administrating && (
                <Accordion.Item>
                  <Accordion.Button>
                    <div className="text-lg font-medium">Social Logins</div>
                    <div className="text-gray-500">Sign-in to your lsnpros.com account with Google or LinkedIn.</div>
                  </Accordion.Button>

                  <Accordion.Panel>
                    <p>
                      Select the service you wish to connect. You will be redirected to that service temporarily to connect your account.
                      Once connected, you will be returned to this page.
                    </p>

                    <div className="mx-auto mt-4 max-w-xs space-y-3">
                      <Connection url="/oauth/google" disabled={user.connections.find((c) => c === 'google')}>
                        {({ disabled }) => (
                          <>
                            <img width="18px" height="18px" src="/images/layout/google_logo.svg" />
                            <span className="font-medium leading-none text-gray-700">{disabled ? 'Connected' : 'Connect'} with Google</span>
                          </>
                        )}
                      </Connection>

                      <Connection url="/oauth/linkedin" disabled={user.connections.find((c) => c === 'linkedin')}>
                        {({ disabled }) => (
                          <>
                            <i className="fab fa-linkedin-in text-lg leading-none"></i>
                            <span className="font-medium leading-none text-gray-700">
                              {disabled ? 'Connected' : 'Connect'} with LinkedIn
                            </span>
                          </>
                        )}
                      </Connection>
                    </div>
                  </Accordion.Panel>
                </Accordion.Item>
              )}

              <Accordion.Item>
                <Accordion.Button>
                  <div className="text-lg font-medium">Name</div>
                  <div className="text-gray-500">{user.full_name}</div>
                </Accordion.Button>

                <Accordion.Panel>
                  <NameForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Button>
                  <div className="text-lg font-medium">Headshot / Photo</div>
                </Accordion.Button>

                <Accordion.Panel>
                  <AvatarForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              {user.type !== 'Associate' && (
                <Accordion.Item>
                  <Accordion.Button>
                    <div className="text-lg font-medium">Title</div>
                    <div className="text-gray-500">{user.title}</div>
                  </Accordion.Button>

                  <Accordion.Panel>
                    <TitleForm user={user} />
                  </Accordion.Panel>
                </Accordion.Item>
              )}

              <Accordion.Item noPanel={user.type === 'Associate'}>
                <Accordion.Button>
                  <div className="text-lg font-medium">Company</div>
                  <div className="text-gray-500">{user.company}</div>
                </Accordion.Button>

                <Accordion.Panel>
                  <CompanyForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item noPanel={user.type === 'Associate'}>
                <Accordion.Button>
                  <div className="text-lg font-medium">Website</div>
                  <div className="text-gray-500">{user.primary_website}</div>
                </Accordion.Button>

                <Accordion.Panel>
                  <WebsiteForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item noPanel={user.type === 'Associate'}>
                <Accordion.Button>
                  <div className="text-lg font-medium">
                    {user.type === 'Associate' ? 'Brokerage Business & Accounting Address' : 'Work Address'}
                  </div>
                  <div className="text-gray-500">
                    {user.primary_address && user.primary_address.street
                      ? (user.show_street || user.type === 'Associate'
                          ? user.primary_address.street + (user.primary_address.street2 ? `, ${user.primary_address.street2}` : '')
                          : '') +
                        (user.primary_address.city
                          ? user.show_street || user.type === 'Associate'
                            ? `, ${user.primary_address.city}`
                            : user.primary_address.city
                          : '') +
                        (user.primary_address.state ? `, ${user.primary_address.state}` : '') +
                        (user.primary_address.postal_code ? ` ${user.primary_address.postal_code}` : '')
                      : 'Not specified'}
                  </div>
                </Accordion.Button>

                <Accordion.Panel>
                  <AddressForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item noPanel={user.type === 'Associate'}>
                <Accordion.Button>
                  <div className="flex items-center justify-between gap-6">
                    <div>
                      <div className="text-lg font-medium">Phone Numbers</div>
                      <div className="text-gray-500">
                        {user.phone_numbers.reduce((carry, phone) => {
                          let separator = carry ? ', ' : ''
                          let type = phone.type ? ` (${phone.type})` : ''
                          return carry + separator + phone.value + type
                        }, '')}
                      </div>
                    </div>

                    {user.type === 'Associate' && (
                      <Button
                        theme="outline"
                        onClick={() =>
                          administrating ? router.visit(route('admin.associates.show', user.associate_id)) : router.visit(route('my-card'))
                        }
                      >
                        <span>Edit{!administrating && '  in My Card'}</span>
                      </Button>
                    )}
                  </div>
                </Accordion.Button>

                <Accordion.Panel>
                  <PhoneNumbersForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item>
                <Accordion.Button>
                  <div className="text-lg font-medium">Social Media</div>
                  <div className="space-x-3 text-gray-500">
                    {user.social_accounts?.map((social, index) => (
                      <i
                        className={classNames('fab text-xl', {
                          'fa-facebook': social.label === 'Facebook',
                          'fa-flickr': social.label === 'Flickr',
                          'fa-instagram': social.label === 'Instagram',
                          'fa-linkedin-in': social.label === 'LinkedIn',
                          'fa-pinterest-p': social.label === 'Pinterest',
                          'fa-snapchat-ghost': social.label === 'Snapchat',
                          'fa-tiktok': social.label === 'TikTok',
                          'fa-x-twitter': social.label === 'Twitter',
                          'fa-youtube': social.label === 'YouTube',
                        })}
                        key={index}
                      ></i>
                    ))}
                  </div>
                </Accordion.Button>

                <Accordion.Panel>
                  <SocialMediaForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item noPanel={user.type === 'Associate'}>
                <Accordion.Button>
                  <div className="flex items-center justify-between gap-6">
                    <div>
                      <div className="text-lg font-medium">License{user.type === 'Associate' ? 's' : ''}</div>
                      <div className="flex flex-wrap gap-1 text-gray-500">
                        {user.license && (
                          <span className="text-gray-500">
                            {user.license_state} - {user.license}{' '}
                            <span className={new Date(user.license_expires_at) <= new Date() ? 'font-medium text-red-600' : ''}>
                              ({new Date(user.license_expires_at) <= new Date() ? 'Expired' : 'Expires'}{' '}
                              {new Date(user.license_expires_at).toLocaleDateString('en-us', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              })}
                              )
                            </span>
                          </span>
                        )}
                      </div>
                    </div>

                    {user.type === 'Associate' && (
                      <Button
                        theme="outline"
                        onClick={() =>
                          administrating ? router.visit(route('admin.associates.show', user.associate_id)) : router.visit(route('my-card'))
                        }
                      >
                        <span>Edit{!administrating && ' in My Card'}</span>
                      </Button>
                    )}
                  </div>
                </Accordion.Button>

                <Accordion.Panel>
                  <LicenseForm user={user} />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion.Items>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
