import React, { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import { router } from '@inertiajs/react'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'
import { useForm, usePage } from '@inertiajs/react'
import AssociateEdit from '../Admin/Associates/Edit'

export default function ContactEdit({ fieldToFocus, linkedId, linkedType, record, open, onClosed }) {
  let focusRef = useRef()
  const { tags } = usePage().props
  const { user } = usePage().props.auth
  const administrating = route().current().startsWith('admin')
  const [submitting, setSubmitting] = useState(false)

  const form = useForm({})
  const { clearErrors, data, errors, setData, setError, post, transform } = form

  const [addressTypes] = useState([
    { label: 'Home', value: 'Home' },
    { label: 'Work', value: 'Work' },
    { label: 'Other', value: 'Other' },
  ])
  const [emailTypes, setEmailTypes] = useState([
    { label: 'Personal', value: 'Personal' },
    { label: 'Work', value: 'Work' },
    { label: 'Other', value: 'Other' },
  ])
  const [notes, setNotes] = useState('')
  const [phoneTypes] = useState([
    { label: 'Mobile', value: 'Mobile' },
    { label: 'Work', value: 'Work' },
    { label: 'Home', value: 'Home' },
    { label: 'Fax', value: 'Fax' },
  ])
  const [significantDateTypes, setSignificantDateTypes] = useState([
    { label: 'Anniversary', value: 'Anniversary' },
    { label: 'Birthday', value: 'Birthday' },
    { label: 'Graduation', value: 'Graduation' },
  ])
  const [socialTypes] = useState([
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Flickr', value: 'Flickr' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'Linked In', value: 'Linked In' },
    { label: 'Pinterest', value: 'Pinterest' },
    { label: 'Snapchat', value: 'Snapchat' },
    { label: 'TikTok', value: 'TikTok' },
    { label: 'Twitter', value: 'Twitter' },
    { label: 'You Tube', value: 'You Tube' },
  ])
  const states = [
    { value: 'AK', label: 'Alaska' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'New York' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WY', label: 'Wyoming' },
  ]
  const [websiteTypes, setWebsiteTypes] = useState([
    { label: 'Blog', value: 'Blog' },
    { label: 'Company', value: 'Company' },
    { label: 'Profile', value: 'Profile' },
    { label: 'Social Media', value: 'Social Media' },
  ])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError()
    }
  }, [errors])

  useEffect(() => {
    if (notes != data.notes) {
      setData({ ...data, notes: notes })
    }
  }, [notes])

  useEffect(() => {
    if (open) {
      setData(loadData(record))
    } else {
      clearErrors()
    }
  }, [open])

  function loadData(contact) {
    const defaultData = {
      type: 'Contact',
      phone_numbers: [{ label: 'Mobile', primary: true }],
      emails: [{ primary: true }],
      addresses: [{ type: 'Home', primary: true }],
      significant_dates: [{}],
      social_accounts: [{ primary: true }],
      websites: [{ primary: true }],
    }

    let addresses = contact?.associate
      ? contact.addresses?.filter((address) => address.type !== 'Brokerage Business & Accounting Address')
      : contact?.addresses

    return {
      ...defaultData,
      ...(contact && {
        id: contact.id || null,
        type: contact.type || defaultData.type,
        permissions: contact.type === 'Associate' && contact.permissions ? contact.permissions : 'view',
        prefix: contact.prefix || '',
        first_name: contact.first_name || '',
        nickname: contact.nickname || '',
        middle_name: contact.middle_name || '',
        last_name: contact.last_name || '',
        suffix: contact.suffix || '',
        industry: contact.industry || '',
        company: contact.company || '',
        title: contact.title || '',
        source: contact.source || '',
        phone_numbers:
          contact.phone_numbers?.length > 0
            ? contact.phone_numbers.map((phone) => ({ ...phone, phone: phone.phone_formatted }))
            : defaultData.phone_numbers,
        emails:
          contact.emails?.length > 0
            ? contact.emails.map((email) => ({
                ...email,
                disableDeleting: !Helpers.isAdminOrHR(user) && contact?.associate && email.primary,
              }))
            : defaultData.emails,
        addresses: addresses?.length > 0 ? addresses : defaultData.addresses,
        significant_dates: contact.significant_dates?.length > 0 ? contact.significant_dates : defaultData.significant_dates,
        social_accounts: contact.social_accounts?.length > 0 ? contact.social_accounts : defaultData.social_accounts,
        websites: contact.websites?.length > 0 ? contact.websites : defaultData.websites,
        notes: contact.notes || '',
        invite: false,

        ...(contact.associate && {
          commission_pct: contact.associate.commission_pct || '70',
          cap_anniversary_date: contact.associate.cap_anniversary_date,
          dob: contact.associate.dob,
          ...(administrating &&
            Helpers.isAdminOrHR(user) && {
              pal: contact.associate.pal,
              sponsor_id: contact.associate.sponsor_id,
              sponsor1stTier: contact.associate.sponsor_id
                ? { id: contact.associate.sponsor_id, name: contact.associate.sponsor1stTier?.name }
                : null,
              support_vp: contact.associate.support_vp,
              support_vps: contact.associate.support_vps || [{}],
              transaction_support: contact.associate.transaction_support || {
                enabled: true,
              },
            }),
          vehicle_make: contact.associate.vehicle_make,
          vehicle_model: contact.associate.vehicle_model,
          vehicle_year: contact.associate.vehicle_year,
          vehicle_color: contact.associate.vehicle_color,
          vehicle_state: contact.associate.vehicle_state,
          vehicle_plate: contact.associate.vehicle_plate,
          drivers_license_state: contact.associate.drivers_license_state,
          drivers_license_expires_at: contact.associate.drivers_license_expires_at,
        }),

        ...(linkedType === 'relationship' && {
          linked_id: linkedId,
          linked_type: linkedType,
          relationship: contact.relationship,
        }),
      }),
    }
  }

  const fetchIndustries = (value) => axios.get('/api/services', { params: { query: value } })

  const scrollToFirstError = () => {
    let id
    for (let key in errors) {
      id = key
      if (key.indexOf('emails') >= 0) {
        id = 'emails'
      }
      break
    }

    const input = document.getElementById(id)
    const container = document.getElementById('slideover-scroll-container')

    if (input && container) {
      container.scrollTo({ behavior: 'smooth', top: input.offsetTop - (id != 'emails' ? 25 : 0) })
    }
  }

  const submit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    let transformedData = {
      addresses: data.addresses.filter((e) => e.street || e.city),
      emails: data.emails.filter((e) => e.email),
      phone_numbers: data.phone_numbers.filter((e) => e.phone),
      significant_dates: data.significant_dates.filter((e) => e.value),
      websites: data.websites.filter((e) => e.url),
      social_accounts: data.social_accounts.filter((e) => e.url),
      support_vps: data.support_vps?.filter((e) => e.supervisor_id || e.states),
    }

    clearErrors()

    if (data.id) {
      router.post(
        route('contacts.update', data.id),
        {
          ...data,
          ...transformedData,
          _method: 'patch',
        },
        {
          preserveState: true,
          preserveScroll: true,
          replace: true,
          onSuccess: () => {
            clearErrors()
            onClosed()
          },
          onError: (errors) => setError(errors),
          onFinish: () => setTimeout(() => setSubmitting(false), 150),
        },
      )
    } else {
      transform((data) => ({
        ...data,
        ...transformedData,
      }))

      post(route('contacts.store'), {
        onSuccess: () => {
          clearErrors()
          onClosed()
        },
        onFinish: () => setTimeout(() => setSubmitting(false), 150),
      })
    }
  }

  if (Object.entries(data).length === 0) {
    return <></>
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="newContact" disabled={submitting}>
          {data.id ? 'Save Changes' : 'Create'}
        </Button>
      }
      footerLeftActions={
        !Boolean(data.id) && (
          <Checkbox
            name={`invite`}
            label="Invite to be a PRO"
            value={data.invite}
            onChange={(checked) => setData({ ...data, invite: checked })}
          />
        )
      }
      show={open}
      size="max-w-2xl"
      title={data.id ? 'Edit' : 'New'}
      subTitle={record?.associate ? 'Associate' : 'Contact'}
      onClosed={onClosed}
      aboveMessages
    >
      <form id="newContact" onSubmit={submit}>
        {Boolean(data.linked_id) && data.linked_type === 'relationship' && (
          <div className="mb-5">
            <Heading>Relationship Information</Heading>

            <TextInput
              ref={focusRef}
              label="Relationship"
              name="relationship"
              value={data.relationship}
              onChange={(value) => setData({ ...data, relationship: value })}
            />
          </div>
        )}

        {!data.id && (
          <Fragment>
            <div className="mb-5">
              <Heading>Notes</Heading>
              <RichTextEditor data={data.notes} theme="simple" onChange={(value) => setNotes(value)} autoFocus />
            </div>
          </Fragment>
        )}

        <div className="mb-5">
          {record?.associate && (
            <AssociateEdit
              clearErrors={clearErrors}
              data={data}
              errors={errors}
              fieldToFocus={fieldToFocus}
              states={states}
              setData={setData}
            />
          )}

          <Heading>Name</Heading>

          <TextInput
            ref={!data.linked_id || !fieldToFocus || fieldToFocus.toLowerCase() === 'prefix' ? focusRef : null}
            label="Prefix"
            name="prefix"
            value={data.prefix}
            onChange={(value) => setData({ ...data, prefix: value })}
            onBlur={() => setData({ ...data, prefix: Helpers.capitalize(data.prefix) })}
          />

          <div className="sm:flex sm:gap-4">
            <div className="flex-1">
              <TextInput
                ref={!data.linked_id || !fieldToFocus || fieldToFocus.toLowerCase() === 'first name' ? focusRef : null}
                label="First Name"
                name="first_name"
                value={data.first_name}
                error={errors.first_name}
                onChange={(value) => setData({ ...data, first_name: value })}
                onBlur={() => setData({ ...data, first_name: Helpers.capitalize(data.first_name) })}
                required
              />
            </div>

            <div className="sm:w-56">
              <TextInput
                ref={fieldToFocus?.toLowerCase() === 'nickname' ? focusRef : null}
                label={
                  <div className="flex items-center gap-1.5">
                    <span>Nickname</span>

                    <Tooltip
                      label="This will be used instead of this contact's first name when sending transaction emails."
                      placement="bottom"
                    >
                      <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                    </Tooltip>
                  </div>
                }
                name="nickname"
                value={data.nickname}
                onChange={(value) => setData({ ...data, nickname: value })}
                onBlur={() => setData({ ...data, nickname: Helpers.capitalize(data.nickname) })}
              />
            </div>
          </div>

          <TextInput
            ref={fieldToFocus?.toLowerCase() === 'middle name' ? focusRef : null}
            label="Middle Name"
            name="middle_name"
            value={data.middle_name}
            onChange={(value) => setData({ ...data, middle_name: value })}
            onBlur={() => setData({ ...data, middle_name: Helpers.capitalize(data.middle_name) })}
          />
          <TextInput
            ref={fieldToFocus?.toLowerCase() === 'last name' ? focusRef : null}
            label="Last Name"
            name="last_name"
            value={data.last_name}
            error={errors.last_name}
            onChange={(value) => setData({ ...data, last_name: value })}
            onBlur={() => setData({ ...data, last_name: Helpers.capitalize(data.last_name) })}
            required
          />
          <TextInput
            ref={fieldToFocus?.toLowerCase() === 'suffix' ? focusRef : null}
            label="Suffix"
            name="suffix"
            value={data.suffix}
            onChange={(value) => setData({ ...data, suffix: value })}
            onBlur={() => setData({ ...data, suffix: Helpers.capitalize(data.suffix) })}
          />

          {!record?.associate && (
            <Fragment>
              <Select
                ref={fieldToFocus?.toLowerCase() === 'industry' ? focusRef : null}
                label="Industry"
                name="industry"
                value={data.industry}
                onChange={(selected) => setData({ ...data, industry: selected && (selected.name || selected.label) })}
                onInputChanged={(value) => fetchIndustries(value)}
                async
                creatable
              />

              <TextInput
                ref={fieldToFocus?.toLowerCase() === 'company' ? focusRef : null}
                label="Company"
                name="company"
                value={data.company}
                error={errors.company}
                onChange={(value) => setData({ ...data, company: value })}
              />

              <TextInput
                ref={fieldToFocus?.toLowerCase() === 'title' ? focusRef : null}
                label="Title"
                name="title"
                value={data.title}
                onChange={(value) => setData({ ...data, title: value })}
              />

              <TextInput
                ref={fieldToFocus?.toLowerCase() === 'source' ? focusRef : null}
                label="Source"
                name="source"
                value={data.source}
                onChange={(value) => setData({ ...data, source: value })}
              />

              {record == undefined && tags != undefined && (
                <Select
                  label="Tags"
                  name="tags"
                  options={[
                    {
                      label: 'Corporate Tags',
                      options: tags.corporate.map((tag) => ({ value: tag.id, label: tag.label })),
                    },
                    {
                      label: 'Personal Tags',
                      options: tags.personal.map((tag) => ({ value: tag.id, label: tag.label })),
                    },
                  ]}
                  placeholder="Select one or more"
                  value={data.tags}
                  onChange={(selected) => setData({ ...data, tags: selected })}
                  multiple
                />
              )}
            </Fragment>
          )}
        </div>

        <div id="phone_numbers" className="mb-5">
          <Heading>
            {errors.phone_numbers && <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>}
            Phone Numbers
          </Heading>

          {errors.phone_numbers && <div className="mx-1 mt-2 text-red-600">{errors.phone_numbers}</div>}

          <MultipleInputBlock
            ref={fieldToFocus?.toLowerCase() === 'phone' ? focusRef : null}
            data={data.phone_numbers}
            onChange={(value) => setData({ ...data, phone_numbers: value })}
            hasPrimary
            render={({ focusRef, data, index, onChange }) => (
              <div className="flex-1 sm:flex sm:flex-wrap">
                <TextInput
                  ref={focusRef}
                  classes="sm:flex-grow"
                  name="phone"
                  placeholder="Number"
                  value={data.phone || ''}
                  onChange={(value) => onChange(value, 'phone')}
                />

                <Select
                  classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                  name="type"
                  options={phoneTypes}
                  placeholder="Type"
                  value={data.type}
                  isClearable={false}
                  onChange={(selected) => onChange(selected && selected.value, 'type')}
                  creatable
                />

                {Boolean(errors[`phone_numbers.${index}.value`]) && (
                  <div className="mx-1 mt-2 text-red-600">{errors[`phone_numbers.${index}.value`]}</div>
                )}
              </div>
            )}
          ></MultipleInputBlock>
        </div>

        <div id="emails" className="mb-5">
          <Heading>
            {errors.emails && <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>}
            Emails
          </Heading>

          {errors.emails && <div className="mx-1 mt-2 text-red-600">{errors.emails}</div>}

          <MultipleInputBlock
            ref={fieldToFocus?.toLowerCase() === 'email' ? focusRef : null}
            data={data.emails}
            onChange={(value) => setData({ ...data, emails: value })}
            hasError={Object.keys(errors).some((k) => k.indexOf('emails'))}
            hasPrimary
            disablePrimary={!Helpers.isAdminOrHR(user) && record?.associate}
            render={({ focusRef, data, index, onChange }) => (
              <div className="flex-1 sm:flex sm:flex-wrap">
                <TextInput
                  ref={focusRef}
                  classes="sm:flex-grow"
                  error={errors[`emails.${index}.email`]}
                  name="email"
                  placeholder="Email"
                  value={data.email ?? ''}
                  onChange={(value) => onChange(value, 'email')}
                  disabled={!Helpers.isAdminOrHR(user) && record?.associate && data.type === 'Work'}
                  hideErrorMessage
                />

                <Select
                  classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                  name="type"
                  options={emailTypes}
                  placeholder="Type"
                  value={data.type}
                  isClearable={false}
                  onChange={(selected) => {
                    if (selected) {
                      if (emailTypes.find((i) => i.value === selected.value) == undefined) {
                        setEmailTypes(emailTypes.concat(selected))
                      }
                    }

                    onChange(selected && selected.value, 'type')
                  }}
                  disabled={!Helpers.isAdminOrHR(user) && record?.associate && data.type === 'Work'}
                  creatable
                />

                {Boolean(errors[`emails.${index}.value`]) && (
                  <div className="mx-1 mt-2 text-red-600">{errors[`emails.${index}.value`]}</div>
                )}
              </div>
            )}
          ></MultipleInputBlock>
        </div>

        <div id="addresses" className="mb-5">
          <Heading>
            {Object.keys(errors).find((key) => key.includes('addresses')) && (
              <i className="fas fa-exclamation-triangle mr-2 text-lg text-red-600"></i>
            )}
            {record?.associate ? 'Home Address' : 'Addresses'}
          </Heading>

          {errors.addresses && <div className="mx-1 mt-2 text-red-600">{errors.addresses}</div>}

          <MultipleInputBlock
            ref={fieldToFocus?.toLowerCase() === 'address' ? focusRef : null}
            data={data.addresses}
            onChange={(value) => setData({ ...data, addresses: value })}
            hasPrimary
            render={({ focusRef, data, index, onChange }) => (
              <div className="flex-1 space-y-2.5">
                {(errors[`addresses.${index}.street`] ||
                  errors[`addresses.${index}.city`] ||
                  errors[`addresses.${index}.state`] ||
                  errors[`addresses.${index}.postal_code`]) && <div className="mx-1 text-red-600">Complete the highlighted fields.</div>}

                <TextInput
                  ref={focusRef}
                  name="street"
                  label="Street"
                  value={data.street ?? ''}
                  onChange={(value) => onChange(value, 'street')}
                  error={errors[`addresses.${index}.street`]}
                  hideErrorMessage
                />

                <TextInput
                  name="street2"
                  label="Apartment/Suite/Unit #"
                  value={data.street2 ?? ''}
                  onChange={(value) => onChange(value, 'street2')}
                />

                <TextInput
                  name="city"
                  value={data.city ?? ''}
                  label="City"
                  onChange={(value) => onChange(value, 'city')}
                  error={errors[`addresses.${index}.city`]}
                  hideErrorMessage
                />

                <Select
                  name="state"
                  options={states}
                  label="State"
                  value={data.state}
                  isClearable={false}
                  onChange={(selected) => onChange(selected && selected.value, 'state')}
                  error={errors[`addresses.${index}.state`]}
                  hideErrorMessage
                />

                <TextInput
                  classes="mb-1"
                  name="postal_code"
                  label="Zip Code"
                  value={data.postal_code ?? ''}
                  onChange={(value) => onChange(value, 'postal_code')}
                  error={errors[`addresses.${index}.postal_code`]}
                  hideErrorMessage
                />

                <Select
                  classes="mb-1"
                  name="type"
                  label="Type"
                  options={record?.associate ? addressTypes.filter((type) => type.value != 'Work') : addressTypes}
                  value={record?.associate ? 'Home' : data.type}
                  isClearable={false}
                  onChange={(selected) => onChange(selected && selected.value, 'type')}
                  creatable={!record?.associate}
                />
                {Boolean(errors[`addresses.${index}.value`]) && (
                  <div className="mx-1 mt-2 text-red-600">{errors[`addresses.${index}.value`]}</div>
                )}
              </div>
            )}
          ></MultipleInputBlock>
        </div>

        <div id="significant_dates" className="mb-5">
          <Heading>Significant Dates</Heading>

          <MultipleInputBlock
            data={data.significant_dates}
            onChange={(value) => setData({ ...data, significant_dates: value })}
            render={({ data, onChange }) => (
              <div className="flex-1 sm:flex">
                <DatePicker date={data.value && new Date(data.value)} onChange={(date) => onChange(date, 'value')}></DatePicker>

                <Select
                  creatable={true}
                  classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                  name="type"
                  options={significantDateTypes}
                  placeholder="Type"
                  value={data.type}
                  isClearable={false}
                  onChange={(selected) => {
                    if (selected) {
                      if (significantDateTypes.find((i) => i.value === selected.value) == undefined) {
                        setSignificantDateTypes(significantDateTypes.concat(selected))
                      }
                    }

                    onChange(selected && selected.value, 'type')
                  }}
                />
              </div>
            )}
          ></MultipleInputBlock>
        </div>

        <div id="websites" className="mb-5">
          <Heading>Websites</Heading>
          <MultipleInputBlock
            data={data.websites}
            onChange={(value) => setData({ ...data, websites: value })}
            hasPrimary
            render={({ data, onChange }) => (
              <div className="flex-1 sm:flex">
                <TextInput
                  classes="sm:flex-grow"
                  name="value"
                  value={data.url ?? ''}
                  placeholder="i.e. https://www.mysite.com/"
                  onChange={(value) => onChange(value, 'url')}
                />

                <Select
                  classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                  name="type"
                  options={websiteTypes}
                  placeholder="Type"
                  value={data.type}
                  isClearable={false}
                  onChange={(selected) => {
                    if (selected) {
                      if (websiteTypes.find((i) => i.value === selected.value) == undefined) {
                        setWebsiteTypes(websiteTypes.concat(selected))
                      }
                    }

                    onChange(selected && selected.value, 'type')
                  }}
                  creatable
                />
              </div>
            )}
          ></MultipleInputBlock>
        </div>

        <div id="social_accounts" className="mb-5">
          <Heading>Social</Heading>
          <MultipleInputBlock
            data={data.social_accounts}
            onChange={(value) => setData({ ...data, social_accounts: value })}
            render={({ data, onChange }) => (
              <div className="flex-1 sm:flex">
                <TextInput
                  classes="sm:flex-grow"
                  name="url"
                  value={data.url ?? ''}
                  placeholder="i.e. https://facebook.com/mypage"
                  onChange={(value) => onChange(value, 'url')}
                />

                <Select
                  classes="mt-2 sm:flex-grow sm:ml-3 sm:mt-0"
                  name="type"
                  options={socialTypes}
                  placeholder="Type"
                  value={data.type}
                  isClearable={false}
                  onChange={(selected) => onChange(selected && selected.value, 'type')}
                  creatable
                />
              </div>
            )}
          ></MultipleInputBlock>
        </div>
      </form>
    </SlideOver>
  )
}
