import { useEffect, useState } from 'react'
import Helpers from '@/utils/helpers'
import MultipleInputBlock from '@/Shared/Forms/MultipleInputBlock'
import Select from '@/Shared/Forms/Select'
import TextArea from '@/Shared/Forms/TextArea'
import TextInput from '@/Shared/Forms/TextInput'
import { usePage } from '@inertiajs/react'

export default ({ associates, data, onChanged }) => {
  return (
    <MultipleInputBlock
      data={data.additional_compensation}
      onChange={(value) => onChanged(value)}
      render={(props) => {
        return <AdditionalCompensationItem associates={associates} {...props} />
      }}
    />
  )
}

const AdditionalCompensationItem = ({ associates, data, index, onChange }) => {
  const { errors } = usePage().props
  const { user } = usePage().props.auth

  const LEAD_AGENT = 'Lead Agent'
  const CO_SERVICING_ASSOCIATE = 'Co-Servicing Associate'
  const ENTIRE_ASSOCIATE_SIDE_SPLIT = 'Entire Associate-Side Split'
  const BROKERAGE_SIDE_SPLIT = 'Brokerage-Side Split'

  const [availableAssociatesForDeduction, setAvailableAssociatesForDeduction] = useState(undefined)
  const [availableDeductionGroups, setAvailableDeductionGroups] = useState([])

  useEffect(() => updateDeductionGroups(), [data.associate_id])

  const fetchContacts = (value) =>
    axios.get('/api/contacts', {
      params: {
        groups: 'associates',
        limit: 25,
        search: value,
      },
    })

  const onDeductedFromChanged = (selected) => {
    let transformed = { ...data, deducted_from: selected?.value }

    let availableAssociates = associates
      .filter((associate) => associate.contact.associate.id != data.associate_id && !associate.transaction_support)
      .map((associate) => ({
        id: associate.contact.associate.id,
        name: associate.full_name,
        is_transaction_owner: associate.is_transaction_owner,
      }))

    if (selected?.value === LEAD_AGENT) {
      let leadAgent = availableAssociates.find((associate) => associate.is_transaction_owner)
      transformed = {
        ...transformed,
        deducted_from_id: leadAgent.id,
        deducted_from_name: leadAgent.name,
      }
    } else if (selected?.value === CO_SERVICING_ASSOCIATE) {
      availableAssociates = availableAssociates.filter((associate) => !associate.is_transaction_owner)
      if (availableAssociates.length == 1) {
        transformed = {
          ...transformed,
          deducted_from_id: availableAssociates[0].id,
          deducted_from_name: availableAssociates[0].name,
        }
      }
    } else {
      transformed = {
        ...transformed,
        deducted_from_id: null,
        deducted_from_name: null,
      }
    }

    setAvailableAssociatesForDeduction(availableAssociates)

    onChange(transformed)
  }

  const updateDeductionGroups = () => {
    let groups = []
    let availableAssociates = associates
      .filter((associate) => associate.contact.associate.id != data.associate_id && !associate.transaction_support)
      .map((associate) => ({
        id: associate.contact.associate.id,
        name: associate.full_name,
        is_transaction_owner: associate.is_transaction_owner,
      }))

    if (availableAssociates.length > 0) {
      if (availableAssociates.find((associate) => associate.is_transaction_owner)) {
        groups = groups.concat({ value: LEAD_AGENT, label: LEAD_AGENT })
      }

      if (availableAssociates.find((associate) => !associate.is_transaction_owner)) {
        groups = groups.concat({ value: CO_SERVICING_ASSOCIATE, label: CO_SERVICING_ASSOCIATE })
      }

      if (availableAssociates.length > 1) {
        groups = groups.concat({ value: ENTIRE_ASSOCIATE_SIDE_SPLIT, label: ENTIRE_ASSOCIATE_SIDE_SPLIT })
      }
    }

    if (Helpers.isAdminOrHR(user)) {
      groups = groups.concat({ value: BROKERAGE_SIDE_SPLIT, label: BROKERAGE_SIDE_SPLIT })
    }

    setAvailableDeductionGroups(groups)
  }

  return (
    <div className="flex-1">
      <div className="mb-3 space-y-1.5">
        <Select
          label="Associate to compensate"
          optionLabel={(option) =>
            option.label || (
              <div className="group flex items-center space-x-3">
                {option.avatar ? (
                  <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
                ) : (
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
                    <div className="text-base font-medium tracking-wide">{option.initials}</div>
                  </div>
                )}

                <div>
                  <div className="space-x-1">
                    <span className="space-x-1 font-medium text-gray-900">
                      <span>{option.name}</span>
                    </span>
                  </div>
                </div>
              </div>
            )
          }
          error={errors[`additional_compensation.${index}.associate_id`]}
          optionValue={(option) => option.id}
          placeholder="Search Associates"
          value={data.associate_name}
          onChange={(selected) =>
            onChange({
              ...data,
              associate_id: selected?.associate_id,
              associate_name: selected?.name,
              deducted_from: null,
              deducted_from_id: null,
              deducted_from_name: null,
            })
          }
          onInputChanged={(value) => fetchContacts(value)}
          isClearable={false}
          disabled={!Helpers.isAdminOrHR(user) && data.deducted_from === BROKERAGE_SIDE_SPLIT}
          required
          async
        />
      </div>

      <TextInput
        label="Amount"
        icon={<i className="fas fa-dollar-sign"></i>}
        value={data.amount}
        error={errors[`additional_compensation.${index}.amount`]}
        onBlur={() => onChange(Helpers.formatDecimal(Helpers.parseCurrency(data.amount || 0), 2), 'amount')}
        onChange={(value) => onChange(value, 'amount')}
        onFocus={(e) => e.target.select()}
        clearable={Helpers.isAdminOrHR(user) || data.deducted_from !== BROKERAGE_SIDE_SPLIT}
        disabled={!Helpers.isAdminOrHR(user) && data.deducted_from === BROKERAGE_SIDE_SPLIT}
        required
      />

      {availableDeductionGroups.length > 0 && (
        <Select
          label={
            <div>
              <div>
                Deducted from <span className="text-red-600">*</span>
              </div>
            </div>
          }
          error={errors[`additional_compensation.${index}.deducted_from`]}
          options={availableDeductionGroups}
          placeholder="Select One"
          value={data.deducted_from}
          onChange={(selected) => onDeductedFromChanged(selected)}
          isClearable={false}
          disabled={!Helpers.isAdminOrHR(user) && data.deducted_from === BROKERAGE_SIDE_SPLIT}
        />
      )}

      {[CO_SERVICING_ASSOCIATE].indexOf(data.deducted_from) >= 0 && (
        <Select
          label="Associate Deducted From"
          error={errors[`additional_compensation.${index}.deducted_from_id`]}
          optionValue={(option) => option.id}
          options={availableAssociatesForDeduction}
          placeholder="Select Associate"
          value={data.deducted_from_name || null}
          onChange={(selected) => onChange({ ...data, deducted_from_id: selected?.id, deducted_from_name: selected?.name })}
          isClearable={false}
          required
        />
      )}

      <TextArea
        label={<span className="mb-0.5 block text-sm font-medium uppercase text-gray-500">Comments:</span>}
        name="comments"
        value={data.comments}
        error={errors[`additional_compensation.${index}.comments`]}
        onChange={(value) => onChange(value, 'comments')}
      />
    </div>
  )
}
