import { Fragment, useEffect, useRef, useState } from 'react'
import Alert from '@/Shared/Alert'
import Button from '@/Shared/Button'
import Checkbox from '@/Shared/Forms/Checkbox'
import classNames from 'classnames'
import Dialog from '@/Shared/Dialog/Index'
import Helpers from '@/utils/helpers'
import { useForm } from '@inertiajs/react'

export default function ChangeAssociateSideSplitsDialog({ contactRemoving, transaction, open, onClose, onApprove }) {
  let focusRef = useRef()
  const { data, setData, clearErrors, errors, setError } = useForm()
  const [disabled, setDisabled] = useState(false)
  const associates = transaction.contacts.filter((tc) => tc.contact_type === 'Associate')
  const transaction_support_enabled = associates.some((associate) => associate.transaction_support)
  const splits = data.splits?.filter((split) => !split.transaction_support) || []

  useEffect(() => {
    if (contactRemoving) {
      const maxShare = contactRemoving.transaction_support ? 15 : 100

      let remainingAssociates = transaction.contacts
        .filter((tc) => tc.contact_type === 'Associate' && tc.id != contactRemoving.id)
        .map((associate) => ({
          id: associate.id,
          full_name: associate.full_name,
          is_transaction_owner: associate.is_transaction_owner,
          transaction_support: associate.transaction_support,
          commission_pct: associate.split,
          cap_pct: associate.cap_split,
          is_owner: associate.is_transaction_owner,
        }))

      // Calculate equal shares and handle rounding remainder
      let associatesCount = remainingAssociates.filter((split) =>
        contactRemoving.transaction_support ? split.transaction_support : !split.transaction_support,
      ).length

      let equalShare = Helpers.numberPrecision(maxShare / associatesCount, 2)
      let combinedShares = Helpers.numberPrecision(equalShare * associatesCount, 2)
      let remainder = Helpers.round(maxShare - combinedShares, 2)
      let equalCommissionPct = Math.round((equalShare + remainder) * 1e12) / 1e12

      const updatedSplits = remainingAssociates.map((split) => {
        if (contactRemoving.transaction_support) {
          if (split.transaction_support) {
            return { ...split, commission_pct: equalCommissionPct, cap_pct: 0 }
          }
        } else {
          if (!split.transaction_support) {
            let commission_pct = split.is_transaction_owner ? equalCommissionPct : equalShare

            return {
              ...split,
              commission_pct: commission_pct,
              cap_pct: commission_pct,
            }
          }
        }

        // Reset to stored value
        const associate = associates.find((associate) => associate.id === split.id)
        return associate ? { ...split, commission_pct: associate.split, cap_pct: associate.cap_split } : split
      })

      setData({
        ...data,
        brokerage_split_auto_distribute: true,
        splits: updatedSplits,
      })
    }
  }, [contactRemoving])

  useEffect(() => {
    if (data.splits) {
      let hasError = false

      if (!contactRemoving.transaction_support) {
        let totalShare = splits.reduce((carry, split) => carry + split.commission_pct, 0)
        let totalCap = splits.reduce((carry, split) => carry + split.cap_pct, 0)

        if (totalShare != 100) {
          setError('commission_splits', `The total of all splits must add up to 100%. (Current: ${totalShare}%)`)
          hasError = true
        } else {
          clearErrors('commission_splits')
        }

        if (!data.brokerage_split_auto_distribute && totalCap != 100) {
          setError('brokerage_splits', `The total of all brokerage-side splits must add up to 100%. (Current: ${totalCap})`)
          hasError = true
        } else {
          clearErrors('brokerage_splits')
        }
      }

      setDisabled(hasError)
    }
  }, [data.splits])

  if (!contactRemoving) return <></>

  return (
    <Dialog
      focusRef={focusRef}
      footerActions={
        <Button theme="solid" onClick={() => onApprove(data)} disabled={disabled}>
          <span className="text-lg">Continue</span>
        </Button>
      }
      show={open}
      size="sm:max-w-xl"
      title={`Update ${contactRemoving.transaction_support ? 'Transaction Support' : 'Associate Side'} Splits`}
      cancelText="Close"
      onClosed={() => onClose()}
      focusCancelButton
    >
      <div className="mb-4 space-y-4">
        <Alert
          heading={`You are removing ${contactRemoving.first_name} ${contactRemoving.last_name} from ${contactRemoving.transaction_support ? 'Transaction Support' : 'the Team'}`}
          subtext={
            <span>
              The splits for the remaining {contactRemoving.transaction_support ? 'Transaction Support associates' : 'associates'} will be
              adjusted as follows.{' '}
              {(contactRemoving.transaction_support
                ? data.splits?.filter((split) => split.transaction_support).length > 1
                : splits.length > 1) && 'Performing this action will require re-approval of the Associate Co-Servicing Agreement.'}
            </span>
          }
          type="warning"
        />

        {transaction_support_enabled && contactRemoving.transaction_support ? (
          <div>
            <div className="mb-0.5 text-sm font-medium uppercase text-gray-500">
              <span>
                Transaction Support Associate Splits <span className="text-red-600">*</span>
              </span>
            </div>

            <div className="space-y-3">
              <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                {data.splits
                  ?.filter((split) => split.transaction_support)
                  ?.map((split) => (
                    <div className="flex divide-x divide-gray-300 bg-white" key={split.id}>
                      <span className="flex-1 bg-gray-200 bg-opacity-75 px-4 py-2 font-medium text-black">
                        {split.full_name}
                        <div className="text-[12px] uppercase leading-none text-gray-600">
                          {split.is_transaction_owner ? 'Lead' : 'Supporting'} Associate Split
                        </div>
                      </span>
                      <span className="flex w-48 items-center justify-center px-4 py-2 font-bold">
                        <div className="relative flex w-full">
                          <input
                            autoComplete="off"
                            autoCorrect="false"
                            className={classNames(
                              'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                              errors?.transaction_support_splits
                                ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                            )}
                            type="number"
                            value={split.commission_pct}
                            onChange={(e) => {
                              clearErrors('commission_split')
                              setData({
                                ...data,
                                splits: data.splits.map((s) =>
                                  s.id == split.id
                                    ? {
                                        ...s,
                                        commission_pct: parseFloat(e.target.value),
                                        cap_pct: data.brokerage_split_auto_distribute ? parseFloat(e.target.value) : s.cap_pct,
                                      }
                                    : s,
                                ),
                              })
                            }}
                            onFocus={(e) => e.target.select()}
                          />

                          <span
                            className={classNames(
                              'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                              errors?.transaction_support_splits
                                ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                : 'border-gray-300 bg-gray-200 text-gray-700',
                            )}
                          >
                            <i className="fal fa-percent text-lg"></i>
                          </span>
                        </div>
                      </span>
                    </div>
                  ))}
              </div>

              {errors?.transaction_support_splits && (
                <div className="font-semibold text-red-600">* {errors.transaction_support_splits}</div>
              )}
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <div className="mb-0.5 text-sm font-medium uppercase text-gray-500">
                <span>
                  Associate-Side Splits <span className="text-red-600">*</span>
                </span>
              </div>

              <div className="space-y-3">
                <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                  {splits?.map((split) => (
                    <div className="flex divide-x divide-gray-300 bg-white" key={split.id}>
                      <span className="flex-1 bg-gray-200 bg-opacity-75 px-4 py-2 font-medium text-black">
                        {split.full_name}
                        <div className="text-[12px] uppercase leading-none text-gray-600">
                          {split.is_transaction_owner ? 'Lead' : 'Supporting'} Associate Split
                        </div>
                      </span>
                      <span className="flex w-48 items-center justify-center px-4 py-2 font-bold">
                        <div className="relative flex w-full">
                          <input
                            autoComplete="off"
                            autoCorrect="false"
                            className={classNames(
                              'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                              splits?.length == 1 ? 'cursor-not-allowed bg-gray-200' : '',
                              errors?.commission_splits
                                ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                            )}
                            type="number"
                            value={split.commission_pct}
                            onChange={(e) => {
                              clearErrors('commission_split')
                              setData({
                                ...data,
                                splits: data.splits.map((s) =>
                                  s.id == split.id
                                    ? {
                                        ...s,
                                        commission_pct: parseFloat(e.target.value),
                                        cap_pct: data.brokerage_split_auto_distribute ? parseFloat(e.target.value) : s.cap_pct,
                                      }
                                    : s,
                                ),
                              })
                            }}
                            onFocus={(e) => e.target.select()}
                            disabled={splits?.length == 1}
                          />

                          <span
                            className={classNames(
                              'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                              errors?.commission_splits
                                ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                : 'border-gray-300 bg-gray-200 text-gray-700',
                            )}
                          >
                            <i className="fal fa-percent text-lg"></i>
                          </span>
                        </div>
                      </span>
                    </div>
                  ))}
                </div>

                {errors.commission_splits && <div className="font-semibold text-red-600">{errors.commission_splits}</div>}
              </div>
            </div>

            {splits.length > 1 && (
              <div>
                <div className="mb-3 text-sm font-medium uppercase text-gray-500">
                  <span>
                    Brokerage-Side <span className="text-red-600">*</span>
                  </span>
                </div>

                <div className="mb-2 mt-1">
                  <Checkbox
                    name="brokerage_split_auto_distribute"
                    label="Distribute Automatically"
                    description="CAP will be applied to all Associates according to their Associate-side split %."
                    value={data.brokerage_split_auto_distribute}
                    onChange={(checked) =>
                      setData({
                        ...data,
                        brokerage_split_auto_distribute: checked,
                        ...(checked
                          ? {
                              splits: data.splits.map((s) => ({
                                ...s,
                                cap_pct: s.commission_pct,
                              })),
                            }
                          : {}),
                      })
                    }
                  />
                </div>

                <div className="space-y-3">
                  <div className="divide-y divide-gray-300 overflow-hidden rounded-md border border-gray-300">
                    {splits?.map((split, index) => (
                      <Fragment key={split.id}>
                        <div className="flex divide-x divide-gray-300 bg-white">
                          <span className="flex-1 bg-gray-200 bg-opacity-75 px-4 py-2 font-medium text-black">
                            {split.full_name}
                            <div className="text-[12px] uppercase leading-none text-gray-600">
                              {split.is_transaction_owner ? 'Lead' : 'Supporting'} Associate Brokerage Split
                            </div>
                          </span>
                          <span className="flex w-48 items-center justify-center px-4 py-2 font-bold">
                            <div className="relative flex w-full">
                              <input
                                name={`brokerage_split_${index}`}
                                autoComplete="off"
                                autoCorrect="false"
                                className={classNames(
                                  'font-md transition-border relative block h-11 w-full rounded-l px-4 py-2 placeholder-gray-400 outline-none duration-150 ease-in-out',
                                  data.brokerage_split_auto_distribute ? 'cursor-not-allowed bg-gray-200' : '',
                                  errors?.brokerage_splits
                                    ? 'border-transparent ring-2 ring-red-500 hover:ring-red-400 focus:ring-red-700'
                                    : 'border border-gray-300 hover:border-gray-400 focus:border-transparent focus:ring-2 focus:ring-primary-500',
                                )}
                                spellCheck="false"
                                type="number"
                                value={split.cap_pct}
                                onChange={(e) => {
                                  clearErrors('brokerage_splits')
                                  setData({
                                    ...data,
                                    commission_splits: data.splits.map((s) => {
                                      if (s.id == split.id || (s.contact_id != undefined && s.contact_id == split.contact_id)) {
                                        return {
                                          ...s,
                                          cap_pct: parseFloat(e.target.value),
                                        }
                                      }

                                      return s
                                    }),
                                  })
                                }}
                                onFocus={(e) => e.target.select()}
                                disabled={data.brokerage_split_auto_distribute}
                              />

                              <span
                                className={classNames(
                                  'flex w-20 items-center justify-center rounded-r border-b border-r border-t text-sm font-semibold transition-all duration-150 ease-in-out',
                                  errors?.brokerage_splits
                                    ? 'bg-red-100 text-red-500 ring-2 ring-red-500'
                                    : 'border-gray-300 bg-gray-200 text-gray-700',
                                )}
                              >
                                <i className="fal fa-percent text-lg"></i>
                              </span>
                            </div>
                          </span>
                        </div>
                      </Fragment>
                    ))}
                  </div>

                  {errors?.brokerage_splits && <div className="font-semibold text-red-600">* {errors.brokerage_splits}</div>}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Dialog>
  )
}
