import { Fragment, useEffect, useMemo, useState } from 'react'
import Button from '@/Shared/Button'
import CancelClosingDialog from './CancelClosingDialog'
import CancelReviewDialog from './CancelReviewDialog'
import CloseTransactionDialog from './CloseTransactionDialog'
import DisableTransactionSupportDialog from './DisableTransactionSupportDialog'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import NoteDialog from '@/Shared/NoteDialog'
import { Link, usePage } from '@inertiajs/react'
import Stage from './Stage'
import { Tooltip } from '@/Shared/Tooltip'
import { router } from '@inertiajs/react'
import classNames from 'classnames'
import Helpers from '@/utils/helpers'

export default function Card({
  approvable,
  onEdit,
  onCancelReview,
  onNoteCreating,
  onNoteCreated,
  onReviewApproved,
  onReviewRequested,
  onReviewPaused,
}) {
  const { transaction, auth } = usePage().props
  const administrating = Helpers.isAdminOrHR(auth.user)
  const [beforeClosing, setBeforeClosing] = useState(false)
  const [beforeCancelClosing, setBeforeCancelClosing] = useState(false)
  const [beforeCancelReview, setBeforeCancelReview] = useState(false)
  const [beforeTransactionSupportDisabled, setBeforeTransactionSupportDisabled] = useState(false)
  const [closingType, setClosingType] = useState('')
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [note, setNote] = useState(null)
  const transaction_support_enabled = transaction.contacts.some((contact) => contact.transaction_support)
  const myContact = transaction.contacts.find((c) => c.is_me)

  useEffect(() => {
    if (note != null) {
      setNoteDialogOpen(true)
    }
  }, [note])

  useEffect(() => {
    if (onNoteCreating) {
      setNote({ type: 'Note' })
    }
  }, [onNoteCreating])

  const canRequestComplianceReviews = useMemo(() => {
    if (administrating || !transaction_support_enabled) {
      return true
    }

    const myContact = transaction.contacts.find((tc) => tc.is_me)
    return myContact?.transaction_support || false
  }, [transaction])

  const displayTransactionName = () => {
    let type = transaction.type
    let parts = transaction.name.split(type)

    return (
      <div>
        <span className="font-bold uppercase text-green-900">{type}</span>
        <span className="font-bold text-black">{parts[1]}</span>
      </div>
    )
  }

  const handleBeginReview = () => {
    router.post(route('transactions.change-status', transaction.id), { closing_type: 'Under Review' })
  }

  const onTransactionClosing = (type) => {
    setClosingType(type)
    setBeforeClosing(true)
  }

  const onTransactionReOpening = () => {
    router.post(route('transactions.change-status', transaction.id))
  }

  const onTransactionSupportEnabling = () => {
    router.post(route('transactions.enable-transaction-support', transaction.id))
  }

  const onTransactionSupportDisabling = () => {
    if (administrating) {
      return onDisableTransactionSupport()
    }

    setBeforeTransactionSupportDisabled(true)
  }

  const onDisableTransactionSupport = (data) => {
    router.post(route('transactions.disable-transaction-support', transaction.id), data)
  }

  return (
    <div className="relative z-50 border-b border-gray-200 bg-white pb-6 pt-3">
      <div className="mb-3 px-3">
        <div className="flex items-center justify-between">
          <div>
            <Link
              href={route('transactions.index')}
              className="inline-flex h-10 items-center rounded-md px-3 leading-none text-blue-500 outline-none transition-all duration-150 ease-in-out hover:bg-blue-100 hover:text-gray-800 focus:ring-2 focus:ring-blue-500"
            >
              <i className="far fa-chevron-left mr-3 text-xl"></i>
              <span className="font-medium">Transactions</span>
            </Link>
          </div>
          {(transaction.editable || auth.user.support_vp) && (
            <div className="flex items-center justify-end gap-0.5">
              {transaction.editable && !transaction.restricted && (
                <Tooltip label="Edit" placement="bottom">
                  <Button theme="icon" onClick={onEdit}>
                    <i className="far fa-pen text-gray-500 group-hover:font-bold group-hover:text-blue-500"></i>
                  </Button>
                </Tooltip>
              )}

              {(transaction.status !== 'Under Review' || !transaction.restricted) &&
                (transaction.editable || !transaction_support_enabled) && (
                  <Dropdown id="actions-options" label="Actions" orientation="right" size="w-[21rem]">
                    {!transaction.alta_uploaded && (
                      <Fragment>
                        <div className="bg-gray-200 py-1 pl-4 text-sm font-semibold uppercase text-gray-700">General</div>

                        {transaction.editable && !transaction.type.includes('Fee Only') && (
                          <DropdownItem
                            colorClasses="text-red-500 hover:text-red-600"
                            onClick={() => setBeforeCancelClosing(true)}
                            disabled={!transaction.resettable || !['Open', 'Pending'].includes(transaction.status)}
                          >
                            <div className="flex items-center gap-2">
                              <i className="far fa-undo w-6 text-center"></i>
                              Reset & Start New Offer
                            </div>
                          </DropdownItem>
                        )}
                      </Fragment>
                    )}

                    {transaction.editable && !transaction.alta_uploaded && (
                      <Fragment>
                        <div className="bg-gray-200 py-1 pl-4 text-sm font-semibold uppercase text-gray-700">Change Status</div>

                        {['On-Hold', 'Lost', 'Archived'].indexOf(transaction.status) >= 0 && (
                          <DropdownItem onClick={() => onTransactionReOpening()}>
                            <div className="flex items-center gap-2">
                              <i className="fas fa-undo w-6 text-center"></i>
                              Re-open Transaction
                            </div>
                          </DropdownItem>
                        )}

                        {transaction.status !== 'On-Hold' && (
                          <DropdownItem onClick={() => onTransactionClosing('On-Hold')}>
                            <div className="flex items-center gap-2 text-gray-500 group-hover:text-gray-600">
                              <i className="far fa-pause w-6 text-center"></i>
                              On-Hold
                            </div>
                          </DropdownItem>
                        )}

                        {transaction.status !== 'Archived' && (
                          <DropdownItem onClick={() => onTransactionClosing('Archived')}>
                            <div className="flex items-center gap-2">
                              <i className="far fa-box-archive w-6 text-center"></i>
                              Archive
                            </div>
                          </DropdownItem>
                        )}

                        {transaction.status !== 'Lost' && (
                          <DropdownItem colorClasses="text-red-500 hover:text-red-600" onClick={() => onTransactionClosing('Lost')}>
                            <div className="flex items-center gap-2">
                              <i className="far fa-trash-xmark w-6 text-center"></i>
                              Lost
                            </div>
                          </DropdownItem>
                        )}
                      </Fragment>
                    )}

                    {!transaction.pending_transaction_support_disable &&
                      (administrating || myContact?.transaction_support || Boolean(auth.user.support_vp)) && (
                        <Fragment>
                          <div className="bg-gray-200 py-1 pl-4 text-sm font-semibold uppercase text-gray-700">Transaction Support</div>

                          {transaction_support_enabled && Boolean(administrating || myContact?.transaction_support) && (
                            <Fragment>
                              <DropdownItem
                                onClick={() => onTransactionSupportDisabling()}
                                disabled={!administrating && transaction.pending_transaction_support_disable}
                              >
                                <div className="flex items-center gap-3 text-left text-red-500">
                                  <i className="far fa-users-slash w-6 text-center text-red-500"></i>
                                  <div className="text-left">Disable Transaction Support</div>
                                </div>
                              </DropdownItem>
                            </Fragment>
                          )}

                          {!transaction_support_enabled && Boolean(administrating || auth.user.support_vp) && (
                            <DropdownItem onClick={() => onTransactionSupportEnabling()}>
                              <div className="flex items-center gap-2">
                                <i className="far fa-users-medical w-6 text-center"></i>
                                Enable Transaction Support
                              </div>
                            </DropdownItem>
                          )}
                        </Fragment>
                      )}

                    {transaction.editable &&
                      (transaction.alta_uploaded ||
                        ['Compliance', 'Under Review'].some((status) => status.includes(transaction.status))) && (
                        <Fragment>
                          <div className="bg-gray-200 py-1 pl-4 text-sm font-semibold uppercase text-gray-700">Compliance Review</div>

                          {!['Compliance', 'Under Review'].some((status) => status.includes(transaction.status)) && (
                            <DropdownItem
                              colorClasses="text-blue-500 hover:text-blue-600"
                              onClick={() => onReviewRequested()}
                              disabled={
                                !canRequestComplianceReviews ||
                                transaction.checklist.sections.find((section) => section.steps.find((step) => !step.completed_at))
                              }
                            >
                              <div className="flex items-center gap-2">
                                <i className="fas fa-folder-closed w-6 text-center"></i>
                                <div className="text-left">
                                  Request to Review & Complete
                                  {!canRequestComplianceReviews && (
                                    <div className="text-sm font-semibold italic text-red-500">Requires Transaction Support Associate</div>
                                  )}
                                </div>
                              </div>
                            </DropdownItem>
                          )}

                          {administrating && ['Compliance'].some((status) => status.includes(transaction.status)) && (
                            <DropdownItem onClick={() => handleBeginReview()}>
                              <div className="flex items-center gap-2 font-semibold text-orange-600">
                                <i className="far fa-file-magnifying-glass w-6 text-center text-xl"></i>
                                Begin Compliance Review
                              </div>
                            </DropdownItem>
                          )}

                          {!administrating && ['Compliance'].includes(transaction.status) && (
                            <DropdownItem onClick={() => onCancelReview()}>
                              <div className="flex items-center gap-2 text-left">
                                <i className="fas fa-lock-open w-6 text-center"></i>

                                <div className="leading-tight">
                                  <div className="font-medium">Cancel Compliance Review</div>
                                </div>
                              </div>
                            </DropdownItem>
                          )}

                          {administrating && transaction.status === 'Under Review' && (
                            <Fragment>
                              <DropdownItem
                                disabled={!approvable || ['Under Review'].indexOf(transaction.status) < 0}
                                onClick={() => onReviewApproved()}
                              >
                                <div className="flex items-center gap-2">
                                  <i
                                    className={classNames(
                                      'fas fa-check w-6 text-center text-xl',
                                      approvable && transaction.status === 'Under Review' ? 'text-green-800' : '',
                                    )}
                                  ></i>
                                  Approve & Complete
                                </div>
                              </DropdownItem>

                              <DropdownItem disabled={['Under Review'].indexOf(transaction.status) < 0} onClick={() => onReviewPaused()}>
                                <div
                                  className={classNames(
                                    'flex items-center gap-2 text-left',
                                    transaction.status === 'Under Review' ? 'text-red-600' : '',
                                  )}
                                >
                                  <i className="far fa-times w-6 text-center text-xl"></i>

                                  <div className="leading-tight">
                                    <div className="font-medium">Pause Compliance Review</div>
                                  </div>
                                </div>
                              </DropdownItem>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                  </Dropdown>
                )}
            </div>
          )}
        </div>
      </div>

      <div className="mb-2 bg-green-100 px-8 py-2 text-xl">{displayTransactionName()}</div>

      <div className="px-6">
        <div className="mb-3 mt-1 space-y-1.5">
          {!Boolean(transaction.paused_at) && (
            <div className="flex flex-wrap justify-center gap-1.5">
              <span className="inline-block rounded bg-blue-200 bg-opacity-40 px-2.5 py-1.5 text-center text-sm font-medium uppercase leading-tight text-blue-700 md:hidden">
                <span className="whitespace-pre-line leading-none">{transaction.stage.name}</span>
              </span>

              <span className="inline-block rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800 md:hidden">
                {transaction.stage.elapsed}
              </span>

              {transaction.property_type ? (
                <div className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                  <span>{transaction.property_type}</span>
                </div>
              ) : (
                transaction.propertyTypes?.length > 0 &&
                transaction.propertyTypes.map((type) => (
                  <div
                    className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                    key={type.id}
                  >
                    <span>{type.name}</span>
                  </div>
                ))
              )}

              {transaction.property_state ? (
                <div className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800">
                  <span>{transaction.property_state}</span>
                </div>
              ) : (
                transaction.states?.length > 0 &&
                transaction.states.map((state) => (
                  <div
                    className="inline-block whitespace-nowrap rounded bg-gray-200 px-2.5 py-1.5 text-sm leading-none text-gray-800"
                    key={state.id}
                  >
                    <span>{state.state}</span>
                  </div>
                ))
              )}
            </div>
          )}

          <div className="flex flex-wrap justify-center gap-1.5">
            {Boolean(transaction.paused_at) && (
              <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                <span>Paused</span>
              </div>
            )}

            {transaction.status === 'Pending' && (
              <div className="inline-block whitespace-nowrap rounded bg-blue-500 px-2.5 py-1.5 text-sm leading-none text-white">
                <span>Sale Pending</span>
              </div>
            )}

            {['Archived', 'Lost'].indexOf(transaction.status) >= 0 && (
              <div className="inline-block whitespace-nowrap rounded bg-red-600 px-2.5 py-1.5 text-sm leading-none text-white">
                <span>{transaction.status}</span>
              </div>
            )}

            {['On-Hold'].indexOf(transaction.status) >= 0 && (
              <div className="inline-block whitespace-nowrap rounded bg-yellow-200/80 px-2.5 py-1.5 text-sm font-medium leading-none text-yellow-600">
                <span>{transaction.status}</span>
              </div>
            )}

            {['Compliance', 'Under Review'].indexOf(transaction.status) >= 0 && (
              <div className="inline-block whitespace-nowrap rounded bg-orange-200/60 px-2.5 py-1.5 text-sm font-medium leading-none text-orange-600">
                <span>{transaction.status}</span>
              </div>
            )}

            {['Closed'].indexOf(transaction.status) >= 0 && (
              <div className="inline-block whitespace-nowrap rounded bg-orange-500 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                <span>Closed</span>
              </div>
            )}

            {transaction.completed_at && (
              <div className="inline-block whitespace-nowrap rounded bg-green-600 px-2.5 py-1.5 text-sm font-medium uppercase leading-none text-white">
                <span>Completed</span>
              </div>
            )}
          </div>
        </div>

        {!transaction.completed_at && !transaction.paused_at && <Stage transaction={transaction} />}

        <div className="relative mx-auto mt-3 flex max-w-md items-center justify-center px-8">
          <div className="group cursor-pointer px-2 text-center">
            <button
              type="button"
              className="inline-flex h-10 w-10 items-center justify-center rounded-full border border-blue-100 bg-blue-50 outline-none transition-all duration-300 group-hover:border-blue-500"
              onClick={() => setNote({ type: 'Note' })}
            >
              <i className="fad fa-note-medical text-xl leading-none text-blue-500 group-hover:text-blue-500"></i>
            </button>
            <div className="mt-1 text-sm text-gray-700 group-hover:text-blue-500">Note</div>
          </div>
        </div>
      </div>

      <CloseTransactionDialog
        closingType={closingType}
        open={beforeClosing}
        transaction={transaction}
        onCanceled={() => {
          setBeforeClosing(false)
          setTimeout(() => {
            setClosingType(false)
          }, 700)
        }}
      />
      <CancelClosingDialog open={beforeCancelClosing} transaction={transaction} onCanceled={() => setBeforeCancelClosing(false)} />
      <CancelReviewDialog
        open={beforeCancelReview}
        onCanceled={() => setBeforeCancelReview(false)}
        onCancelReview={(data) => onCancelReview(data)}
      />

      <DisableTransactionSupportDialog
        open={beforeTransactionSupportDisabled}
        onCanceled={() => setBeforeTransactionSupportDisabled(false)}
        onSuccess={(data) => onDisableTransactionSupport(data)}
      />

      {note && (
        <NoteDialog
          open={noteDialogOpen}
          id={transaction.id}
          type="Transaction"
          noteOnly={false}
          record={note}
          onClosed={() => {
            setNoteDialogOpen(false)
            onNoteCreated()
            setTimeout(() => {
              setNote(null)
            }, 150)
          }}
        />
      )}
    </div>
  )
}
