export default {
  capitalize(value) {
    let words = value ? value.split(' ') : []
    let wordsToCapitalize = words.length

    // check if last entry contains only roman numerals
    if (
      words.length > 0 &&
      words[words.length - 1].toUpperCase().match(/^M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/) != undefined
    ) {
      words[words.length - 1] = words[words.length - 1].toUpperCase()
      wordsToCapitalize -= 1
    }

    return _.compact(words)
      .map((word, index) => {
        if (index < wordsToCapitalize) {
          return word
            .split(/[-–]/)
            .map(
              (subWord) =>
                subWord
                  .split("'")
                  .map((part) => _.capitalize(part)) // Capitalize parts separated by an apostrophe
                  .join("'"), // Rejoin the parts with the apostrophe
            )
            .join('-') // Rejoin with the original hyphen
        } else {
          return word
        }
      })
      .join(' ')
  },

  formatPhoneNumber(cleaned) {
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  },

  formatCurrency(str, precision) {
    if (str == undefined || str === 'NaN' || isNaN(str)) {
      str = 0
    }

    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision || 2,
    })

    return formatter.format(str)
  },

  formatDecimal: function (str, precision) {
    var fraction = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: precision || 0,
      maximumFractionDigits: precision || 0,
    })

    return fraction.format(str)
  },

  truncateDecimals: function (value, notation) {
    const truncatedValue = Math.floor(value * 10) / 10
    const fixedValue = truncatedValue.toFixed(1)

    if (truncatedValue % 1 === 0) {
      return truncatedValue.toString() + (notation || '')
    }

    // Check if there are significant digits beyond the tenths place by comparing the original value and the fixed value
    return Math.abs(value - truncatedValue) > 0.01 ? fixedValue + (notation || '') + '*' : fixedValue + (notation || '')
  },

  parseCurrency: function (str) {
    return typeof str === 'string' ? Number(str.replace(/[^0-9.-]+/g, '')) : str
  },

  hasPermission: function (user, permission) {
    return this.isAdminOrManager(user) || user.permissions.includes(permission)
  },

  isPRO: function (user) {
    return user.roles.includes('Advisor')
  },

  isAssociate: function (user) {
    return user.roles.includes('Associate')
  },

  isAdmin: function (user) {
    return user.roles.includes('Admin')
  },

  isDeveloper: function (user) {
    return user.roles.includes('developer')
  },

  isHR: function (user) {
    return user.roles.includes('hr_controller')
  },

  isAdminOrHR: function (user) {
    return this.isAdmin(user) || this.isHR(user)
  },

  isImpersonating: function (user) {
    return Boolean(user.impersonating)
  },

  // https://stackoverflow.com/a/39786700/661612
  isNumber: function (evt) {
    evt = evt ? evt : window.event
    var charCode = evt.which ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault()
    } else {
      return true
    }
  },

  round: function (num) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  },

  numberPrecision: function (number, decimals = 0) {
    let negation = number < 0 ? -1 : 1
    let coefficient = 10 ** decimals
    return (negation * Math.floor((Math.abs(number) * coefficient).toString())) / coefficient
  },

  sanitizeCurrencyInput: function (value) {
    return value === '.' ? '0.' : value
  },

  slugify: function (string) {
    string = string.replace(/^\s+|\s+$/g, '') // trim leading/trailing white space
    string = string.toLowerCase() // convert string to lowercase
    string = string
      .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-') // remove consecutive hyphens
    return string
  },

  sortAlpha: function (a, b) {
    let nameA = a.toLowerCase(),
      nameB = b.toLowerCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  },

  spaceWords: function (array) {
    let result = ''
    let separator = ''
    array.map((item) => {
      if (Array.isArray(item)) {
        separator = item[1]
        item = item[0]
      }

      result += !item ? '' : separator + item
      separator = ' ' // reset separator to space for any subsequent items
    })

    return result
  },

  joinNames: function (names) {
    if (names.length === 0) return ''
    if (names.length === 1) return names[0]
    if (names.length === 2) return names.join(' and ')
    return names.slice(0, -1).join(', ') + ' and ' + names[names.length - 1]
  },

  explode(separator, array) {
    return array.reduce((output, name, index) => {
      return output + (index === 0 ? name : index === array.length - 1 ? ` and ${name}` : `${separator} ${name}`)
    }, '')
  },

  getReadableFileSizeString: function (Num = 0, dec = 1) {
    if (Num < 1000) return Num + ' Bytes'
    Num = ('0'.repeat(((Num += '').length * 2) % 3) + Num).match(/.{3}/g)
    return Number(Num[0]) + '.' + Num[1].substring(0, dec) + '  KMGTPEZY'[Num.length]
  },

  prepareFormData: function (data, formData = new FormData(), parentKey = '') {
    Object.keys(data).forEach((key) => {
      const fullKey = parentKey ? `${parentKey}[${key}]` : key
      const value = data[key]

      if (value instanceof File || value instanceof Blob) {
        // Directly append files or blobs
        formData.append(fullKey, value)
      } else if (Array.isArray(value)) {
        // Handle arrays
        value.forEach((item, index) => {
          const arrayKey = `${fullKey}[${index}]`
          if (typeof item === 'object' && item !== null && !(item instanceof File || item instanceof Blob)) {
            // Recursive call for nested objects or arrays
            this.prepareFormData(item, formData, arrayKey)
          } else if (item === null) {
            // Append "null" for arrays
            formData.append(arrayKey, 'null') // Represent null as a string
          } else {
            formData.append(arrayKey, this.formatBooleanValue(item))
          }
        })
      } else if (value instanceof Date) {
        // Format Date objects
        formData.append(fullKey, value.toISOString()) // ISO 8601 format
      } else if (typeof value === 'object' && value !== null) {
        // Handle objects recursively
        this.prepareFormData(value, formData, fullKey)
      } else if (value === null) {
        // Explicitly append "null" for nullable fields
        formData.append(fullKey, 'null') // Represent null explicitly
      } else {
        // Handle primitives
        formData.append(fullKey, this.formatBooleanValue(value))
      }
    })

    return formData
  },

  formatBooleanValue: function (value) {
    if (typeof value === 'boolean') {
      return value ? '1' : '0'
    }
    return value
  },
}
