import { Fragment, useEffect, useState } from 'react'
import TopBarDropdown from '@/Shared/Navigation/TopBarDropdown'
import TopBarDropdownItem from '@/Shared/Navigation/TopBarDropdownItem'
import { router } from '@inertiajs/react'
import MainMenu from '@/Shared/Navigation/MainMenu'
import MobileNavigation from '@/Shared/SlideOvers/MobileNavigation'
import NotificationButton from '@/Shared/Notifications/Button'
import { usePage } from '@inertiajs/react'
import Helpers from '../utils/helpers'

export default function TopHeader({ standAlone }) {
  const { url } = usePage()
  const { testing } = usePage().props
  const { user } = usePage().props.auth
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setOpen(false)
    }
  }, [url])

  const onCancelImpersonating = () => {
    router.post(route('users.cancel-impersonate'))
  }

  return (
    <Fragment>
      {testing && (
        <div className="bg-red-700 px-4 py-1 text-center font-semibold text-white">
          <span>TEST SITE</span>
        </div>
      )}

      <div className="relative z-20 bg-primary-500 px-4">
        <div className="flex h-16">
          <div className="flex flex-1">
            {!standAlone && (
              <div className="mr-1 flex items-center xl:hidden">
                <button
                  type="button"
                  className="inline-flex h-10 w-10 items-center justify-center rounded text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setOpen(!open)}
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? <i className="far fa-times text-3xl leading-none"></i> : <i className="far fa-bars text-2xl leading-none"></i>}
                </button>
              </div>
            )}

            <div className="flex flex-shrink-0 items-center">
              <a href="/account" className="flex w-full items-center outline-none">
                {!standAlone && user.type === 'Associate' ? (
                  <svg className="py-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.78 51.44" width="143.78" height="51.44">
                    <path
                      d="M64.47,46a2.46,2.46,0,0,1-.95,2.06,3.1,3.1,0,0,1-1.83.57H56.22V21.91h5.44a3.06,3.06,0,0,1,1.83.57,2.38,2.38,0,0,1,1,2V34a1.1,1.1,0,0,1-.41.91,1.62,1.62,0,0,1-.67.33h0l.36.15a1.27,1.27,0,0,1,.77,1.19ZM61.66,34V24.53H59V34Zm0,12.07V36.59H59V46Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M75.48,45.09q0,3.67-4.21,4-4-.3-4-4V31.17c0-2.49,1.34-3.81,4-4,2.78.13,4.17,1.46,4.17,4v8.18h-5.6v7.08h2.87V43.79h2.73ZM72.8,36.92V29.84H69.93v7.08Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M84.12,30.07H80.85V48.88c-2-.22-3-1.12-3-2.68V21.91h3v5.51h3.26Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M91.38,30.07H88.11V48.88c-2-.22-3-1.12-3-2.22V22.34l3-.43v5.51h3.27Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M100.6,45.13c0,2.39-1.4,3.75-4.22,3.94-2.68-.19-4-1.52-4-3.94V31.33q0-3.72,4-3.94c2.82.14,4.22,1.46,4.22,3.94v8.1H95v7h2.87V43.79h2.73ZM97.91,37V30H95v7Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M109,30H106V49h-3V30a2.39,2.39,0,0,1,1-2,3.45,3.45,0,0,1,2-.61H109Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M137.5,9.42a19.76,19.76,0,0,1,3.19,2.18,8.91,8.91,0,0,1,3,4.56c.66-3.37-1.2-6.28,2.19-10.88C141.53,6.19,139.47,7.5,137.5,9.42Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M140.22,12.22a15,15,0,0,0-10.08-3.7c4.12,4.12,2.58,7.37,3.9,10.66a4.88,4.88,0,0,0,6,2.91,5.22,5.22,0,0,1,.21,3.67c0,.57,1,.24,1.48-.24s-.36-2.57-1.1-3.66a4.83,4.83,0,0,0,2.18-2.22C143.78,17.69,142.67,14.16,140.22,12.22Zm-3.66,3.52a13.09,13.09,0,0,0-2-4.6,7.14,7.14,0,0,1,2.75,4.18,13.63,13.63,0,0,0,2,4.6A7.14,7.14,0,0,1,136.56,15.74Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path d="M121.85,48.86h-9.56V22.12h3.26V46.26h3V40.53h3.27Z" transform="translate(-56.22 -5.28)" fill="#ffffff" />
                    <path
                      d="M125.81,25.4h-3.06V22.12h3.06Zm0,23.66c-2-.19-3.06-1.07-3.06-2.65v-19h3.06Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M137.18,27.44,133.91,49h-3.06l-3.28-21.53h3.28l1.53,14.74,1.53-14.74Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path d="M142.2,49c-2-.19-3.07-1.07-3.07-2.62V27.44h3.07Z" transform="translate(-56.22 -5.28)" fill="#ffffff" />
                    <path
                      d="M153.76,49h-3.07V30h-3.26V49h-3V27.42h6.32a3.44,3.44,0,0,1,2,.58,2.37,2.37,0,0,1,1.05,2Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M164.88,54.13a2.39,2.39,0,0,1-1,2,3.59,3.59,0,0,1-2,.58h-8.49V54.13h8.5V48.67h-3.26q-3-.33-3-2.61V31c0-2.49,1.59-3.81,4.79-3.95,3,.17,4.55,1.47,4.55,3.94Zm-3-8.05V29.66h-3.28V46.08Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M177.72,34.12a2.35,2.35,0,0,1-1.2,2.05,3.93,3.93,0,0,1-2.14.57h-3V48.81h-3.28V22h6.33a3.76,3.76,0,0,1,2.13.6,2.3,2.3,0,0,1,1.2,2Zm-3.28,0V24.68h-3.05v9.44Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M189.11,48.8h-3.06a3.91,3.91,0,0,1-.62-2.62V36.74h-3.06V48.81h-3.3V22h6.31a3.9,3.9,0,0,1,2.13.6,2.3,2.3,0,0,1,1.2,2v9.44a1,1,0,0,1-.48.89,2.59,2.59,0,0,1-.79.37h-.06a3.52,3.52,0,0,1,.42.15,1.19,1.19,0,0,1,.91,1.19v9.44A3.61,3.61,0,0,0,189.11,48.8Zm-3.68-14.68V24.68h-3.06v9.44Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                    <path
                      d="M200,44.85c0,2.47-1.59,3.78-4.78,3.95-3.19-.17-4.79-1.48-4.79-3.95V26q0-3.71,4.79-4c3.19.15,4.78,1.46,4.78,4Zm-3.27,1.33V24.65h-3.06V46.18Z"
                      transform="translate(-56.22 -5.28)"
                      fill="#ffffff"
                    />
                  </svg>
                ) : (
                  <svg className="h-12 w-48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 979.73 246.24">
                    <polygon
                      fill="#ffffff"
                      points="155.33 0 236.67 79.33 236.67 56.67 272.67 56.67 272.67 115.33 312 154.67 292 173.33 156 41.33 20.67 173.33 0 154.67 155.33 0"
                    />
                    <rect fill="#ffffff" x="124.67" y="105.33" width="29.33" height="29.33" />
                    <rect fill="#ffffff" x="124.67" y="144" width="29.33" height="29.33" />
                    <rect fill="#ffffff" x="160.67" y="105.33" width="29.33" height="29.33" />
                    <rect fill="#ffffff" x="160.67" y="144" width="29.33" height="29.33" />
                    <path fill="#ffffff" d="M12.49,234.61H6.88l6.24-29.73h-9L5.23,200h23.6l-1,4.85h-9Z" />
                    <path
                      fill="#ffffff"
                      d="M41.51,234.61l3.35-15.9a17.42,17.42,0,0,0,.43-3q0-3.19-3-3.19-2.66,0-5,2.95a19.58,19.58,0,0,0-3.37,8.09L31.6,234.61H26l7.81-36.8h5.56L38,204c-.43,2-1,4.3-1.73,6.93l-.45,1.78H36a13.79,13.79,0,0,1,4-3.62,8.51,8.51,0,0,1,4.2-1.11,6.44,6.44,0,0,1,4.93,1.83,7.4,7.4,0,0,1,1.71,5.24,18.29,18.29,0,0,1-.54,4.11L47,234.61Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M65.58,235.08a9.81,9.81,0,0,1-7.27-2.63,9.94,9.94,0,0,1-2.61-7.33,20.83,20.83,0,0,1,1.83-8.71,15,15,0,0,1,5-6.24,11.92,11.92,0,0,1,7-2.19,9.17,9.17,0,0,1,5.92,1.71,5.9,5.9,0,0,1,2.12,4.86,7.53,7.53,0,0,1-3.94,6.73q-3.93,2.43-11.22,2.42h-1l0,.74v.68a6,6,0,0,0,1.33,4.12,5.1,5.1,0,0,0,4,1.49,13,13,0,0,0,3.38-.45,23.76,23.76,0,0,0,4-1.54v4.42a21.3,21.3,0,0,1-4.17,1.48A19.14,19.14,0,0,1,65.58,235.08Zm3.6-22.85a5.93,5.93,0,0,0-4.27,2A11.77,11.77,0,0,0,62,219.63h.69a15.72,15.72,0,0,0,7-1.26,3.9,3.9,0,0,0,2.51-3.58,2.36,2.36,0,0,0-.76-1.88A3.26,3.26,0,0,0,69.18,212.23Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M105.15,235.08a7.22,7.22,0,0,1-4-1.07,7,7,0,0,1-2.62-3.09h-.24l-1.51,3.69H92.59l7.81-36.8H106l-1.68,7.87c-.2,1-.5,2.23-.89,3.7s-.71,2.58-.95,3.33h.19a15.16,15.16,0,0,1,3.9-3.71,7.5,7.5,0,0,1,3.81-1,6.38,6.38,0,0,1,5.34,2.44,10.7,10.7,0,0,1,1.92,6.76,24.51,24.51,0,0,1-1.64,9,15.7,15.7,0,0,1-4.51,6.56A9.64,9.64,0,0,1,105.15,235.08Zm3.15-22.56a5.57,5.57,0,0,0-3.84,1.89,13.76,13.76,0,0,0-3.08,5,17.86,17.86,0,0,0-1.17,6.39,5.18,5.18,0,0,0,1.1,3.54,3.91,3.91,0,0,0,3.11,1.27,5.14,5.14,0,0,0,3.76-1.86,13,13,0,0,0,2.76-5,20.69,20.69,0,0,0,1-6.49Q112,212.52,108.3,212.52Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M132,235.08a9.79,9.79,0,0,1-7.27-2.63,9.94,9.94,0,0,1-2.62-7.33,20.68,20.68,0,0,1,1.84-8.71,15,15,0,0,1,5-6.24,12,12,0,0,1,7-2.19,9.19,9.19,0,0,1,5.92,1.71,5.9,5.9,0,0,1,2.12,4.86,7.55,7.55,0,0,1-3.94,6.73q-3.94,2.43-11.22,2.42h-1l-.05.74v.68a6,6,0,0,0,1.34,4.12,5.09,5.09,0,0,0,4,1.49,13,13,0,0,0,3.39-.45,23.76,23.76,0,0,0,4-1.54v4.42a21.53,21.53,0,0,1-4.17,1.48A19.14,19.14,0,0,1,132,235.08Zm3.59-22.85a5.92,5.92,0,0,0-4.26,2,11.68,11.68,0,0,0-2.88,5.36h.69a15.72,15.72,0,0,0,6.95-1.26,3.92,3.92,0,0,0,2.51-3.58,2.36,2.36,0,0,0-.76-1.88A3.27,3.27,0,0,0,135.56,212.23Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M163.78,226.56a7.59,7.59,0,0,1-2.79,6.31q-2.79,2.2-7.76,2.21a16.63,16.63,0,0,1-7.61-1.59v-4.8a14.38,14.38,0,0,0,7.38,2.13,6.61,6.61,0,0,0,3.71-.94,3,3,0,0,0,1.42-2.58,2.87,2.87,0,0,0-.82-2.07,15.69,15.69,0,0,0-3.34-2.31,12.27,12.27,0,0,1-4.18-3.23,6.35,6.35,0,0,1-1.22-3.89,7,7,0,0,1,2.53-5.74,10.44,10.44,0,0,1,6.84-2.08,16.48,16.48,0,0,1,8.18,2l-1.8,4.16a13.27,13.27,0,0,0-6.29-1.79,4.66,4.66,0,0,0-2.8.78,2.52,2.52,0,0,0-1.08,2.17,2.72,2.72,0,0,0,.79,1.9,13.61,13.61,0,0,0,3.19,2.12,18.63,18.63,0,0,1,3.52,2.39,7.21,7.21,0,0,1,1.59,2.17A6.38,6.38,0,0,1,163.78,226.56Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M177.85,230.59a10.31,10.31,0,0,0,3.57-.74v4.21a9.88,9.88,0,0,1-2.24.71,13.14,13.14,0,0,1-2.84.31q-6.48,0-6.48-5.84a14.4,14.4,0,0,1,.38-3.1l2.86-13.48h-3.83l.49-2.6,4.5-1.94,3.05-5.49h3.45l-1.23,5.82h6.6l-.92,4.21h-6.55l-2.89,13.53a10,10,0,0,0-.31,2.17,2.19,2.19,0,0,0,.6,1.62A2.41,2.41,0,0,0,177.85,230.59Z"
                    />
                    <path fill="#ffffff" d="M204.1,234.61h-5.53l7.81-36.8h5.55Z" />
                    <path
                      fill="#ffffff"
                      d="M236.29,218.24a21.33,21.33,0,0,1-1.72,8.69,14,14,0,0,1-4.74,6,12,12,0,0,1-7,2.09,9.66,9.66,0,0,1-7.21-2.71A10,10,0,0,1,213,225a21.59,21.59,0,0,1,1.69-8.64,14.33,14.33,0,0,1,4.74-6.11,11.76,11.76,0,0,1,7-2.19,9.46,9.46,0,0,1,7.21,2.75A10.39,10.39,0,0,1,236.29,218.24Zm-5.6-.24a6.16,6.16,0,0,0-1.16-4,4,4,0,0,0-3.31-1.43,5.49,5.49,0,0,0-3.94,1.69,11.19,11.19,0,0,0-2.7,4.59,19.8,19.8,0,0,0-1,6.18,6.13,6.13,0,0,0,1.17,4,4.25,4.25,0,0,0,3.46,1.39,5.4,5.4,0,0,0,3.83-1.61,10.5,10.5,0,0,0,2.65-4.5A20.48,20.48,0,0,0,230.69,218Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M250.51,235.08a9.83,9.83,0,0,1-7.19-2.51c-1.7-1.67-2.56-4.06-2.56-7.16a22.13,22.13,0,0,1,1.74-8.91,14.75,14.75,0,0,1,4.89-6.27,11.94,11.94,0,0,1,7.14-2.25,16.66,16.66,0,0,1,7,1.44l-1.65,4.35a13.25,13.25,0,0,0-5.23-1.25c-2.36,0-4.34,1.21-5.91,3.63a16.07,16.07,0,0,0-2.37,9,5.7,5.7,0,0,0,1.33,4,4.77,4.77,0,0,0,3.66,1.42,10,10,0,0,0,3.28-.53,24.78,24.78,0,0,0,3.06-1.27v4.61A15.71,15.71,0,0,1,250.51,235.08Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M270.54,235.08a6.41,6.41,0,0,1-5.27-2.4,10.44,10.44,0,0,1-1.94-6.75A24,24,0,0,1,265,217a16.21,16.21,0,0,1,4.53-6.59,9.61,9.61,0,0,1,6.34-2.42,6.68,6.68,0,0,1,6.52,4.21h.24l1.47-3.74h4.26l-5.59,26.16h-4.3l.5-4.17h-.15Q275.06,235.08,270.54,235.08Zm2-4.49a5.48,5.48,0,0,0,3.81-1.84,13.86,13.86,0,0,0,3.08-4.91,18.24,18.24,0,0,0,1.2-6.71,4.89,4.89,0,0,0-1.11-3.35,3.69,3.69,0,0,0-2.91-1.26,5.22,5.22,0,0,0-3.78,1.82,12.94,12.94,0,0,0-2.84,4.95,20.06,20.06,0,0,0-1.06,6.49,5.7,5.7,0,0,0,1,3.61A3.22,3.22,0,0,0,272.57,230.59Z"
                    />
                    <path fill="#ffffff" d="M295.8,234.61h-5.53l7.8-36.8h5.56Z" />
                    <path
                      fill="#ffffff"
                      d="M333.55,226.56a7.59,7.59,0,0,1-2.79,6.31q-2.79,2.2-7.76,2.21a16.64,16.64,0,0,1-7.62-1.59v-4.8a14.38,14.38,0,0,0,7.38,2.13,6.62,6.62,0,0,0,3.72-.94,3,3,0,0,0,1.41-2.58,2.9,2.9,0,0,0-.81-2.07,15.83,15.83,0,0,0-3.35-2.31,12.32,12.32,0,0,1-4.17-3.23,6.29,6.29,0,0,1-1.22-3.89,7,7,0,0,1,2.53-5.74A10.41,10.41,0,0,1,327.7,208a16.55,16.55,0,0,1,8.19,2l-1.8,4.16a13.3,13.3,0,0,0-6.29-1.79,4.64,4.64,0,0,0-2.8.78,2.52,2.52,0,0,0-1.08,2.17,2.71,2.71,0,0,0,.78,1.9,13.84,13.84,0,0,0,3.19,2.12,18.78,18.78,0,0,1,3.53,2.39,7.18,7.18,0,0,1,1.58,2.17A6.38,6.38,0,0,1,333.55,226.56Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M349,235.08a9.83,9.83,0,0,1-7.28-2.63,9.94,9.94,0,0,1-2.61-7.33,20.83,20.83,0,0,1,1.83-8.71,15,15,0,0,1,5-6.24,12,12,0,0,1,7-2.19,9.2,9.2,0,0,1,5.93,1.71,5.89,5.89,0,0,1,2.11,4.86,7.54,7.54,0,0,1-3.93,6.73q-3.94,2.43-11.23,2.42h-1l-.05.74v.68a5.93,5.93,0,0,0,1.34,4.12,5.07,5.07,0,0,0,4,1.49,13,13,0,0,0,3.38-.45,23.82,23.82,0,0,0,4-1.54v4.42a21.6,21.6,0,0,1-4.18,1.48A19,19,0,0,1,349,235.08Zm3.59-22.85a5.94,5.94,0,0,0-4.27,2,11.67,11.67,0,0,0-2.87,5.36h.68a15.74,15.74,0,0,0,7-1.26,3.91,3.91,0,0,0,2.5-3.58,2.39,2.39,0,0,0-.75-1.88A3.31,3.31,0,0,0,352.56,212.23Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M381.13,208a10.23,10.23,0,0,1,2.56.28l-1.21,5.18a9.67,9.67,0,0,0-2.41-.33,6.94,6.94,0,0,0-5.32,2.67,15.3,15.3,0,0,0-3.27,7L369,234.61h-5.59L369,208.45h4.35l-.5,4.85H373a14.27,14.27,0,0,1,3.93-4.08A7.65,7.65,0,0,1,381.13,208Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M387.52,234.61l-3.1-26.16h5.49l1.3,14.14q.33,3.76.33,7h.16c.45-1.17,1-2.47,1.66-3.91s1.17-2.47,1.54-3.12l7.35-14.14h5.91L394,234.61Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M412.33,234.61h-5.59l5.59-26.16h5.55ZM413.44,202a3.71,3.71,0,0,1,.92-2.61,3.38,3.38,0,0,1,2.6-1,3.15,3.15,0,0,1,2,.63,2.28,2.28,0,0,1,.78,1.9,3.64,3.64,0,0,1-.95,2.65,3.3,3.3,0,0,1-2.48,1,3.38,3.38,0,0,1-2.08-.62A2.22,2.22,0,0,1,413.44,202Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M430.92,235.08a9.83,9.83,0,0,1-7.19-2.51q-2.57-2.5-2.56-7.16a22.13,22.13,0,0,1,1.74-8.91,14.8,14.8,0,0,1,4.88-6.27,12,12,0,0,1,7.15-2.25,16.69,16.69,0,0,1,7,1.44l-1.65,4.35a13.25,13.25,0,0,0-5.23-1.25q-3.56,0-5.91,3.63a16.07,16.07,0,0,0-2.37,9,5.7,5.7,0,0,0,1.33,4,4.77,4.77,0,0,0,3.66,1.42,10,10,0,0,0,3.28-.53,26.13,26.13,0,0,0,3.06-1.27v4.61A15.71,15.71,0,0,1,430.92,235.08Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M453.62,235.08a9.81,9.81,0,0,1-7.27-2.63,9.94,9.94,0,0,1-2.61-7.33,20.83,20.83,0,0,1,1.83-8.71,15,15,0,0,1,5-6.24,12,12,0,0,1,7-2.19,9.17,9.17,0,0,1,5.92,1.71,5.9,5.9,0,0,1,2.12,4.86,7.53,7.53,0,0,1-3.94,6.73c-2.62,1.62-6.37,2.42-11.22,2.42h-1l-.05.74v.68a6,6,0,0,0,1.34,4.12,5.1,5.1,0,0,0,4,1.49,13,13,0,0,0,3.38-.45,23.76,23.76,0,0,0,4-1.54v4.42a21.3,21.3,0,0,1-4.17,1.48A19.14,19.14,0,0,1,453.62,235.08Zm3.6-22.85a5.93,5.93,0,0,0-4.27,2,11.68,11.68,0,0,0-2.88,5.36h.69a15.72,15.72,0,0,0,6.95-1.26,3.92,3.92,0,0,0,2.51-3.58,2.36,2.36,0,0,0-.76-1.88A3.26,3.26,0,0,0,457.22,212.23Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M485.43,226.56a7.59,7.59,0,0,1-2.79,6.31q-2.79,2.2-7.76,2.21a16.63,16.63,0,0,1-7.61-1.59v-4.8a14.38,14.38,0,0,0,7.38,2.13,6.58,6.58,0,0,0,3.71-.94,2.94,2.94,0,0,0,1.42-2.58,2.87,2.87,0,0,0-.82-2.07,15.69,15.69,0,0,0-3.34-2.31,12.37,12.37,0,0,1-4.18-3.23,6.35,6.35,0,0,1-1.22-3.89,7,7,0,0,1,2.53-5.74,10.44,10.44,0,0,1,6.84-2.08,16.48,16.48,0,0,1,8.18,2L486,214.12a13.34,13.34,0,0,0-6.3-1.79,4.66,4.66,0,0,0-2.8.78,2.51,2.51,0,0,0-1.07,2.17,2.67,2.67,0,0,0,.78,1.9,13.61,13.61,0,0,0,3.19,2.12,18.63,18.63,0,0,1,3.52,2.39,7.21,7.21,0,0,1,1.59,2.17A6.38,6.38,0,0,1,485.43,226.56Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M499.53,246.24a9.89,9.89,0,0,1-3.53-.54v-4.5a9.07,9.07,0,0,0,2.7.48q3.17,0,4.19-4.85L508,212.66h-4.24l.5-2.51,4.59-1.8.5-2.17q1-4.68,3.18-6.66a8.59,8.59,0,0,1,6.06-2,12,12,0,0,1,5,1l-1.44,4.16a9.2,9.2,0,0,0-3.22-.66,3.46,3.46,0,0,0-2.61,1,7.09,7.09,0,0,0-1.5,3.33l-.43,2h5.42l-.88,4.21h-5.41l-5.28,24.9a12.77,12.77,0,0,1-3.09,6.58A7.72,7.72,0,0,1,499.53,246.24Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M543.45,218.24a21.33,21.33,0,0,1-1.73,8.69,14,14,0,0,1-4.74,6A11.92,11.92,0,0,1,530,235a9.67,9.67,0,0,1-7.22-2.71,10,10,0,0,1-2.67-7.36,21.59,21.59,0,0,1,1.69-8.64,14.33,14.33,0,0,1,4.74-6.11,11.8,11.8,0,0,1,7-2.19,9.48,9.48,0,0,1,7.22,2.75A10.43,10.43,0,0,1,543.45,218.24Zm-5.61-.24a6.16,6.16,0,0,0-1.16-4,4,4,0,0,0-3.31-1.43,5.49,5.49,0,0,0-3.94,1.69,11.18,11.18,0,0,0-2.69,4.59,19.51,19.51,0,0,0-1,6.18,6.13,6.13,0,0,0,1.17,4,4.27,4.27,0,0,0,3.46,1.39,5.44,5.44,0,0,0,3.84-1.61,10.7,10.7,0,0,0,2.65-4.5A20.49,20.49,0,0,0,537.84,218Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M564.54,208a10.23,10.23,0,0,1,2.56.28l-1.21,5.18a9.61,9.61,0,0,0-2.41-.33,6.94,6.94,0,0,0-5.32,2.67,15.3,15.3,0,0,0-3.27,7l-2.5,11.83h-5.58l5.58-26.16h4.35l-.5,4.85h.21a14.27,14.27,0,0,1,3.93-4.08A7.65,7.65,0,0,1,564.54,208Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M580.41,208.45h5.49L587.39,221c.14,1,.27,2.35.38,4.12s.16,3.21.16,4.3h.15c1.35-3.39,2.42-5.86,3.19-7.41l6.93-13.57h6l-16.28,30.27a16.68,16.68,0,0,1-4.63,5.71,9.78,9.78,0,0,1-5.89,1.81,12.27,12.27,0,0,1-3.38-.45v-4.44a14.84,14.84,0,0,0,3,.38,5.29,5.29,0,0,0,3.17-1,11.8,11.8,0,0,0,2.94-3.68l1.2-2.18Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M627.31,218.24a21.33,21.33,0,0,1-1.72,8.69,14,14,0,0,1-4.75,6A11.9,11.9,0,0,1,613.9,235a9.66,9.66,0,0,1-7.21-2.71A10,10,0,0,1,604,225a21.59,21.59,0,0,1,1.69-8.64,14.25,14.25,0,0,1,4.74-6.11,11.76,11.76,0,0,1,7-2.19,9.46,9.46,0,0,1,7.21,2.75A10.39,10.39,0,0,1,627.31,218.24Zm-5.6-.24a6.16,6.16,0,0,0-1.16-4,4,4,0,0,0-3.31-1.43,5.49,5.49,0,0,0-3.94,1.69,11.19,11.19,0,0,0-2.7,4.59,19.51,19.51,0,0,0-1,6.18,6.13,6.13,0,0,0,1.17,4,4.25,4.25,0,0,0,3.46,1.39,5.4,5.4,0,0,0,3.83-1.61,10.5,10.5,0,0,0,2.65-4.5A20.48,20.48,0,0,0,621.71,218Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M641.53,208.45l-3,14.42a26.14,26.14,0,0,0-.74,4.57q0,3.15,3,3.15a5.4,5.4,0,0,0,3.38-1.35,11.88,11.88,0,0,0,3-3.85,22.55,22.55,0,0,0,2-5.85l2.34-11.09H657l-5.51,26.16h-4.38l.5-4.85h-.19a14.25,14.25,0,0,1-4,4,8.56,8.56,0,0,1-4.54,1.29,6.46,6.46,0,0,1-4.9-1.8,7.16,7.16,0,0,1-1.73-5.15,15.29,15.29,0,0,1,.29-2.95l.57-2.92,2.9-13.81Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M677,208a10.09,10.09,0,0,1,2.55.28l-1.2,5.18a9.69,9.69,0,0,0-2.42-.33,7,7,0,0,0-5.32,2.67,15.19,15.19,0,0,0-3.26,7l-2.51,11.83h-5.58l5.58-26.16h4.35l-.49,4.85h.21a14.27,14.27,0,0,1,3.93-4.08A7.62,7.62,0,0,1,677,208Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M707.09,234.61l3.35-15.9a17.42,17.42,0,0,0,.43-3q0-3.19-3-3.19-2.66,0-5,2.95a19.58,19.58,0,0,0-3.37,8.09l-2.31,11.05H691.6l7.8-36.8H705L703.61,204c-.43,2-1,4.3-1.73,6.93l-.45,1.78h.19a13.92,13.92,0,0,1,4-3.62,8.51,8.51,0,0,1,4.2-1.11,6.44,6.44,0,0,1,4.93,1.83,7.4,7.4,0,0,1,1.71,5.24,18.29,18.29,0,0,1-.54,4.11l-3.29,15.45Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M744.57,218.24a21.33,21.33,0,0,1-1.72,8.69,13.9,13.9,0,0,1-4.75,6,11.9,11.9,0,0,1-6.94,2.09,9.66,9.66,0,0,1-7.21-2.71,10,10,0,0,1-2.67-7.36,21.59,21.59,0,0,1,1.69-8.64,14.33,14.33,0,0,1,4.74-6.11,11.76,11.76,0,0,1,7-2.19,9.46,9.46,0,0,1,7.21,2.75A10.39,10.39,0,0,1,744.57,218.24ZM739,218a6.16,6.16,0,0,0-1.16-4,4,4,0,0,0-3.31-1.43,5.49,5.49,0,0,0-3.94,1.69,11.19,11.19,0,0,0-2.7,4.59,19.8,19.8,0,0,0-1,6.18,6.13,6.13,0,0,0,1.17,4,4.25,4.25,0,0,0,3.46,1.39,5.4,5.4,0,0,0,3.83-1.61,10.5,10.5,0,0,0,2.65-4.5A20.48,20.48,0,0,0,739,218Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M765.91,208c3.46,0,5.47,1.85,6,5.55h.19a13.17,13.17,0,0,1,4-4.15,8.71,8.71,0,0,1,4.69-1.4,6.16,6.16,0,0,1,4.79,1.8,7.36,7.36,0,0,1,1.64,5.1,24.53,24.53,0,0,1-.52,4.28l-3.31,15.45h-5.56l3.39-15.9a18.55,18.55,0,0,0,.44-3.14c0-2-1-3-2.86-3-1.7,0-3.29,1-4.76,2.93a19.35,19.35,0,0,0-3.23,7.78l-2.39,11.38H762.9l3.38-15.9a15.84,15.84,0,0,0,.41-3c0-2.13-.93-3.19-2.77-3.19s-3.34,1-4.81,3a20.59,20.59,0,0,0-3.28,8l-2.32,11.1h-5.58l5.58-26.16h4.35l-.49,4.85h.21Q761.08,208,765.91,208Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M802,235.08a9.79,9.79,0,0,1-7.27-2.63,9.94,9.94,0,0,1-2.62-7.33,20.83,20.83,0,0,1,1.84-8.71,15,15,0,0,1,5-6.24,12,12,0,0,1,7-2.19,9.19,9.19,0,0,1,5.92,1.71,5.9,5.9,0,0,1,2.12,4.86,7.55,7.55,0,0,1-3.94,6.73q-3.94,2.43-11.22,2.42h-1l0,.74v.68a6,6,0,0,0,1.34,4.12,5.1,5.1,0,0,0,4,1.49,13,13,0,0,0,3.38-.45,23.76,23.76,0,0,0,4-1.54v4.42a21.3,21.3,0,0,1-4.17,1.48A19.14,19.14,0,0,1,802,235.08Zm3.6-22.85a5.93,5.93,0,0,0-4.27,2,11.68,11.68,0,0,0-2.88,5.36h.69a15.72,15.72,0,0,0,6.95-1.26,3.92,3.92,0,0,0,2.51-3.58,2.36,2.36,0,0,0-.76-1.88A3.26,3.26,0,0,0,805.55,212.23Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M829.41,226.33a9.33,9.33,0,0,1,2-6,19.67,19.67,0,0,1,6.78-4.85,13.37,13.37,0,0,1-2.08-6.69,8.87,8.87,0,0,1,2.66-6.78,9.8,9.8,0,0,1,7-2.52,8.68,8.68,0,0,1,6,1.92,6.54,6.54,0,0,1,2.18,5.16,8,8,0,0,1-2.12,5.44,20.41,20.41,0,0,1-6.94,4.54l5.56,7.71a20.82,20.82,0,0,0,4.28-7h5.51a32.92,32.92,0,0,1-7,10.74l4.84,6.6h-6.55l-2.22-3.1a17.92,17.92,0,0,1-5,2.73,16.83,16.83,0,0,1-5.42.84,9.92,9.92,0,0,1-7-2.31A8.33,8.33,0,0,1,829.41,226.33Zm10.45,4.11a9.47,9.47,0,0,0,3.48-.64,14.71,14.71,0,0,0,3.41-1.93l-6.25-9a13.21,13.21,0,0,0-4.29,3.35,6.06,6.06,0,0,0-1.15,3.63,4.38,4.38,0,0,0,1.33,3.32A4.76,4.76,0,0,0,839.86,230.44Zm5.68-26.53a4,4,0,0,0-3.17,1.27,5,5,0,0,0-1.11,3.36,8.4,8.4,0,0,0,1.46,4.76,17.31,17.31,0,0,0,4.72-3.16,4.39,4.39,0,0,0,1.24-3,3.21,3.21,0,0,0-.85-2.4A3.1,3.1,0,0,0,845.54,203.91Z"
                    />
                    <path fill="#ffffff" d="M880.45,234.61h-5.54l7.81-36.8h5.56Z" />
                    <path
                      fill="#ffffff"
                      d="M893.81,234.61h-5.58l5.58-26.16h5.56ZM894.92,202a3.71,3.71,0,0,1,.92-2.61,3.41,3.41,0,0,1,2.61-1,3.14,3.14,0,0,1,2,.63,2.28,2.28,0,0,1,.78,1.9,3.64,3.64,0,0,1-1,2.65,3.3,3.3,0,0,1-2.48,1,3.38,3.38,0,0,1-2.08-.62A2.25,2.25,0,0,1,894.92,202Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M898.63,246.24a9.87,9.87,0,0,1-3.52-.54v-4.5a9.07,9.07,0,0,0,2.7.48q3.16,0,4.18-4.85l5.14-24.17h-4.24l.5-2.51,4.59-1.8.49-2.17q1-4.68,3.18-6.66a8.62,8.62,0,0,1,6.07-2,12,12,0,0,1,5,1l-1.44,4.16a9.2,9.2,0,0,0-3.22-.66,3.44,3.44,0,0,0-2.61,1,6.9,6.9,0,0,0-1.5,3.33l-.43,2h5.42l-.88,4.21h-5.41l-5.28,24.9a12.69,12.69,0,0,1-3.1,6.58A7.71,7.71,0,0,1,898.63,246.24Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M929.14,235.08a9.81,9.81,0,0,1-7.27-2.63,9.94,9.94,0,0,1-2.61-7.33,20.83,20.83,0,0,1,1.83-8.71,15,15,0,0,1,5-6.24,11.94,11.94,0,0,1,7-2.19,9.17,9.17,0,0,1,5.92,1.71,5.87,5.87,0,0,1,2.12,4.86,7.53,7.53,0,0,1-3.94,6.73q-3.93,2.43-11.22,2.42h-1l0,.74v.68a6,6,0,0,0,1.33,4.12,5.1,5.1,0,0,0,4,1.49,13,13,0,0,0,3.38-.45,23.76,23.76,0,0,0,4-1.54v4.42a21.3,21.3,0,0,1-4.17,1.48A19.14,19.14,0,0,1,929.14,235.08Zm3.6-22.85a5.94,5.94,0,0,0-4.27,2,11.67,11.67,0,0,0-2.87,5.36h.68a15.77,15.77,0,0,0,7-1.26,3.91,3.91,0,0,0,2.5-3.58,2.39,2.39,0,0,0-.75-1.88A3.31,3.31,0,0,0,932.74,212.23Z"
                    />
                    <path fill="#ffffff" d="M369.29,173.33V64.44H383.7v96h53.63v12.85Z" />
                    <path
                      fill="#ffffff"
                      d="M447.45,138.35l13.6-1.19a30,30,0,0,0,4.49,13.41q3.52,5.24,11,8.46a41.37,41.37,0,0,0,16.71,3.24,39.92,39.92,0,0,0,14.56-2.46,20.18,20.18,0,0,0,9.4-6.72,15.6,15.6,0,0,0,3.08-9.32,14.17,14.17,0,0,0-3-9q-3-3.82-9.81-6.43-4.38-1.71-19.38-5.31t-21-6.79a29.55,29.55,0,0,1-11.62-10.14,24.88,24.88,0,0,1-3.83-13.56,27.66,27.66,0,0,1,4.68-15.41A29,29,0,0,1,470,66.29a52,52,0,0,1,20-3.71,54.63,54.63,0,0,1,21.36,3.9A30.79,30.79,0,0,1,525.52,78a33,33,0,0,1,5.35,17.16l-13.81,1q-1.12-10.34-7.54-15.6t-19-5.28q-13.08,0-19.06,4.79t-6,11.55a12.38,12.38,0,0,0,4.24,9.66q4.15,3.8,21.73,7.76t24.1,6.95q9.51,4.38,14,11.1a27.09,27.09,0,0,1,4.53,15.49,29.61,29.61,0,0,1-5,16.38,32.69,32.69,0,0,1-14.3,12,49.76,49.76,0,0,1-21,4.27q-14.79,0-24.77-4.31a34.7,34.7,0,0,1-15.68-13A36.83,36.83,0,0,1,447.45,138.35Z"
                    />
                    <path fill="#ffffff" d="M551.55,173.33V64.44h14.78l57.19,85.5V64.44h13.82V173.33H622.56l-57.2-85.57v85.57Z" />
                    <path
                      fill="#ffffff"
                      d="M659.43,173.33V64.44h41.08a98.45,98.45,0,0,1,16.56,1,32.85,32.85,0,0,1,13.45,5.09,26.66,26.66,0,0,1,8.72,10.51,33.25,33.25,0,0,1,3.31,14.85q0,13.89-8.84,23.51t-31.94,9.62H673.84v44.27Zm14.41-57.12H702q14,0,19.83-5.2t5.87-14.63a19.69,19.69,0,0,0-3.45-11.7,16.18,16.18,0,0,0-9.1-6.43q-3.64-1-13.45-1H673.84Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M752,173.33V94.45h12v12q4.62-8.4,8.51-11.07a14.87,14.87,0,0,1,8.58-2.68A26.07,26.07,0,0,1,794.9,97l-4.6,12.41a19.16,19.16,0,0,0-9.81-2.9,12.7,12.7,0,0,0-7.87,2.64,14.26,14.26,0,0,0-5,7.31,52,52,0,0,0-2.23,15.6v41.3Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M796.35,133.89q0-21.91,12.18-32.46,10.19-8.76,24.81-8.77,16.27,0,26.6,10.66t10.32,29.46q0,15.22-4.57,23.95a32.29,32.29,0,0,1-13.29,13.56,38.71,38.71,0,0,1-19.06,4.83q-16.56,0-26.78-10.63T796.35,133.89Zm13.74,0q0,15.15,6.61,22.69a22,22,0,0,0,33.21,0q6.6-7.58,6.61-23.11,0-14.62-6.65-22.17a22,22,0,0,0-33.17,0Q810.1,118.74,810.09,133.89Z"
                    />
                    <path
                      fill="#ffffff"
                      d="M878.46,149.79l13.22-2.08q1.11,7.95,6.2,12.18t14.23,4.23q9.21,0,13.66-3.75a11.19,11.19,0,0,0,4.46-8.8,8.11,8.11,0,0,0-3.94-7.13q-2.74-1.78-13.66-4.53-14.71-3.72-20.39-6.43a19.44,19.44,0,0,1-11.55-18.09,20.28,20.28,0,0,1,2.41-9.76,21.65,21.65,0,0,1,6.57-7.47,27.84,27.84,0,0,1,8.51-3.9,40.6,40.6,0,0,1,11.55-1.6A45.69,45.69,0,0,1,926,95.34q7,2.67,10.37,7.24A28.41,28.41,0,0,1,941,114.8l-13.07,1.78a14.36,14.36,0,0,0-5.16-9.51q-4.28-3.41-12.08-3.41-9.21,0-13.14,3t-3.94,7.13a7.35,7.35,0,0,0,1.63,4.68,11.76,11.76,0,0,0,5.13,3.57c1.34.5,5.27,1.63,11.81,3.42q14.19,3.78,19.8,6.2a20.08,20.08,0,0,1,8.8,7A19.58,19.58,0,0,1,944,150.16a22.44,22.44,0,0,1-3.9,12.59,25.23,25.23,0,0,1-11.25,9.14,41,41,0,0,1-16.64,3.23q-15.37,0-23.44-6.39T878.46,149.79Z"
                    />
                    <path fill="#ffffff" d="M966.17,96V97.5h-4.23v11.06h-1.72V97.5H956V96Z" />
                    <path
                      fill="#ffffff"
                      d="M967.69,96h2.43l3.61,10.61L977.31,96h2.42v12.55h-1.62v-7.41c0-.25,0-.68,0-1.27s0-1.23,0-1.91l-3.58,10.59h-1.68L969.26,98v.39c0,.31,0,.77,0,1.4s0,1.1,0,1.39v7.41h-1.62Z"
                    />
                  </svg>
                )}
              </a>
            </div>

            <div className="hidden xl:ml-6 xl:flex xl:flex-grow xl:space-x-8">{!standAlone && <MainMenu />}</div>

            {!standAlone && (
              <div className="ml-6 flex flex-grow items-center justify-end xl:flex-auto">
                <ul className="navbar m-0 flex items-center space-x-1.5 p-0">
                  <li>
                    <TopBarDropdown id="help-menu" label={<i className="far fa-question-circle text-xl leading-none"></i>}>
                      <TopBarDropdownItem onClick={() => FreshworksWidget('open')}>
                        <i className="far fa-question-circle mr-3 w-7 text-center text-xl leading-none"></i>
                        Request Support
                      </TopBarDropdownItem>
                      <TopBarDropdownItem onClick={() => FreshworksWidget('open')}>
                        <i className="far fa-exclamation-circle mr-3 w-7 text-center text-xl leading-none"></i>
                        Report a Bug or Error
                      </TopBarDropdownItem>
                      <TopBarDropdownItem onClick={() => FreshworksWidget('open')}>
                        <i className="far fa-exclamation-square mr-3 w-7 text-center text-xl leading-none"></i>
                        Request a Feature
                      </TopBarDropdownItem>
                    </TopBarDropdown>
                  </li>

                  {/* <li className="mr-2">
                <a
                  href="#"
                  className="focus:outline-none inline-flex h-10 w-10 items-center justify-center rounded text-white hover:bg-white hover:bg-opacity-10 focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <i className="fas fa-calendar-alt text-xl leading-none"></i>
                </a>
              </li> */}

                  {user.type === 'Associate' && (
                    <li>
                      <NotificationButton />
                    </li>
                  )}

                  <li className="pl-3">
                    <TopBarDropdown id="avatar-menu">
                      <TopBarDropdown.Button>
                        {({ id, clickRef, open, setOpen }) => (
                          <button
                            id={id}
                            ref={clickRef}
                            className="inline-flex h-10 w-10 items-center justify-center rounded-full text-white outline-none transition-colors duration-500 hover:opacity-75 focus:ring-2 focus:ring-white"
                            aria-expanded={open}
                            aria-haspopup="true"
                            onClick={() => setOpen(!open)}
                          >
                            <span className="inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-50 bg-opacity-30 outline-none">
                              <img src={user.avatar || '/images/layout/headshot_ph.png'} alt={user.full_name} />
                            </span>
                          </button>
                        )}
                      </TopBarDropdown.Button>

                      {user.type === 'Associate' && (
                        <TopBarDropdownItem onClick={() => router.visit(route('my-card'))}>
                          <i className="far fa-user-circle mr-3 w-7 text-center text-xl leading-none"></i>
                          My Card
                        </TopBarDropdownItem>
                      )}

                      <TopBarDropdownItem onClick={() => router.visit('/account/settings')}>
                        <i className="far fa-cog mr-3 w-7 text-center text-xl leading-none"></i>
                        Settings
                      </TopBarDropdownItem>

                      {Helpers.isImpersonating(user) && (
                        <Fragment>
                          <div className="my-2 h-0 border-t border-gray-200"></div>

                          <TopBarDropdownItem onClick={() => onCancelImpersonating()}>
                            <i className="far fa-person-walking-arrow-loop-left mr-3 w-7 text-center text-xl leading-none text-green-800"></i>
                            <span className="font-medium text-green-800">Back to My Account</span>
                          </TopBarDropdownItem>
                        </Fragment>
                      )}

                      {!window.navigator.standalone && (
                        <Fragment>
                          <div className="my-2 h-0 border-t border-gray-200"></div>

                          <TopBarDropdownItem onClick={() => (window.location.href = '/logout')}>
                            <i className="far fa-sign-out mr-3 w-7 text-center text-xl leading-none"></i>
                            Logout
                          </TopBarDropdownItem>
                        </Fragment>
                      )}
                    </TopBarDropdown>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {!standAlone && <MobileNavigation open={open} onClosed={() => setOpen(false)} />}
      </div>
    </Fragment>
  )
}
