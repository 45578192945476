import React, { createContext, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import classNames from 'classnames'
import { Transition } from '@headlessui/react'

const Button = () => null
const Item = () => null
const Items = () => null
const Panel = () => null

export const AccordionContext = createContext({
  activeIndex: null,
  savedIndex: Number,
  onSaved: () => {},
  setActiveIndex: (index) => {},
})

export const AccordionProvider = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [savedIndex, setSavedIndex] = useState(null)

  const onSaved = () => {
    setSavedIndex(activeIndex)
    setActiveIndex(null)

    setTimeout(() => {
      setSavedIndex(null)
    }, 4000)
  }

  const context = {
    activeIndex,
    savedIndex,
    onSaved,
    setActiveIndex,
  }

  return <AccordionContext.Provider value={context}>{children}</AccordionContext.Provider>
}

export const AccordionConsumer = AccordionContext.Consumer

const AccordionItem = forwardRef(({ id, index, item, activeIndex, savedIndex, unmount, setActiveIndex, onIndexChanged }, ref) => {
  useImperativeHandle(ref, () => ({
    isExpanded: activeIndex == index,
    expand: () => setActiveIndex(index),
    collapse: () => setActiveIndex(null),
    toggle: toggleOpened,
  }))

  useEffect(() => {
    if (activeIndex != index && item.props.active) {
      setTimeout(() => {
        setActiveIndex(index)
      }, 1)
    }
  }, [item.props.active])

  const toggleOpened = () => {
    setActiveIndex(activeIndex == index ? null : index)
    if (typeof onIndexChanged !== 'undefined') {
      onIndexChanged(activeIndex == index ? null : index)
    }
  }

  return (
    <div
      className={classNames('divide-y divide-gray-300', {
        'bg-gray-100': !item.props.error && activeIndex == index,
        hidden: item.props.hidden,
      })}
    >
      {item.props.noPanel ? (
        <div className="block w-full px-4 py-4 sm:px-6">
          {Array.isArray(item.props.children)
            ? item.props.children.find((el) => el.type === Button).props.children
            : item.props.children.type === Button
              ? item.props.children.props.children
              : null}
        </div>
      ) : (
        <button
          className={classNames(
            'block w-full px-4 py-4 transition-colors duration-150 focus:outline-none sm:px-6',
            { 'text-primary-500': !item.props.error && !item.props.warning && activeIndex == index },
            { 'text-red-600': item.props.error },
          )}
          type="button"
          aria-expanded="true"
          aria-controls={`${id}-${index + 1}`}
          aria-describedby={`${id}-heading-${index + 1}`}
          onClick={() => toggleOpened()}
        >
          <div className="flex items-center justify-between">
            <div id={`${id}-heading-${index + 1}`} className="flex-1 text-left md:flex md:items-center md:space-x-3">
              {Array.isArray(item.props.children)
                ? item.props.children.find((el) => el.type === Button).props.children
                : item.props.children.type === Button
                  ? item.props.children.props.children
                  : null}

              {item.props.error && <i className="fas fa-exclamation-triangle text-xl"></i>}
              {item.props.warning && <i className="fas fa-exclamation-triangle text-xl text-red-600"></i>}
            </div>

            <div className="flex items-center space-x-4">
              <span
                className={classNames(
                  'font-semibold text-green-600 transition-opacity duration-150',
                  savedIndex == index ? 'opacity-100' : 'opacity-0',
                )}
              >
                Saved
              </span>

              <div
                className={classNames('flex items-center justify-end transition duration-200', {
                  'rotate-180 transform': activeIndex == index,
                })}
              >
                <i
                  className={classNames(
                    'far fa-angle-down text-2xl leading-none transition-colors duration-300',
                    activeIndex == index ? 'text-primary-500' : 'text-gray-500',
                  )}
                ></i>
              </div>
            </div>
          </div>
        </button>
      )}

      <Transition
        unmount={unmount}
        show={activeIndex == index}
        enter="transition ease-in duration-300 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div id={`${id}-${index + 1}`} className="accordion-collapse" aria-labelledby={`${id}-heading-${index + 1}`}>
          <div className="px-4 py-6 sm:px-6">
            {Array.isArray(item.props.children)
              ? item.props.children.find((el) => el.type === Panel).props.children
              : item.props.children.type === Panel
                ? item.props.children.props.children
                : null}
          </div>
        </div>
      </Transition>
    </div>
  )
})

export default function Accordion({ children, onIndexChanged, ...props }) {
  const { id } = props
  const itemGroup = Array.isArray(children) ? children.find((el) => el.type === Items) : children.type === Items ? children : null
  const items = Array.isArray(itemGroup.props.children)
    ? itemGroup.props.children.filter((el) => el.type === Item).map((el) => el)
    : itemGroup.props.children.type === Item
      ? [itemGroup.props.children]
      : []

  return (
    <AccordionProvider>
      <AccordionConsumer>
        {(accordionProps) => (
          <div className="divide-y divide-gray-300 border-b border-t border-gray-300">
            {items.map((item, index) => (
              <AccordionItem
                ref={item.ref}
                id={id}
                index={index}
                item={item}
                unmount={props.unmount}
                {...accordionProps}
                key={index}
                onIndexChanged={onIndexChanged}
              />
            ))}
          </div>
        )}
      </AccordionConsumer>
    </AccordionProvider>
  )
}

Accordion.Button = Button
Accordion.Item = Item
Accordion.Items = Items
Accordion.Panel = Panel
