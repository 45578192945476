import { useEffect, useState } from 'react'
import DeleteConfirmationDialog from '@/Shared/Dialog/DeleteConfirmationDialog'
import Dropdown from '@/Shared/Dropdown'
import DropdownItem from '@/Shared/DropdownItem'
import Entry from './Entry'
import NoteDialog from '@/Shared/NoteDialog'
import { router, useForm } from '@inertiajs/react'
import '@/Shared/RichTextEditor/styles.scss'

export default function NoteEntry({ entry }) {
  const [deleting, setDeleting] = useState(false)
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [note, setNote] = useState(null)
  const { delete: destroy } = useForm({})

  useEffect(() => {
    if (note != null) {
      setNoteDialogOpen(true)
    }
  }, [note])

  const onDelete = () => {
    destroy(route('api.notes.destroy', entry.loggable.id), {
      onError: () => {
        setDeleting(false)
      },
    })
  }

  const onPin = (note) => {
    router.post(route('api.notes.update', note.id), {
      _method: 'patch',
      pinned: true,
    })
  }

  const onUnPin = (note) => {
    router.post(route('api.notes.update', note.id), {
      _method: 'patch',
      pinned: false,
    })
  }

  return (
    <Entry icon={entry.icon} timestamp={entry.elapsed} key={entry.id}>
      <div className="mb-1.5 flex flex-grow flex-col rounded bg-white shadow">
        <div className="flex h-14 items-center justify-between border-b border-gray-200 px-4">
          <div>
            <span>
              {entry.author} <span dangerouslySetInnerHTML={{ __html: entry.description }}></span>
              {entry.loggable.updated_at > entry.loggable.created_at ? <span className="ml-1.5 text-gray-500">(edited)</span> : ''}
            </span>
          </div>

          <Dropdown
            id="note-options"
            label={<i className="fas fa-ellipsis-v text-xl text-gray-400 group-hover:text-gray-800"></i>}
            orientation="right"
            icon
          >
            {entry.loggable.pinned ? (
              <DropdownItem className="relative" onClick={() => onUnPin(entry.loggable)}>
                <i className="fal fa-ban absolute left-2 top-1 mr-4 text-2xl text-gray-400 group-hover:text-blue-500"></i>
                <i className="far fa-thumbtack mr-4 text-gray-600 hover:text-gray-700 group-hover:font-bold group-hover:text-blue-500"></i>
                Unpin
              </DropdownItem>
            ) : (
              <DropdownItem onClick={() => onPin(entry.loggable)}>
                <i className="far fa-thumbtack mr-4 text-gray-600 hover:text-gray-700 group-hover:font-bold group-hover:text-primary-500"></i>
                Pin
              </DropdownItem>
            )}

            <DropdownItem onClick={() => setNote(entry.loggable)}>
              <i className="far fa-pen mr-4 text-gray-600 hover:text-gray-700 group-hover:font-bold group-hover:text-primary-500"></i>
              Edit
            </DropdownItem>

            <DropdownItem colorClasses="text-red-500 hover:text-red-600" onClick={() => setDeleting(true)}>
              <i className="fas fa-trash mr-4"></i>
              Delete
            </DropdownItem>
          </Dropdown>
        </div>

        <div className="px-6 py-4 text-gray-900" style={{ backgroundColor: '#ffd' }}>
          <div className="ProseMirror" dangerouslySetInnerHTML={{ __html: entry.loggable.content }}></div>
        </div>
      </div>

      {note && (
        <NoteDialog
          open={noteDialogOpen}
          noteOnly={entry.sourceable_type !== 'Contact'}
          record={note}
          onClosed={() => {
            setNoteDialogOpen(false)
            setTimeout(() => {
              setNote(null)
            }, 500)
          }}
        />
      )}

      <DeleteConfirmationDialog open={deleting} onCanceled={() => setDeleting(false)} onApproved={() => onDelete()}>
        <p>Are you sure you want to delete this note?</p>
      </DeleteConfirmationDialog>
    </Entry>
  )
}
