import React from 'react'
import ReactDOM from 'react-dom/client'
import axios from '@/apis/axios'

export default function Login(props) {
  const [success, setSuccess] = React.useState(props.success)
  const [error, setError] = React.useState(props.error)
  const [form, setForm] = React.useState({
    email: '',
    password: '',
  })

  const authorizations = [
    { key: 'google', label: 'Google' },
    { key: 'linkedin', label: 'Linked In' },
  ]

  async function login() {
    await axios.get('/sanctum/csrf-cookie')
    await axios
      .post('/providers/sign-in', form)
      .then(() => {
        window.location.href = props.redirect || '/account'
      })
      .catch((error) => {
        let errors = error.response.data.errors
        if (errors.email) {
          setError(errors.email[0])
        }
      })
  }

  const handleInputChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSuccess('')
    login()
  }

  return (
    <React.Fragment>
      <form className="row sign-in" onSubmit={(event) => handleSubmit(event)}>
        <div className="col-sm-12 col-lg-10 col-lg-offset-1">
          <div className="row">
            <div className="col-sm-6 col-sm-push-3 registered-users">
              <h2 className="text-xl font-semibold">Returning Providers</h2>
              <p>
                <em>If you have an account with us, please log in.</em>
              </p>

              {error && (
                <React.Fragment>
                  <div className="message error font-bold" role="alert" dangerouslySetInnerHTML={{ __html: error }}></div>
                  <br />
                </React.Fragment>
              )}

              {success && (
                <React.Fragment>
                  <div className="message success font-bold" role="alert" dangerouslySetInnerHTML={{ __html: success }}></div>
                  <br />
                </React.Fragment>
              )}

              <div className="form-group">
                <label htmlFor="email">
                  Email <span className="required">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  required
                  maxLength={255}
                  autoFocus={true}
                  value={form.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">
                  Password <span className="required">*</span>
                </label>
                <div className="show-password">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    required
                    maxLength={50}
                    value={form.password}
                    onChange={handleInputChange}
                  />
                  <div className="icon">
                    <i className="fa fa-eye"></i>
                  </div>
                </div>
              </div>

              <div className="form-group buttons-set gap:4 flex items-center justify-between sm:hidden">
                <div>
                  <a href="/providers/forgot-password">Forgot your password?</a>
                </div>
                <div className="text-right">
                  <button
                    className="button bg-primary-500 text-white hover:bg-blue-600 focus:bg-blue-600 focus:ring-2 focus:ring-blue-600 focus:ring-offset-1"
                    type="submit"
                  >
                    Sign In
                  </button>
                </div>
              </div>

              <div className="group">
                <div className="registered-users space-y-6">
                  <div className="hidden items-start gap-4 sm:visible sm:flex">
                    <div className="flex-1">
                      <a className="focus:rounded focus:ring-1 focus:ring-blue-600 focus:ring-offset-1" href="/providers/forgot-password">
                        Forgot your password?
                      </a>
                    </div>
                    <div className="text-right">
                      <button
                        className="button bg-primary-500 text-white hover:bg-blue-600 focus:bg-blue-600 focus:ring-2 focus:ring-blue-600 focus:ring-offset-1"
                        type="submit"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>

                  {!window.navigator.standalone && (
                    <React.Fragment>
                      <hr />
                      <div className="social-connect">
                        {authorizations.map((network) => (
                          <a
                            className="focus:ring-2 focus:ring-blue-600 focus:ring-offset-1"
                            href={`/oauth/${network.key}`}
                            role="button"
                            key={network.key}
                          >
                            {
                              {
                                facebook: <i className="fa-brands fa-facebook text-xl"></i>,
                                linkedin: <i className="fa-brands fa-linkedin text-xl"></i>,
                                google: <i className="fa-brands fa-google text-xl"></i>,
                              }[network.key]
                            }
                            Sign in with {network.label}
                          </a>
                        ))}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

if (document.getElementById('sign-in')) {
  const root = ReactDOM.createRoot(document.getElementById('sign-in') || document.createElement('div'))

  const appData = window.appData || null

  // create new props object with element's data-attributes
  const props = root.dataset != undefined ? Object.assign({}, root.dataset) : appData

  root.render(
    <React.StrictMode>
      <Login {...props} />
    </React.StrictMode>,
  )
}
