import { useEffect, useState } from 'react'
import classNames from 'classnames'
import About from '@/Shared/Contacts/About'
import Button from '@/Shared/Button'
import CapHistoryTabView from './CapHistory/TabView'
import CapHistoryAccordionView from './CapHistory/AccordionView'
import Card from '@/Shared/Contacts/Card'
import ContactEdit from '@/Pages/Contacts/Edit'
import ContactPreview from '@/Pages/Contacts/Preview'
import Details from '@/Shared/Layouts/Details'
import EmergencyContacts from './EmergencyContacts/Index'
import HRDates from './HRDates/Index'
import Licenses from './Licenses/Index'
import Main from '@/Shared/Layouts/Main'
import MLSSystems from './MLSSystems/Index'
import MySupportNetwork from '@/Shared/Contacts/MySupportNetwork'
import SupportedAssociates from './SupportedAssociates'
import RealtorAssociations from './RealtorAssociations/Index'
import Sponsors from './Sponsors'
import Tab from '@/Shared/Tabs/Tab'
import Timeline from '@/Shared/Timeline/Index'

function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const AssociatesShow = ({ contact }) => {
  const [contactEditing, setContactEditing] = useState(false)
  const [contactPreviewing, setContactPreviewing] = useState(null)
  const [activeTab, setActiveTab] = useState('activity')
  const [dimensions, setDimensions] = useState({ width: window.innerWidth })

  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      width: window.innerWidth,
    })
  }, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  const previewContact = (id) => {
    setContactPreviewing(null)

    setTimeout(() => {
      axios.get(route('pros.show', id)).then((response) => {
        setContactPreviewing(response.data)
      })
    }, 300)
  }

  return (
    <Details columns={3}>
      <Details.Card>
        <Card contact={contact} onEdit={() => setContactEditing(true)} onOpenTags={() => setTagsOpen(true)} />
      </Details.Card>

      <Details.LeftColumn>
        <About contact={contact} onOpenTags={() => setTagsOpen(true)} />
      </Details.LeftColumn>

      <Details.MiddleColumn>
        <div className="flex h-full flex-col overflow-hidden bg-gray-100">
          <div className="flex h-full flex-col">
            <div className="relative z-0 flex w-full flex-col self-stretch overflow-hidden">
              <div className="flex h-full flex-col overflow-hidden">
                <div className="flex items-start justify-between px-4 pt-4 sm:px-6">
                  <ul
                    role="tablist"
                    className="list-reset mb-6 mt-2 flex flex-auto flex-wrap justify-start border-b border-gray-400 border-opacity-20"
                  >
                    <Tab active={activeTab === 'activity'} onClick={() => setActiveTab('activity')}>
                      Activity
                    </Tab>

                    {route().current() !== 'my-card' && (
                      <Tab active={activeTab === 'notes'} onClick={() => setActiveTab('notes')}>
                        Notes
                      </Tab>
                    )}

                    {dimensions.width >= 768 && (
                      <Tab active={activeTab === 'cap-history'} onClick={() => setActiveTab('cap-history')}>
                        {`CAP History (${contact.associate.cap_history?.length || 0})`}
                      </Tab>
                    )}

                    {dimensions.width >= 768 && contact.associate.id == 42 && (
                      <Tab active={activeTab === 'team-cap-history'} onClick={() => setActiveTab('team-cap-history')}>
                        {`Team CAP History (${contact.associate.team_cap_history?.length || 0})`}
                      </Tab>
                    )}
                  </ul>
                </div>

                <div className="flex flex-col overflow-hidden">
                  <div
                    id="activity"
                    className={classNames('flex flex-col overflow-hidden', {
                      hidden: activeTab !== 'activity',
                    })}
                  >
                    <Timeline />
                  </div>

                  {route().current() !== 'my-card' && (
                    <div
                      id="notes"
                      className={classNames('flex flex-col overflow-y-auto', {
                        hidden: activeTab !== 'notes',
                      })}
                    >
                      <Timeline type="note" emptyText="No notes have been added." />
                    </div>
                  )}

                  {dimensions.width >= 768 && (
                    <div
                      id="cap-history"
                      className={classNames('flex flex-col overflow-hidden', {
                        hidden: activeTab !== 'cap-history',
                      })}
                    >
                      <CapHistoryTabView type="cap_history" />
                    </div>
                  )}

                  {dimensions.width >= 768 && contact.associate.id == 42 && (
                    <div
                      id="team-cap-history"
                      className={classNames('flex flex-col overflow-hidden', {
                        hidden: activeTab !== 'team-cap-history',
                      })}
                    >
                      <CapHistoryTabView type="team_cap_history" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ContactEdit record={contact} open={contactEditing} onClosed={() => setContactEditing(false)} />
        <ContactPreview
          contact={contactPreviewing}
          hideFooter={!contactPreviewing?.id}
          open={contactPreviewing != null}
          onContactChanged={previewContact}
          onClosed={() => setContactPreviewing(null)}
        >
          <ContactPreview.FooterActions>
            {({ contact }) =>
              contact.url && (
                <div className="flex justify-center">
                  <Button type="submit" theme="solid" onClick={() => window.open(contact.url, '_blank')}>
                    View Website
                  </Button>
                </div>
              )
            }
          </ContactPreview.FooterActions>
        </ContactPreview>
      </Details.MiddleColumn>

      <Details.RightColumn>
        {dimensions.width < 768 && <CapHistoryAccordionView type="cap_history" />}
        {dimensions.width < 768 && contact.associate.id == 42 && <CapHistoryAccordionView type="team_cap_history" />}
        {route().current() !== 'my-card' && <HRDates />}
        <Licenses />
        <RealtorAssociations />
        <MLSSystems />
        <MySupportNetwork associate={contact.associate} onClick={previewContact} collapsed showResources />
        <SupportedAssociates onClick={previewContact} />
        <Sponsors onClick={previewContact} />
        <EmergencyContacts />
      </Details.RightColumn>
    </Details>
  )
}

// Persistent layout
// Docs: https://inertiajs.com/pages#persistent-layouts
AssociatesShow.layout = (page) => <Main title={page.props.contact.full_name} children={page} overflowHidden />

export default AssociatesShow
