import { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import { DatePicker } from '@/Shared/DatePicker/DatePicker'
import Heading from '@/Shared/Forms/Heading'
import Helpers from '@/utils/helpers'
import Info from '@/Shared/Info'
import RichTextEditor from '@/Shared/RichTextEditor/Index'
import Select from '@/Shared/Forms/Select'
import SlideOver from '@/Shared/SlideOver'
import TextInput from '@/Shared/Forms/TextInput'
import { Tooltip } from '@/Shared/Tooltip'
import { useForm } from '@inertiajs/react'
import useDebounce from '@/hooks/useDebounce'

export default function LSNEdit({ focusField, record, open, onClosed }) {
  let focusRef = useRef()

  const form = useForm()
  const { data, errors, clearErrors, setData, patch, post, processing, reset } = form
  const [exactMatch, setExactMatch] = useState(true)
  const [similarLSNs, setSimilarLSNs] = useState({})
  const [summary, setSummary] = useState('')
  const [copyright, setCopyright] = useState('')

  const shortcodes = [
    { value: 'YEAR', label: 'Current Year' },
    { value: 'LSN-NAME', label: 'Name of your LSN' },
    { value: 'LSN-HOMEPAGE', label: "Link to your LSN's homepage" },
  ]
  const states = [
    { value: 'AK', label: 'Alaska' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'New York' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WY', label: 'Wyoming' },
  ]

  useDebounce(() => findSimilarLSNs(), 300, [data.name])

  useEffect(() => {
    if (open) {
      let lsn = record || {}

      if (lsn.id) {
        let summary = lsn.summary || lsn.default_summary
        let copyright = lsn.copyright || lsn.default_copyright
        setData({
          id: lsn.id || null,
          name: lsn.name || null,
          manager_id: lsn.manager_id || null,
          manager: lsn.manager || null,
          replace_leaders_at: lsn.replace_leaders_at ? new Date(lsn.replace_leaders_at) : null,
          extended_name: lsn.extended_name || '',
          namespace: lsn.namespace || null,
          summary: summary,
          meeting_site_name: lsn.meeting_site_name || null,
          meeting_times: lsn.meeting_times || null,
          meeting_street: lsn.meeting_street || null,
          meeting_city: lsn.meeting_city || null,
          meeting_state: lsn.meeting_state || null,
          meeting_zip: lsn.meeting_postal_code || null,
          copyright: copyright,
        })

        setSummary(summary)
        setCopyright(copyright)
      } else {
        setData({ name: '' })
      }
    } else {
      clearErrors()
    }
  }, [open])

  useEffect(() => {
    setExactMatch(similarLSNs.data?.find((lsn) => lsn.name.toLowerCase() === data.name.toLowerCase()) || false)
  }, [similarLSNs])

  useEffect(() => {
    if (summary != data.summary) {
      setData({ ...data, summary: summary })
    }
  }, [summary])

  useEffect(() => {
    if (copyright != data.copyright) {
      setData({ ...data, copyright: copyright })
    }
  }, [copyright])

  const capitalize = (value) => value.replace(/(^\w{1})|(\s+\w{1})/g, (c) => c.toUpperCase())

  const fetchAssociates = (value) =>
    axios.get('/api/contacts', {
      params: {
        limit: 25,
        search: value,
        groups: 'associates',
      },
    })

  const showOption = (option) => {
    return option.label ? (
      option.label.name
    ) : (
      <div className="group flex items-center space-x-3" key={option.id}>
        {option.avatar ? (
          <img className="h-10 w-10 rounded-full" src={option.avatar} alt="" />
        ) : (
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-700">
            <div className="text-base font-medium tracking-wide">{option.initials}</div>
          </div>
        )}

        <div>
          <div className="space-x-1">
            <span className="space-x-1 font-medium text-gray-900">
              <span>{option.name}</span>
              {option.industry && <span className="text-gray-500">in {option.industry}</span>}
            </span>
          </div>

          {option.company && <div className="text-gray-500">{option.company}</div>}
        </div>
      </div>
    )
  }

  const findSimilarLSNs = () => {
    if (data.name?.length > 0 && (!record || record.name.toLowerCase() !== data.name.toLowerCase())) {
      axios
        .get(route('lsns.similar'), {
          params: {
            search: data.name,
          },
        })
        .then((response) => {
          setSimilarLSNs(response.data)
        })
    } else {
      setSimilarLSNs([])
    }
  }

  const highlightSearch = (value) => {
    let regexObj = new RegExp(data.name.replace(/[.*+?^${}()|[]\]/g, '$&'), 'gi')
    return data.name.length > 0 ? value.replace(regexObj, `<span class="font-semibold text-primary-500">$&</span>`) : value
  }

  const submit = (e) => {
    e.preventDefault()

    if (data.id) {
      patch(route('lsns.update', data.id), {
        onSuccess: (_) => {
          onClosed()
        },
      })
    } else {
      post(route('lsns.store'), {
        onSuccess: (_) => {
          reset()
          onClosed()
        },
      })
    }
  }

  return (
    <SlideOver
      focusRef={focusRef}
      footerActions={
        <Button type="submit" theme="solid" form="lsn-form" disabled={processing || !data.name || exactMatch}>
          {data.id ? 'Save Changes' : 'Continue'}
        </Button>
      }
      show={open}
      size={data.id ? 'max-w-xl' : 'max-w-lg'}
      title={data.id ? data.name : 'Create'}
      subTitle="Local Services Network (LSN)"
      onClosed={onClosed}
      aboveMessages
    >
      <form id="lsn-form" className="space-y-6 pb-4" onSubmit={submit}>
        <div>
          {!data.id && <Heading>Name Your LSN</Heading>}

          <TextInput
            ref={focusField ? null : focusRef}
            label={
              <div className="gap-1.5.5 mb-1 flex items-center">
                <span>
                  Name <span className="text-red-600">*</span>
                </span>

                <Tooltip
                  label="This is the name of your Local Services Network (LSN) that you will use frequently in conversation
            so it should be concise as possible - two to three words is optimal."
                  placement="bottom"
                >
                  <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                </Tooltip>
              </div>
            }
            name="name"
            placeholder="Start typing..."
            value={data.name}
            maxLength="255"
            error={errors.name}
            onChange={(value) => setData({ ...data, name: value })}
            onBlur={() => setData({ ...data, name: capitalize(data.name) })}
          />
        </div>

        {similarLSNs?.count > 0 && (
          <div
            className={classNames(
              'space-y-4 rounded-lg px-4 py-3',
              exactMatch ? 'border border-red-300 bg-red-50' : 'border border-gray-300 bg-yellow-50',
            )}
          >
            <div className={classNames('text-center text-lg font-medium leading-none', exactMatch ? 'text-red-600' : 'text-yellow-600')}>
              {exactMatch ? (
                <span>LSN Already Exists</span>
              ) : (
                <span>
                  <span className="font-bold">{similarLSNs.count}</span> Similar LSNs Found
                </span>
              )}
            </div>

            {exactMatch ? (
              <p className="text-center font-medium text-red-600">An LSN with that name already exists.</p>
            ) : (
              <Fragment>
                <p>The following LSNs were found containing words similar to the name you chose:</p>

                <div className="flex flex-col items-center space-y-1 text-xl">
                  {similarLSNs.data.map((lsn, index) => (
                    <span dangerouslySetInnerHTML={{ __html: highlightSearch(lsn.name) }} key={index}></span>
                  ))}
                </div>

                {similarLSNs.count > similarLSNs.data.length && (
                  <div className="text-center">...and {similarLSNs.count - similarLSNs.data.length} others</div>
                )}

                <div className="flex justify-center">
                  <Button
                    theme="border"
                    onClick={() =>
                      window.open(route('lsns.index', { filters: encodeURIComponent(JSON.stringify({ search: data.name })) }), '_blank')
                    }
                  >
                    View All Similar LSNs
                  </Button>
                </div>

                <p>If you wish to continue without changing the name of your LSN, click the continue button.</p>
              </Fragment>
            )}
          </div>
        )}

        {data.id && (
          <div>
            <div className="mb-4">
              <label
                htmlFor="replace_leaders_at"
                className={classNames(
                  'mb-0.5 block text-sm font-medium uppercase',
                  errors.replace_leaders_at ? 'text-red-600' : 'text-gray-500',
                )}
              >
                <div className="mb-1 flex items-center gap-1.5">
                  <span>Leadership Change Date</span>

                  <Tooltip
                    label="Each year, elections should be held to replace the Leadership Team. The Leadership Replacement Clock shows the date by which the Leadership Team should be replaced by the new Leadership Team. Elections should be held prior to this date to allow for reasonable transition."
                    placement="bottom"
                  >
                    <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                  </Tooltip>
                </div>
              </label>

              <DatePicker
                name="replace_leaders_at"
                date={data.replace_leaders_at && new Date(data.replace_leaders_at)}
                error={errors.replace_leaders_at}
                onChange={(date) => setData({ ...data, replace_leaders_at: date })}
              />

              {errors.replace_leaders_at && <div className="mt-1 text-red-600">{errors.replace_leaders_at}</div>}
            </div>

            <TextInput
              label={
                <div className="mb-1 flex gap-1">
                  <div className="flex items-center gap-1">
                    <span>Extended Name</span>
                  </div>

                  <Tooltip
                    label="This is the body description shown on your LSN's home page. We recommend including the name of your LSN and the local area it services."
                    placement="bottom"
                  >
                    <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                  </Tooltip>
                </div>
              }
              name="extended_name"
              value={data.extended_name}
              error={errors.extended_name}
              onChange={(value) => setData({ ...data, extended_name: value })}
              onBlur={() => setData({ ...data, extended_name: capitalize(data.extended_name) })}
            />

            <TextInput
              label={
                <div className="mb-1 flex items-center gap-1.5">
                  <span>Custom LSN Website Address</span>

                  <Tooltip label="Easily direct someone to this LSN by setting a custom web site address (URL)" placement="bottom">
                    <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                  </Tooltip>

                  {record?.namespace && record?.published && (
                    <Tooltip
                      label={
                        <div>
                          <p className="mb-2">This address can't be changed while this LSN is public.</p>
                          <p>
                            <span className="font-semibold text-orange-500">Note:</span> Changing this address after it has been made public
                            will result in a loss of search engine rankings, bookmarks, social media shares, etc.
                          </p>
                        </div>
                      }
                      placement="bottom"
                    >
                      <i className="fas fa-warning ml-1.5 cursor-help text-xl text-orange-500"></i>
                    </Tooltip>
                  )}
                </div>
              }
              inputGroup={`${window.location.protocol}//${window.location.host}/`}
              name="namespace"
              value={data.namespace}
              onChange={(value) => setData({ ...data, namespace: Helpers.slugify(value) })}
              error={errors.namespace}
              disabled={record?.namespace && record?.published}
            />

            <Select
              error={errors.manager_id}
              label="Manager"
              name="manager_id"
              value={data.manager}
              optionLabel={(option) => showOption(option)}
              optionValue={(option) => option.id}
              placeholder="Search Associates"
              onChange={(selected) => setData({ ...data, manager_id: selected?.associate_id, manager: selected })}
              onInputChanged={(value) => fetchAssociates(value)}
              required
              async
              isClearable={false}
            />

            <Heading>Meeting Location</Heading>

            <TextInput
              label="Location Name"
              name="meeting_site_name"
              value={data.meeting_site_name}
              error={errors.meeting_site_name}
              onChange={(value) => setData({ ...data, meeting_site_name: value })}
            />

            <TextInput
              label="Meeting Day & Time"
              name="meeting_times"
              value={data.meeting_times}
              error={errors.meeting_times}
              onChange={(value) => setData({ ...data, meeting_times: value })}
            />

            <TextInput
              label="Street"
              name="meeting_street"
              value={data.meeting_street}
              error={errors.meeting_street}
              onChange={(value) => setData({ ...data, meeting_street: value })}
            />

            <TextInput
              ref={focusField === 'meeting_city' ? focusRef : null}
              label="City"
              name="meeting_city"
              value={data.meeting_city}
              error={errors.meeting_city}
              onChange={(value) => setData({ ...data, meeting_city: value })}
              required
            />

            <Select
              ref={focusField === 'meeting_state' ? focusRef : null}
              label="State"
              classes="mb-4"
              name="meeting_state"
              options={states}
              value={data.meeting_state}
              error={errors.meeting_state}
              isClearable={false}
              onChange={(selected) => setData({ ...data, meeting_state: selected && selected.value })}
              required
            />

            <TextInput
              label="Zip Code"
              name="meeting_zip"
              value={data.meeting_zip}
              error={errors.meeting_zip}
              onChange={(value) => setData({ ...data, meeting_zip: value })}
            />

            <div className="mb-5">
              <Heading className={classNames('flex items-center gap-1.5', errors.summary && 'text-red-600')}>
                Summary{' '}
                <Tooltip
                  label="This is the brief description of your LSN which is located beneath the menu. The available shortcodes can be used to display your LSN's current data when a user views your LSN site dynamically."
                  placement="bottom"
                >
                  <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                </Tooltip>
              </Heading>

              {record?.default_summary && (
                <Info text="We have provided some default summary text for you that you can modify for your purposes or use as-is." />
              )}

              <RichTextEditor value={summary} theme="link" shortcodes={shortcodes} onChange={(value) => setSummary(value)} />

              {errors.summary && <div className="mt-1 text-red-600">{errors.summary}</div>}
            </div>

            <div className="mb-5">
              <Heading className={classNames('flex items-center gap-1.5', errors.copyright && 'text-red-600')}>
                Copyright{' '}
                <Tooltip
                  label="Copyright & Footer text that appears at the bottom of your LSN site. The available shortcodes can be used to display your LSN's current data when a user views your LSN site dynamically."
                  placement="bottom"
                >
                  <i className="far fa-question-circle cursor-help text-base text-orange-500"></i>
                </Tooltip>
              </Heading>

              {record?.default_copyright && (
                <Info text="We have provided some default copyright text for you that you can modify for your purposes or use as-is." />
              )}

              <RichTextEditor value={copyright} theme="link" shortcodes={shortcodes} onChange={(value) => setCopyright(value)} />

              {errors.copyright && <div className="mt-1 text-red-600">{errors.copyright}</div>}
            </div>
          </div>
        )}
      </form>
    </SlideOver>
  )
}
