import Button from '@/Shared/Button'
import Dialog from '@/Shared/Dialog/Index'
import { useForm, usePage } from '@inertiajs/react'
import Heading from '../../Shared/Forms/Heading'
import Checkbox from '../../Shared/Forms/Checkbox'

export default function RequestInvoiceDialog({ brokerageStatement, open, onCanceled }) {
  const { transaction } = usePage().props

  const { data, setData } = useForm({
    price_seller_credits: true,
    commission: true,
    reimbursements: transaction.reimbursements.flatMap((reimbursement) => reimbursement.id),
  })

  const onSubmit = () => {
    const baseUrl = route('transactions.files.show', brokerageStatement.id)

    const queryParams = new URLSearchParams({
      ...data,
      reimbursements: data.reimbursements.join(','),
    }).toString()

    window.open(`${baseUrl}?${queryParams}`, '_blank')
  }

  return (
    <Dialog
      footerActions={
        <Button type="submit" theme="solid" onClick={() => onSubmit()}>
          <div className="flex items-center gap-2">
            <i className="far fa-external-link text-lg"></i>
            View Brokerage Statement
          </div>
        </Button>
      }
      size="sm:max-w-xl"
      show={open}
      title="Generate Brokerage Statement"
      onClosed={onCanceled}
      cancelText="Close"
      focusCancelButton
    >
      <div className="space-y-4">
        <p>Specify the items that you would like to appear on your Brokerage Statement:</p>

        <div className="!mt-0">
          <Heading>Options</Heading>

          <div className="space-y-4">
            {transaction.type === 'Seller' && !data.commission && (
              <Checkbox
                name="price_seller_credits"
                label="Price and Seller Credits"
                value={data.price_seller_credits}
                onChange={(checked) => setData({ ...data, price_seller_credits: checked })}
              />
            )}

            <Checkbox
              name="commission"
              label="Commission(s) Due"
              value={data.commission}
              onChange={(checked) => setData({ ...data, commission: checked, price_seller_credits: checked || data.price_seller_credits })}
            />

            {transaction.reimbursements
              .filter((reimbursement) => !reimbursement.paid)
              .map((reimbursement) => (
                <Checkbox
                  name={`reimbursement_${reimbursement.id}`}
                  label={<span className="leading-snug">{reimbursement.description} reimbursement</span>}
                  value={data.reimbursements.includes(reimbursement.id)}
                  onChange={(checked) =>
                    setData((prevData) => ({
                      ...prevData,
                      reimbursements: checked
                        ? [...prevData.reimbursements, reimbursement.id]
                        : prevData.reimbursements.filter((id) => id !== reimbursement.id),
                    }))
                  }
                  key={reimbursement.id}
                />
              ))}
          </div>
        </div>
      </div>
    </Dialog>
  )
}
