import { createRef, useEffect } from 'react'
import Link from '@/Shared/Link'
import classNames from 'classnames'
import { Tooltip } from '@/Shared/Tooltip'
import { usePage } from '@inertiajs/react'

export default () => {
  const scrollRef = createRef()
  const { user } = usePage().props.auth

  useEffect(() => {
    let ref = scrollRef.current

    if (ref) {
      setTimeout(() => {
        ref.scrollIntoView()
      }, 100)
    }
  }, [])

  return (
    <div ref={scrollRef} className="divide-y divide-gray-200 lg:col-span-9">
      <div className="px-4 py-6 sm:p-6 lg:pb-8">
        <div>
          <h2 className="text-xl font-medium leading-6 text-gray-900">Local Services Network® (LSNs)</h2>
          <p className="mt-1 text-gray-500">
            These are your Local Services Network® (LSN) Business Teams. Select one of the below Local Services Network® (LSN) Business
            Teams to view your PRO-Website for that LSN.
          </p>
        </div>

        {user.lsns?.length > 0 ? (
          <div className="mx-auto mt-6 max-w-xl space-y-2">
            {user.lsns.map((lsn) => (
              <a
                href={lsn.permalink}
                className="flex w-full items-center justify-between rounded-lg border border-gray-200 bg-white p-4 hover:bg-gray-100 sm:px-6"
                key={lsn.id}
                target="_blank"
              >
                <div className="flex-1 space-x-2 text-left font-medium leading-none">
                  <span>{lsn.title}</span>
                  <span
                    className={classNames(
                      'inline-block whitespace-nowrap rounded px-2.5 py-1.5 text-sm leading-none',
                      lsn.published ? 'bg-green-50 text-green-700' : 'bg-gray-200 text-gray-800',
                    )}
                  >
                    {lsn.published ? 'Public' : 'Private'}
                  </span>
                </div>

                <Tooltip label="Visit LSN">
                  <div className="flex items-center justify-end">
                    <i className="far fa-external-link text-lg leading-none text-gray-700"></i>
                  </div>
                </Tooltip>
              </a>
            ))}
          </div>
        ) : (
          <div className="mt-6 rounded-md border border-primary-200 bg-primary-50 p-6 text-center">
            <p className="mb-3 text-xl font-medium text-primary-500">You don't currently belong to a Local Services Network® (LSN).</p>

            <p className="mb-3 text-lg">Interested in joining an LSN? Search our LSNs to find one in your area.</p>

            <Link href="/account/lsns" theme="outline">
              <i className="fas fa-search mr-2 text-lg"></i>
              <span className="pr-1">Find an LSN</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
