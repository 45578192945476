import { useContext } from 'react'
import Accordion from '@/Shared/Accordion'
import EmailPreferences from './Forms/Preferences/Email'
import { SettingContext } from './SettingContext'
import { usePage } from '@inertiajs/react'

export default () => {
  const { user } = usePage().props.auth
  const administrating = route()?.current()?.startsWith('admin')
  const { setting, setSetting } = useContext(SettingContext)

  return (
    <div className="divide-y divide-gray-200 lg:col-span-9">
      <div className="p-4 sm:p-6 lg:pb-4">
        <div>
          <h2 className="text-xl font-medium leading-6 text-gray-900">{administrating ? 'User' : 'Your'} Preferences</h2>
          <p className="mt-1 text-gray-500">These preferences only apply to {administrating ? 'this user' : 'you'}.</p>
        </div>

        <div className="mt-6">
          <Accordion
            id="website"
            onIndexChanged={(index) => {
              setSetting(
                {
                  1: 'email',
                }[index],
              )
            }}
          >
            <Accordion.Items>
              <Accordion.Item active={setting === 'email'}>
                <Accordion.Button>
                  <div className="text-lg font-medium">Email</div>
                </Accordion.Button>

                <Accordion.Panel>
                  <EmailPreferences user={user} />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion.Items>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
