import { Fragment, useRef, useState } from 'react'
import Button from '@/Shared/Button'
import classNames from 'classnames'
import { Tooltip } from '@/Shared/Tooltip'
import { Transition } from '@headlessui/react'
import { useClickAway } from 'use-click-away'

const DropdownButton = () => null

const Wrapper = ({ children, tooltip, tooltip_placement }) => {
  return tooltip ? (
    <Tooltip label={tooltip} placement={tooltip_placement || 'bottom'}>
      {children}
    </Tooltip>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

export default function Dropdown({
  buttonTheme,
  children,
  disabled,
  dropup,
  label,
  id,
  icon,
  orientation,
  size,
  tooltip,
  tooltip_placement,
}) {
  const [open, setOpen] = useState(false)
  const clickRef = useRef(null)

  const button = Array.isArray(children)
    ? children.find((el) => {
        if (el == undefined) {
          console.error(children)
        }

        return el?.type === DropdownButton
      })
    : children?.type === DropdownButton

  useClickAway(clickRef, () => {
    setTimeout(() => setOpen(false), 50)
  })

  return (
    <div className="relative z-10" ref={clickRef}>
      <Wrapper tooltip={tooltip} tooltip_placement={tooltip_placement}>
        {button ? (
          button.props.children({ id, clickRef, open, setOpen })
        ) : (
          <Button
            theme={icon ? 'icon' : buttonTheme || 'link'}
            id={id}
            aria-expanded="true"
            aria-haspopup="true"
            disabled={disabled}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              setOpen(!open)
            }}
          >
            <span className={disabled ? 'text-gray-300' : !buttonTheme ? 'text-gray-900' : ''}>{label}</span>
            {!icon && (
              <i
                className={classNames('fas fa-angle-down pl-2 text-sm', disabled ? 'text-gray-300' : !buttonTheme ? 'text-gray-900' : '')}
              ></i>
            )}
          </Button>
        )}
      </Wrapper>

      <Transition
        show={open}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className={classNames(
            'absolute rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            size,
            {
              'left-0 origin-top-left': orientation === 'left',
              'right-0 origin-top-right': orientation === 'right',
            },
            dropup ? 'bottom-0 mb-14' : 'mt-2',
          )}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby={id}
        >
          {children}
        </div>
      </Transition>
    </div>
  )
}

Dropdown.defaultProps = {
  id: 'options-menu',
  orientation: 'left',
  size: 'w-48',
}

Dropdown.DropdownButton = DropdownButton
