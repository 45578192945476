import React from 'react'
import classNames from 'classnames'

export default ({ children, colSpan, classes, className, direction, field, noBorder, noHover, sorting, width, onSort, ...props }) => {
  const customClasses = classNames(classes, className)

  return (
    <th
      className={classNames(
        'h-14 py-4 text-left text-sm font-medium uppercase tracking-wider text-gray-800',
        {
          'border-l border-gray-200': !noBorder,
          'cursor-pointer hover:bg-gray-100': !noHover,
          'px-4 sm:px-6': !customClasses?.includes('px-'),
        },
        customClasses,
      )}
      colSpan={colSpan || 1}
      width={width}
      onClick={onSort ? () => onSort(field) : null}
      {...props}
    >
      <div className="flex items-center justify-between">
        {children}

        {field && sorting === field && (
          <i
            className={classNames('fas', {
              'fa-arrow-down text-primary-500': direction === 'asc',
              'fa-arrow-up text-primary-500': direction === 'desc',
            })}
          />
        )}
      </div>
    </th>
  )
}
